import InputText from '@/components/element/inputs/InputText';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
	DialogDescription,
} from '@/components/ui/dialog';
import { EventTypes, RewardType } from '../../types/admin-user-affiliation.enums';
import InputSelect from '@/components/element/inputs/InputSelect';
import useAdminAffiliateProgram from '../../hooks/useAdminAffiliateProgram';
import AffiliateRewardTypes from './AffiliateRewardTypes';
import { useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { convertToTitleCase } from '@/utils/parsers';

const AffiliateRewardsDialog = ({
	open,
	setOpen,
	rowId,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	rowId?: string;
}) => {
	const {
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		items,
		isLoading,
		addReward,
		removeReward,
		handleChange,
		handleRewardChange,
		setRewardMetadata,
		updateAffiliateRewards,
		resetForm,
	} = useAdminAffiliateProgram({ setOpen });

	useEffect(() => {
		if (rowId) {
			setFormFields({
				...formFields,
				_id: rowId,
			});
		}
	}, [rowId]);

	useEffect(() => {
		setFormErrors({});
	}, [formFields?.reward, setFormErrors]);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[525px] ">
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="flex justify-between">
						User Affiliation Rewards
					</DialogTitle>
					<DialogDescription>
						Add new rewards for user-affiliation program
					</DialogDescription>
				</DialogHeader>
				<div className="flex flex-col space-y-4 my-4">
					<InputSelect
						label="Events Type"
						options={eventTypeOptions}
						className="w-full"
						value={formFields.eventType}
						setValue={(value) =>
							setFormFields((p) => ({
								...p,
								eventType: value as EventTypes,
							}))
						}
						placeholder="Select Event type"
						error={formErrors.eventType}
					/>

					<div className="flex flex-col w-full gap-2">
						<p className="text-sm font-medium">Reward Types</p>
						<div className="mb-1">
							{formFields?.reward?.length > 0 ? (
								formFields?.reward?.map(
									(_item: any, index: number) => (
										<>
											<AffiliateRewardTypes
												key={_item.rewardType}
												index={index}
												attribute="reward"
												formFields={formFields}
												formErrors={formErrors}
												removeReward={removeReward}
												handleChange={handleChange}
												handleRewardChange={
													handleRewardChange
												}
												setRewardMetadata={setRewardMetadata}
											/>
											{(formFields?.['reward']?.[index]
												?.rewardType ===
												RewardType.TokenUSDC ||
												formFields?.['reward']?.[index]
													?.rewardType ===
													RewardType.TokenUSDT) && (
												<div className="flex flex-grow items-center gap-2 w-full py-1 px-0.5 ">
													<InputText
														placeholder={`Enter ${convertToTitleCase(formFields?.['reward']?.[index]?.rewardType)} Hash`}
														className={`h-9 w-full ${
															formErrors?.transactionHash
																? 'border-destructive'
																: ''
														}`}
														onFocus={(e) => {
															e.target.select();
														}}
														value={
															formFields?.['reward']?.[
																index
															]?.metadata
																?.transactionHash
														}
														setValue={(value) =>
															setRewardMetadata(
																index,
																'transactionHash',
																value,
															)
														}
														error={
															formErrors.transactionHash
														}
														errorText={
															formErrors.transactionHash
														}
													/>
												</div>
											)}
											{/* {formErrors[
												formFields?.reward?.[index]
													?.rewardType
											] ? (
												<p className="mb-0 pt-1 text-right text-xs text-destructive">
													{
														formErrors[
															formFields?.reward?.[
																index
															]?.rewardType
														]
													}
												</p>
											) : null} */}
										</>
									),
								)
							) : (
								<div className=" text-sm text-muted-foreground py-2 border-l pl-3 border-slate-300">
									No reward type specified.
								</div>
							)}
						</div>
						{items < 3 && formFields?.reward?.length < 3 ? (
							<div className="flex justify-end gap-1">
								<Button
									className="mt-3 "
									variant="secondary"
									size="sm"
									onClick={() => addReward()}
								>
									<i className="bi-plus-circle me-2"></i>Add More
									Rewards
								</Button>
							</div>
						) : null}
					</div>
				</div>

				<DialogFooter
					className={`justify-between space-x-2 border-t pt-3 flex sm:justify-between `}
				>
					<div></div>
					<div className="flex gap-2">
						<Button
							variant="outline"
							onClick={() => {
								resetForm();
								setOpen(false);
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={() => updateAffiliateRewards()}
							disabled={isLoading}
						>
							{isLoading ? (
								<i className="bi-arrow-repeat animate-spin me-2"></i>
							) : null}
							Update
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default AffiliateRewardsDialog;

const eventTypeOptions = [
	{
		label: 'Signup',
		value: EventTypes.Signup,
	},
	{
		label: 'First Quest Created',
		value: EventTypes.FirstQuestCreated,
	},
	{
		label: 'Telegram Group Created',
		value: EventTypes.TelegramGroupCreated,
	},
	{
		label: 'Other',
		value: EventTypes.Other,
	},
];
