import { getLoyaltyCampaign } from '@/features/loyalty-campaigns/services/loyalty.service';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { CreditBaseCtaEnum } from '../types/credits.enums';
import { ICreditBenefit } from '../types/credits.types';

interface IEarnBenefit extends ICreditBenefit {
	comingSoon?: boolean;
}

const useEarnSection = ({ user, creditBenefits }) => {
	const handleAction = (action: string) => {
		if (action === CreditBaseCtaEnum.Apply) {
			// open dialogs
		} else if (action === CreditBaseCtaEnum.createQuest) {
			window.open('/app/campaign/quests/new', '_blank');
		}
	};

	const formattedBenefits: IEarnBenefit[] = [
		...creditBenefits,
		{
			_id: uuid(),
			comingSoon: true,
		},
	];

	useEffect(() => {
		let isSubscribed = true;
		const updateData = async () => {
			try {
				await queryClient.invalidateQueries({
					queryKey: ['campaign', 'loyalty-quests'],
				});
				const newData = await getLoyaltyCampaign();

				if (isSubscribed) {
					queryClient.setQueryData(
						['campaign', 'loyalty-quests'],
						newData,
					);
				}
			} catch (error) {
				if (isSubscribed) {
					handleErrorMessage(error);
				}
			}
		};

		updateData();

		return () => {
			isSubscribed = false;
		};
	}, []);

	useEffect(() => {
		analytics.track(TrackingEvents.CreditsEarnViewed, {
			userId: user?._id,
			enterpriseId: user?.enterpriseId,
		});
	}, [user]);

	return {
		handleAction,
		formattedBenefits,
	};
};

export default useEarnSection;
