import { useRouter } from '@/hooks/useRouter';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { getApiRequestById } from '../services/task-apis.service';
import { IApiRequest } from '../types/task-apis.type';
import {
	ApiCredentialTypes,
	CreateTaskAPISteps,
	TaskAPIStatus,
} from '../types/task-apis.enum';

const useTaskApiInitialize = ({ setFormFields, setIsDirty, setCompletedSteps }) => {
	const { query } = useRouter();

	const { data, isLoading } = useQuery({
		queryKey: ['api-requests', query.id],
		queryFn: () => getApiRequestById(query.id as string),
		enabled: !!query.id,
	});

	const formatters = useMemo(
		() => ({
			formatData: (payload: IApiRequest['apiDataFields']) => {
				if (!payload) return [];
				return Object.entries(payload).map(([key, value]) => ({
					key,
					value,
					testValue: '',
				}));
			},

			formatCustomData: (payload: IApiRequest['apiConstantDataFields']) => {
				if (!payload) return [];
				return Object.entries(payload).map(([key, value]) => ({
					key,
					value,
					testValue: '',
				}));
			},

			formatHeaders: (headers: IApiRequest['apiHeaders']) => {
				if (!headers) return [{ key: 'Authorization', value: '' }];
				return Object.entries(headers).map(([key, value]) => ({
					key,
					value,
				}));
			},
		}),
		[],
	);

	const apiDetails = useMemo(() => {
		if (!data) return null;

		return {
			isApiTestDone: false,
			isApiSchemaValid: false,
			responseSchema: {},
			_id: data._id,
			apiName: data.apiName,
			apiEndpoint: data.apiEndpoint,
			apiMethod: data.apiMethod,
			apiPayloadMethod: data.apiDataPassingMethod,
			apiVerificationFor: data.apiVerificationFor,
			isHeaderRequired: data.isHeaderRequired,
			apiPayload: formatters.formatData(data.apiDataFields),
			apiHeaders: formatters.formatHeaders(data.apiHeaders),
			isRecurringTasksEnabled: data.isRecurringTaskEnabled,
			isMetricBasedTaskEnabled: data.isMetricBasedTaskEnabled,
			apiCredentialsType: data.apiCredentialsType,
			isCustomConstantPayloadRequired:
				Array.isArray(data.apiConstantDataFields) &&
				data.apiConstantDataFields.length > 0,
			apiConstantDataFields: formatters.formatCustomData(
				data.apiConstantDataFields,
			),
			graphQlQuery: data.graphQlQuery || '',
			apiOutputExpressions: data.apiOutputExpressions,
			isAdvancedMode:
				data?.isRecurringTaskEnabled || data?.isMetricBasedTaskEnabled,
			status: data?.status as TaskAPIStatus | undefined,
		};
	}, [data, formatters]);

	// Calculate previously completed steps
	const previouslyCompletedSteps = useMemo(() => {
		if (!apiDetails) return [];

		const steps = [];

		// Basic step
		if (apiDetails.apiName?.trim()) {
			steps.push(CreateTaskAPISteps.Basic);
		}

		// Curl step
		if (
			apiDetails.apiCredentialsType === ApiCredentialTypes.GraphQl
				? !!apiDetails.graphQlQuery
				: true
		) {
			steps.push(CreateTaskAPISteps.Curl);
		}

		// Payload step
		const hasValidEndpoint =
			apiDetails.apiEndpoint?.trim() && !apiDetails.apiEndpoint.includes('?');
		const hasValidHeaders =
			!apiDetails.isHeaderRequired ||
			apiDetails.apiHeaders?.every(
				(header) => header.key?.trim() && header.value?.trim(),
			);
		const hasValidConstantPayload =
			!apiDetails.isCustomConstantPayloadRequired ||
			apiDetails.apiConstantDataFields?.every(
				(item) => item.key?.trim() && item.value !== undefined,
			);

		if (hasValidEndpoint && hasValidHeaders && hasValidConstantPayload) {
			steps.push(CreateTaskAPISteps.Payload);
		}

		// Testing step
		if (apiDetails.apiPayload?.every((item) => !!item.testValue)) {
			steps.push(CreateTaskAPISteps.Testing);
		}

		return steps;
	}, [apiDetails]);

	useEffect(() => {
		if (apiDetails) {
			// Direct update instead of functional update to break dependency chain
			setFormFields(apiDetails);
			setIsDirty(false);
			setCompletedSteps(previouslyCompletedSteps);
		}
	}, [
		apiDetails,
		previouslyCompletedSteps,
		setFormFields,
		setIsDirty,
		setCompletedSteps,
	]);

	return {
		isLoadingApiDetails: isLoading,
		apiDetails,
	};
};

export default useTaskApiInitialize;
