import InputText from '@/components/element/inputs/InputText';
import { Label } from '@/components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { ApiCredentialTypes, ApiVerification } from '../types/task-apis.enum';
import ApiMeta from './ApiMeta';
import { Switch } from '@/components/ui/switch';
import InputSwitch from '@/components/element/inputs/InputSwitch';

const ApiDetailsV2 = () => {
	const {
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		setParsedData,
		setIsDirty,
		step,
	} = useConnectAPI();

	const editApiDetails = (
		key: string,
		value: string | Date | boolean | { x: number; y: number },
	) => {
		setIsDirty(true);
		setFormErrors((prev: any) => ({ ...prev, [key]: '' }));
		if (key === 'apiCredentialsType') {
			setParsedData(null);
			setFormFields((prev: any) => ({
				...prev,
				apiCredentialsType: value,
				curlCommand: '',
			}));
			setFormErrors((prev: any) => ({ ...prev, curlCommand: '' }));
		} else {
			setFormFields((prev) => ({ ...prev, [key]: value }));
		}
	};

	return (
		<div className="my-3">
			<div className="flex items-start gap-4">
				<InputText
					label="Name your API"
					required
					placeholder="Verifying Swap Txn"
					className={cn(
						formErrors['apiName'] ? 'border-destructive' : '',
						'w-[60%]',
					)}
					value={formFields?.apiName}
					error={formErrors['apiName']}
					errorText={formErrors['apiName']}
					setValue={(e) => editApiDetails('apiName', e)}
				/>
				<div className="w-[40%]">
					<Label className="flex items-center mb-1">
						API Type - &nbsp;
						<p className="text-slate-600 text-sm font-normal">
							<i className="bi-info-circle me-1"></i>
							Ask your developer
						</p>
					</Label>
					<Select
						value={formFields?.apiCredentialsType}
						onValueChange={(newVal) => {
							editApiDetails('apiCredentialsType', newVal);
						}}
						required
					>
						<SelectTrigger>
							<SelectValue placeholder="Choose the API type" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value={ApiCredentialTypes?.Rest}>
								REST
							</SelectItem>
							<SelectItem value={ApiCredentialTypes?.GraphQl}>
								GraphQL
							</SelectItem>
						</SelectContent>
					</Select>
				</div>
			</div>
			<div className="mt-6 space-y-2">
				<Label>What action will your API verify?</Label>
				<Select
					name="apiVerificationFor"
					value={formFields?.apiVerificationFor}
					onValueChange={(newVal) =>
						editApiDetails('apiVerificationFor', newVal)
					}
				>
					<SelectTrigger aria-label="Area" className="text-sm">
						<SelectValue placeholder="Select" className="h-10 " />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value={ApiVerification?.EVM}>
							On chain action on EVM Networks (Eg. Swap, Transfer)
						</SelectItem>
						<SelectItem value={ApiVerification?.NonEVM}>
							On chain action on non-EVM Networks
						</SelectItem>
						<SelectItem value={ApiVerification?.OffChain}>
							Off chain action (Eg. App sign up)
						</SelectItem>
					</SelectContent>
				</Select>
			</div>

			<InputSwitch
				label="Advanced Options"
				subLabel="Enable advanced options for the task verification with your API"
				subLabelClassName="font-normal mt-2 text-slate-500"
				value={formFields.isAdvancedMode}
				setValue={(e) => editApiDetails('isAdvancedMode', e)}
				className="mt-6"
			/>
			{formFields.isAdvancedMode && <ApiMeta />}
		</div>
	);
};

export default ApiDetailsV2;
