import { AdminInputType, TaskKeys } from '@/features/campaigns/types';
import { TaskStatus } from '@/features/campaigns/types/tasks.enums';
import { useConfirm } from '@/hooks/useConfirm';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import { completeAndSanitizeUrl } from '@/utils/parsers';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { addLoyaltyTask, editLoyaltyTask } from '../../services/loyalty.service';
import { LoyaltyTaskCreateSteps } from '../../types/loyalty.enums';
import { ILoyaltyTask } from '../../types/loyalty.type';
import { useLoyaltyCampaign } from '../useLoyaltyCampaign';
import { useLoyaltyTasks } from './useLoyaltyTasks';
import { useLoyaltyTasksValidate } from './useLoyaltyTasksValidate';

export const useLoyaltyTasksNavigate = () => {
	const { id, campaign } = useLoyaltyCampaign();
	const {
		isDirty,
		setIsDirty,
		setShowAddTask,
		showAddTask,
		setStep,
		step,
		task,
		selectedSectionId,
		isUpdate,
	} = useLoyaltyTasks();
	const { validate } = useLoyaltyTasksValidate();
	const { show } = useConfirm();

	useEffect(() => {
		setStep(LoyaltyTaskCreateSteps.TaskParams);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showAddTask]);

	const [isLoading, setIsLoading] = useState({
		draft: false,
		publish: false,
	});

	const onClose = async () => {
		if (isDirty) {
			const res = await show({
				title: 'You have unsaved changes!',
				subtitle:
					'All unsaved changes will be lost. Are you sure you want to leave this page',
				confirmText: 'Leave',
				cancelText: 'Stay',
				type: 'warning',
			});
			if (res) {
				setIsDirty(false);
				setShowAddTask(false);
			}
		} else {
			setShowAddTask(false);
		}
	};

	const saveAsDraft = async () => {
		try {
			setIsLoading((p) => ({ ...p, draft: true }));
			const body = await prepareBody(TaskStatus.Draft);
			if (isUpdate) {
				await editLoyaltyTask(body);
			} else {
				await addLoyaltyTask(body);
			}
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
			setShowAddTask(false);
			toast.success('Task saved as draft, you can publish it later.');
			setIsDirty(false);
			setIsLoading((p) => ({ ...p, draft: false }));
		} catch (err) {
			handleErrorMessage(err);
			setIsLoading((p) => ({ ...p, draft: false }));
			console.log(err);
		}
	};

	const publishTask = async () => {
		try {
			if (!validate(LoyaltyTaskCreateSteps.TaskDuration)) return;
			setIsLoading((p) => ({ ...p, publish: true }));
			const body = await prepareBody(TaskStatus.Active);
			if (isUpdate) {
				await editLoyaltyTask(body);
			} else {
				await addLoyaltyTask(body);
			}
			queryClient.invalidateQueries({
				queryKey: ['campaign', 'loyalty-quests'],
			});
			if (body.task.startImmediately) {
				toast.success(`Task published successfully`);
			} else {
				toast.success(
					`Task scheduled to be published in ${dayjs(body.task.taskStartDate).fromNow()}`,
				);
			}
			setShowAddTask(false);
			setIsLoading((p) => ({ ...p, publish: false }));
		} catch (err) {
			handleErrorMessage(err);
			setIsLoading((p) => ({ ...p, publish: false }));
			console.log(err);
		}
	};

	const parseMetricBasedXpConfig = (
		config: ILoyaltyTask['metricBasedXpConfig'],
	) => {
		const parsedConfig: ILoyaltyTask['metricBasedXpConfig'] = {
			functionType: config?.functionType,
		};

		if (config.linearMetadata) {
			parsedConfig.linearMetadata = {
				xpMultiplier: parseFloat(
					config?.linearMetadata?.xpMultiplier?.toString(),
				),
			};
		}

		if (config.tieredMetadata) {
			parsedConfig.tieredMetadata = {
				tiers: config.tieredMetadata.tiers.map((tier) => ({
					tierXp: parseFloat(tier?.tierXp?.toString()),
					minMetric: parseFloat(tier?.minMetric?.toString()),
					maxMetric: parseFloat(tier?.maxMetric?.toString()),
				})),
			};
		}

		if (config.sigmoidMetadata) {
			parsedConfig.sigmoidMetadata = {
				optimalMetricValue: parseFloat(
					config?.sigmoidMetadata?.optimalMetricValue?.toString(),
				),
				rateOfIncrease: parseFloat(
					config?.sigmoidMetadata?.rateOfIncrease?.toString(),
				),
			};
		}

		return parsedConfig;
	};

	const prepareBody = async (status: TaskStatus) => {
		const task_ = {
			_id: task._id,
			adminInputs: task.adminInputs?.map((i) => {
				if (
					i.key === TaskKeys.DiscordInviteLink ||
					i.key === TaskKeys.TeleGramGroupLink ||
					i.key === TaskKeys.WebsiteUrl
				) {
					i.value = completeAndSanitizeUrl(i.value as string);
				}
				if (i.inputType === AdminInputType.InputStringArray) {
					return {
						...i,
						value: (i.value as { value: string; id: string }[]).map(
							(i) => i.value,
						),
						// .join(','),
					};
				}
				if (i.inputType === AdminInputType.TwitterTags) {
					if (i.key === TaskKeys.TweetTagsRequired) {
						return {
							...i,
							value: (i.value as number) || 0,
						};
					}
				}

				return i;
			}),
			xp: typeof task.xp === 'string' ? parseInt(task.xp) : task.xp,
			logo: task.logo,
			description: task.description,
			templateFamily: task.templateFamily,
			templateType: task.templateType,
			slides: task.slides,
			isRequiredTask: task.isRequiredTask,
			powVerifyConfig: task.powVerifyConfig,
			maxFailedCountConfig:
				task.maxFailedCountConfig?.isMaxFailedCountEnabled &&
				!task.recurrenceConfig?.isRecurring
					? {
							...task.maxFailedCountConfig,
							maxFailedCount: task.maxFailedCountConfig.maxFailedCount,
						}
					: {
							maxFailedCount: 0,
							isMaxFailedCountEnabled: false,
							isDiminishingXpEnabled: false,
						},
			recurrenceConfig: task.recurrenceConfig?.isRecurring
				? {
						...task.recurrenceConfig,
						frequencyInDays: task.recurrenceConfig.frequencyInDays,
					}
				: {
						isRecurring: false,
						frequencyInDays: 0,
					},
			metricBasedXpConfig: task.metricBasedXpConfig
				? parseMetricBasedXpConfig(task.metricBasedXpConfig)
				: undefined,
			customInitiationURL: task.customInitiationURL,
			status: status,
			noEndTime: task.noEndTime,
			taskEndDate: task.taskEndDate,
			taskEndDateTz: task.taskEndDateTz,
			startImmediately: task.startImmediately,
			taskStartDate: task.taskStartDate,
			taskStartDateTz: task.taskStartDateTz,
			isVisibleAfterCompletion:
				campaign?.metadata?.isVisibleAfterCompletion ?? false,
			requiredLogins:
				task.requiredLogins?.length > 0 ? task.requiredLogins : [],
		};
		return {
			campaignId: id,
			sectionId: selectedSectionId,
			taskId: task._id,
			task: task_,
		};
	};

	const moveBack = () => {
		if (step === LoyaltyTaskCreateSteps.TaskFeatures) {
			setStep(LoyaltyTaskCreateSteps.TaskParams);
		}
		if (step === LoyaltyTaskCreateSteps.TaskDuration) {
			setStep(LoyaltyTaskCreateSteps.TaskFeatures);
		}
	};
	const moveNext = () => {
		if (step === LoyaltyTaskCreateSteps.TaskParams) {
			if (!validate(LoyaltyTaskCreateSteps.TaskParams)) return;
			setStep(LoyaltyTaskCreateSteps.TaskFeatures);
		}
		if (step === LoyaltyTaskCreateSteps.TaskFeatures) {
			if (!validate(LoyaltyTaskCreateSteps.TaskFeatures)) return;
			setStep(LoyaltyTaskCreateSteps.TaskDuration);
		}
	};

	return {
		onClose,
		saveAsDraft,
		moveNext,
		moveBack,
		isLoading,
		publishTask,
	};
};
