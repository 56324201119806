import ChainBadge from '@/components/element/badges/ChainBadge';
import {
	AdminInputType,
	ITaskAdminInput,
	TaskKeys,
} from '@/features/campaigns/types';
import { axios } from '@/lib/axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const TaskAdminValue = ({ adminInput }: { adminInput: ITaskAdminInput }) => {
	if (!adminInput) return null;
	if (adminInput.value === undefined || adminInput.value === null) return null;
	if (
		adminInput.inputType === AdminInputType.InputAddress ||
		adminInput.inputType === AdminInputType.InputString ||
		adminInput.inputType === AdminInputType.InputNumber ||
		adminInput.inputType === AdminInputType.SelectFromAdminInput ||
		adminInput.inputType === AdminInputType.InputStringArray
	)
		return <>{adminInput.value?.toString()}</>;
	if (
		adminInput.inputType === AdminInputType.InputCheckbox ||
		adminInput.inputType === AdminInputType.InputSwitch
	)
		return <>{adminInput.value ? 'Yes' : 'No'}</>;

	if (adminInput.inputType === AdminInputType.TwitterTags)
		return <>{adminInput.value}</>;
	// if (adminInput.inputType === AdminInputType.SelectNetwork) {
	// 	const chainId = adminInput.value as number;
	// 	return <ChainBadge chainId={chainId} className="" />;
	// }
	if (adminInput.inputType === AdminInputType.InputDate) {
		return <>{dayjs(adminInput.value as Date).format('Do MMM YY hh:mmZ')}</>;
	}
	if (adminInput.inputType === AdminInputType.SelectAsync) {
		return <SelectAsyncValue adminInput={adminInput} />;
	}
	if (adminInput.inputType === AdminInputType.Select) {
		const options = adminInput.options;
		const label = options.find((i) => i.value === adminInput.value)?.label;
		return label;
	}
	if (adminInput.inputType === AdminInputType.InputMultiSelectAsync) {
		return <MultiSelectAsyncValue adminInput={adminInput} />;
	}
	return <>NA</>;
};

export default TaskAdminValue;

const SelectAsyncValue = ({ adminInput }: { adminInput: ITaskAdminInput }) => {
	const [options, setOptions] = useState([]);

	useEffect(() => {
		axios.get(adminInput.optionsFrom).then(({ data }) => {
			setOptions(
				data.map(
					(option: {
						label: string;
						description: string;
						value: string;
					}) => ({
						label: (
							<div className="">
								{option.label}{' '}
								<div>
									<div className="text-xs text-muted-foreground mt-1">
										{option.description}
									</div>
								</div>
							</div>
						),
						value: option.value,
						name: option.label,
					}),
				),
			);
		});
	}, [adminInput.optionsFrom]);

	const selected = (options ?? []).find((i) => i?.value === adminInput?.value);
	return <>{selected?.name}</>;
};

const MultiSelectAsyncValue = ({ adminInput }: { adminInput: ITaskAdminInput }) => {
	const [options, setOptions] = useState([]);
	const [selectedIds, setSelectedIds] = useState([]);
	const [selectedOptions, setSelectedOptions] = useState([]);

	useEffect(() => {
		const fetchOptions = async () => {
			try {
				const { data } = await axios.get(adminInput.optionsFrom);
				let fetchedOptions;
				if (adminInput.key === TaskKeys.IntractMedallions) {
					fetchedOptions = data.map(
						(option: {
							label: string;
							medallionHubName: string;
							medallionHubId: string;
							medallionId: string;
						}) => ({
							label: (
								<div className="">
									{option.label}
									<div>
										<div className="text-xs text-muted-foreground mt-1">
											{option.medallionHubName}
										</div>
									</div>
								</div>
							),
							value: `${option.medallionHubId} | ${option.medallionId}`,
							name: option.medallionHubName,
						}),
					);
				} else {
					fetchedOptions = data.map(
						(option: { label: string; name: string; _id: string }) => ({
							label: (
								<div className="">
									{option.label}
									<div>
										<div className="text-xs text-muted-foreground mt-1">
											{option.name}
										</div>
									</div>
								</div>
							),
							value: option._id,
							name: option.name,
						}),
					);
				}
				setOptions(fetchedOptions);
			} catch (error) {
				console.error('Error fetching options:', error);
			}
		};

		fetchOptions();
	}, [adminInput.optionsFrom]);

	useEffect(() => {
		if (adminInput?.inputType === AdminInputType.InputMultiSelectAsync) {
			let newValues: Array<{ value: string; id: string }> = [];

			if (Array.isArray(adminInput.value)) {
				if (typeof adminInput.value[0] === 'string') {
					newValues = adminInput.value.map((val) => ({
						value: val,
						id: val,
					}));
				} else {
					newValues = adminInput.value as Array<{
						value: string;
						id: string;
					}>;
				}
			} else if (typeof adminInput.value === 'string') {
				const values = adminInput.value.split(',');
				newValues = values.map((val) => ({
					value: val.trim(),
					id: val.trim(),
				}));
			}

			adminInput.value = newValues;
			setSelectedIds(newValues.map((item) => item.id));
		}
	}, [adminInput]);

	useEffect(() => {
		const selectedOptions = options.filter((option) =>
			selectedIds.includes(option.value),
		);
		setSelectedOptions(selectedOptions);
	}, [options, selectedIds]);

	return (
		<ul className="list-disc list-inside">
			{selectedOptions?.length > 0 ? (
				selectedOptions?.map((option) => (
					<li key={option.value} className="text-sm">
						{option.name}
					</li>
				))
			) : (
				<div className="text-sm">No selections found</div>
			)}
		</ul>
	);
};
