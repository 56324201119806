import StatusBadge from '@/components/element/badges/StatusBadge';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { useConfirm } from '@/hooks/useConfirm';
import { useRouter } from '@/hooks/useRouter';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { TrackingEvents } from '@/types/tracking.type';
import { useMutation } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { toast } from 'sonner';
import { deleteTaskAPI } from '../services/task-apis.service';
import { TaskAPIStatus } from '../types/task-apis.enum';
import { IApiRequest } from '../types/task-apis.type';
import { useConnectAPI } from './useConnectAPI';

const useTaskAPITable = () => {
	const { show } = useConfirm();
	const { setFormFields, setStep } = useConnectAPI();
	const { navigate } = useRouter();

	const deleteApiMutation = useMutation({
		mutationFn: deleteTaskAPI,
		onSuccess: () => {
			toast.success('API deleted successfully');
			queryClient.invalidateQueries({ queryKey: ['api-requests'] });
		},
	});

	const copyText = (endpoint: string, name: string) => {
		navigator.clipboard.writeText(endpoint);
		toast.success(
			`${name ? name : 'API endpoint'} has been copied on your clipboard`,
		);
	};

	const editApi = (data: IApiRequest) => {
		analytics.track(TrackingEvents.TaskAPIsCreateViewed, {
			isUpdate: true,
			apiId: data?._id,
			apiName: data?.apiName,
		});

		navigate(`/app/tools/task-apis/${data?._id}/update`);
	};

	const deleteConfirmation = async (data: any) => {
		const res = await show({
			title: 'Delete API',
			subtitle: `Are you sure you want to delete ${data.apiName} API?`,
			confirmText: 'Delete',
		});
		if (!res) return;
		await deleteApiMutation.mutateAsync(data);
	};

	const options = (row: any) => [
		{
			type: 'button',
			label: 'Edit',
			disabled: row?.original?.campaigns?.length > 0,
			onClick: () => editApi(row.original),
			tooltip:
				'You can not edit Task APIs which are being used in active campaigns. If there are issues with this API, you can connect a new API and change the API being used in the respective campaign tasks.',
		},
		{
			type: 'separator',
		},
		{
			type: 'button',
			label: 'Delete',
			onClick: () => deleteConfirmation(row.original),
		},
	];

	const taskApiColumns: ColumnDef<IApiRequest>[] = [
		{
			accessorKey: 'apiName',
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					className="px-1"
				/>
			),
			cell: ({ row }) => <div className="p-1">{row?.original?.apiName}</div>,
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'apiEndpoint',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Endpoint" />
			),
			cell: ({ row }) => (
				<span className="font-normal truncate text-ellipsis text-xs rounded-sm px-3 py-2 bg-muted">
					<span className="me-2 font-semibold">
						{row?.original?.apiMethod}
					</span>
					{row?.original?.apiEndpoint}
					{row?.original?.apiEndpoint && (
						<Button
							variant="ghost"
							size="sm"
							className="ml-2 p-0"
							onClick={() =>
								copyText(
									row?.original?.apiEndpoint,
									row?.original?.apiName,
								)
							}
						>
							<i className="bi-clipboard-fill text-muted-foreground"></i>
						</Button>
					)}
				</span>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ row }) => (
				<div className="space-x-2 flex items-center">
					{row?.original?.campaigns?.length > 0 ? (
						<StatusBadge status={'ACTIVE'} className="text-xs" />
					) : row?.original?.status === TaskAPIStatus.Submitted ? (
						<TooltipWrapper
							tooltip={
								'Your API has been submitted for approval, please check back in 2 mins.'
							}
							align="end"
							triggerClassName="flex items-center gap-1"
						>
							<StatusBadge status={row?.original?.status} />
							<i className="bi bi-info-circle text-slate-700 text-xs"></i>
						</TooltipWrapper>
					) : (
						<StatusBadge
							status={row?.original?.status}
							className="text-xs"
						/>
					)}

					{row?.original?.status === TaskAPIStatus.Rejected ? (
						<Badge
							variant="destructive"
							className="cursor-pointer"
							onClick={() =>
								toast.error(`API Rejection Reason`, {
									description: ` ${row.original.apiName} API was rejected due to ${row.original.errorMessage}`,
									action: <Button onClick={() => {}}>OK</Button>,
								})
							}
						>
							Check Error
						</Badge>
					) : null}
				</div>
			),
			enableSorting: true,
		},
		{
			id: 'actions',
			cell: ({ row }) => {
				return (
					<DataTableRowActions row={row} options={options(row) as any} />
				);
			},
			enableSorting: false,
			enableHiding: false,
		},
	];

	return {
		taskApiColumns,
	};
};

export default useTaskAPITable;
