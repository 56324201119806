import Spinner from '@/components/element/loading/Spinner';
import { Input } from '@/components/ui/input';
import { useCreateCampaignValidateAdminInputs } from '@/features/campaigns/hooks/create/useCreateCampaignValidateAdminInputs';
import { cn } from '@/lib/utils';
import { ITaskInputsProps } from './TaskInputs';
import { useEffect, useMemo, useState } from 'react';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';

const TaskInputSelectAdminInput = ({
	adminInput,
	adminInputs,
	taskId,
	error,
	setErrors,
	editTaskAdminInput,
}: ITaskInputsProps) => {
	const [options, setOptions] = useState([]);

	useEffect(() => {
		if (!adminInputs) return;
		const options = adminInputs?.find(
			(i) => i.key === adminInput.optionsFrom,
		)?.value;
		let options_ = [];
		if (options && Array.isArray(options)) {
			options_ =
				options
					.map((i) => ({
						value: i?.value,
						id: i?.id,
					}))
					?.filter((i) => i?.value) ?? [];
		}
		if (
			options_ &&
			options_.length > 0 &&
			adminInput.value &&
			!options_.find((i) => i.value === adminInput.value)
		) {
			editTaskAdminInput(taskId, adminInput.key, []);
		} else {
			setOptions(options_);
		}
	}, [adminInput, adminInputs, taskId, editTaskAdminInput]);

	const isDisabled = useMemo(() => {
		const options = adminInputs?.find(
			(i) => i.key === adminInput.optionsFrom,
		)?.value;

		if (options && Array.isArray(options)) {
			return options.length !== new Set(options.map((i) => i.value)).size;
		}
		return true;
	}, [adminInputs, adminInput.optionsFrom, options]);

	if (!options || options.length === 0) return null;
	return (
		<>
			<div className="relative">
				<div className="text-sm font-medium mb-1">{adminInput.label}</div>
				{adminInput.description && (
					<div className="text-xs text-muted-foreground mb-1">
						{adminInput.description}
					</div>
				)}
				<Select
					value={adminInput.value as string}
					onValueChange={(e) => {
						editTaskAdminInput(taskId, adminInput.key, e);
					}}
					disabled={isDisabled}
				>
					<SelectTrigger
						aria-label="Area"
						className={cn('text-sm', error && 'border-destructive')}
					>
						<SelectValue placeholder={adminInput.placeholder} />
					</SelectTrigger>
					<SelectContent>
						{options?.map((i) => (
							<SelectItem key={i.id} value={i.value}>
								{i.value}
							</SelectItem>
						)) ?? []}
					</SelectContent>
				</Select>
				{error && (
					<div className="text-xs text-destructive mt-1">
						{error ?? 'This field is required'}
					</div>
				)}
			</div>
		</>
	);
};

export default TaskInputSelectAdminInput;
