import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { useMemo, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Separator } from '@/components/ui/separator';
import ChainBadge from '../badges/ChainBadge';
import { IdentityNamespaceTag } from '@/features/campaigns/types';

interface Network {
	name: string;
	value: string;
	chainId: string;
	img: string;
	type: string;
	chain: string;
	namespaceTag: IdentityNamespaceTag;
}

export function SelectNetwork2({
	value,
	setValue,
	error,
	errorMsg,
	allowedChainIds,
	placeholder,
	defaultAll = true,
	withoutPortal,
	disabled,
	showNonEVM,
	showAddCustomChain,
	setShowAddCustomChain,
	fetchEnterpriseChains,
	addCustomChain,
}: {
	value: string | string[];
	setValue: (network: Network) => void;
	allowedChainIds?: string[];
	error?: boolean | string;
	errorMsg?: string;
	placeholder?: string;
	defaultAll?: boolean;
	withoutPortal?: boolean;
	disabled?: boolean;
	showNonEVM?: boolean;
	showAddCustomChain?: boolean;
	setShowAddCustomChain?: Dispatch<SetStateAction<boolean>>;
	fetchEnterpriseChains?: boolean;
	addCustomChain?: boolean;
}) {
	const [searchTerm, setSearchTerm] = useState('');
	const [open, setOpen] = useState(false);

	const { chains, getNativeChainLogo, getChainName } = useGetSupportedChains({
		showAdditionalChains: false,
		fetchEnterpriseChains: fetchEnterpriseChains,
		showNonEVM: showNonEVM,
	});

	const networks = useMemo(() => {
		if (!chains) return [];
		return [
			{
				title: 'Enterprise Chains',
				networks: chains
					.filter((chain) => !chain.isTestnet && !chain.isCustom)
					?.map((i) => ({
						name: i.chainName,
						value: i.chainId?.toString(),
						chainId: i.chainId?.toString(),
						chain: i.chain,
						namespaceTag: i.namespaceTag,
						img: i.chainLogo,
						type: 'EVM',
					})),
			},
			{
				title: 'Custom Enterprise Chains',
				networks: chains
					.filter((chain) => chain.isCustom && !chain.isTestnet)
					?.map((i) => ({
						name: i.chainName,
						value: i.chainId?.toString(),
						chainId: i.chainId?.toString(),
						chain: i.chain,
						namespaceTag: i.namespaceTag,
						img: i.chainLogo,
						type: 'CUSTOM',
					})),
			},
			{
				title: 'Testnets',
				networks: chains
					.filter((chain) => chain.isTestnet)
					?.map((i) => ({
						name: i.chainName,
						value: i.chainId?.toString(),
						chainId: i.chainId?.toString(),
						chain: i.chain,
						namespaceTag: i.namespaceTag,
						img: i.chainLogo,
						type: 'EVM_TESTNET',
					})),
			},
		]
			.map((network) => {
				if (!allowedChainIds || (allowedChainIds.length === 0 && defaultAll))
					return network;
				return {
					...network,
					networks: network.networks.filter((net: any) =>
						allowedChainIds.includes(net.chainId),
					),
				};
			})
			.filter((network) => network.networks.length > 0);
	}, [chains, allowedChainIds, defaultAll]);

	const [filteredNetworks, setFilteredNetworks] = useState(networks);

	useEffect(() => {
		const filtered = networks.map((network) => ({
			...network,
			networks: network.networks.filter((net) => {
				return net.name
					.toLowerCase()
					.includes(searchTerm.trim().toLowerCase());
			}),
		}));
		setFilteredNetworks(filtered);
	}, [searchTerm, networks]);

	useEffect(() => {}, [filteredNetworks]);

	const selectedValues = Array.isArray(value) ? value : [value];
	const selectedNetwork = networks
		.flatMap((n) => n?.networks)
		.find((n) => n?.chainId?.toString() === value);
	const chain = selectedNetwork?.chain;
	const namespaceTag = selectedNetwork?.namespaceTag;

	const renderChains = () => {
		return (
			<>
				{networks?.map((network, index) => (
					<CommandGroup key={network.title}>
						<CommandItem
							className={`!bg-transparent hover:!bg-transparent text-xs font-medium pl-2 my-2 `}
						>
							{network.title}
						</CommandItem>
						{network?.networks?.map((net) => (
							<CommandItem
								key={net.value}
								// value={net.chainId.toString()}
								onSelect={() => {
									setValue(net);
									setOpen(false);
								}}
								className="cursor-pointer"
							>
								<div className="flex py-1 ">
									<img
										src={net.img}
										alt=""
										className="size-5 me-2 rounded-lg object-cover"
									/>
									{net.name}
								</div>
							</CommandItem>
						))}
					</CommandGroup>
				))}
			</>
		);
	};

	return (
		<>
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger asChild>
					<Button
						variant="outline"
						className="w-full justify-between px-3 hover:!bg-white"
						disabled={disabled}
						onClick={() => setOpen(!open)}
					>
						<ChainBadge
							chainId={value as string}
							chain={chain}
							namespaceTag={namespaceTag}
							showNonEVM={showNonEVM}
							fetchEnterpriseChains={fetchEnterpriseChains}
							className="max-w-[10rem] truncate"
						/>
					</Button>
				</PopoverTrigger>
				<PopoverContent
					className="p-0"
					align="start"
					withoutPortal={withoutPortal}
				>
					<Command>
						<CommandInput
							placeholder={placeholder || 'Select Chains'}
							className="border-none focus:ring-0 focus:outline-none"
							// onValueChange={(val) => setSearchTerm(val)}
						/>
						<CommandList>
							<CommandEmpty className="py-2 px-4 text-sm text-center">
								No such chain found
							</CommandEmpty>
							{renderChains()}
							{addCustomChain ? (
								<>
									<Separator />
									<Button
										variant="ghost"
										className="w-full text-sm font-normal"
										onClick={() => setShowAddCustomChain(true)}
									>
										<i className="bi bi-plus-circle me-2"></i>{' '}
										Add Custom Chain
									</Button>
								</>
							) : null}
						</CommandList>
					</Command>
				</PopoverContent>
			</Popover>
			{error ? (
				<p className="text-destructive text-xs mt-1">{errorMsg || error}</p>
			) : null}
		</>
	);
}
