import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { homepageContent } from '../../content/homepage.content';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Features = () => {
	const [selected, setSelected] = useState('1');
	const content = homepageContent.features;
	const selectedContent = content.coreFeatures.find((i) => i.id === selected);
	return (
		<div id="features">
			<div className="mx-auto w-full max-w-screen-xl px-2.5 lg:px-20 pb-0 pt-24">
				<div className="mx-auto max-w-4xl text-center sm:max-w-4xl">
					<h2 className="font-display text-4xl font-extrabold leading-tight text-black sm:text-5xl sm:leading-tight">
						<span className="bg-gradient-to-r from-red-400 to-violet-600 bg-clip-text text-transparent">
							Powerful features
						</span>
						&nbsp;for
						<br />
						Web3 communities
					</h2>
					<p className="mt-5 text-gray-600 sm:text-lg">
						{content.description}
					</p>
				</div>
				<div className="relative flex gap-20 overflow-hidden masked-overflow px-16 mt-10">
					<div className="flex flex-row items-center gap-3 animate-marquee3 whitespace-nowrap ">
						{content.featureList.map((i) => (
							<span className="px-3 py-2 flex flex-row items-center gap-3 bg-zinc-100 rounded-xl">
								<span className="para-display-medium">
									{i.emoji}
								</span>
								<span className="body-medium">{i.title}</span>
							</span>
						))}
					</div>
					<div className="absolute top-0 -left-3 flex flex-row items-center gap-3 animate-marquee2 whitespace-nowrap">
						{content.featureList.map((i) => (
							<span className="px-3 py-2 flex flex-row items-center gap-3 bg-zinc-100 rounded-xl">
								<span className="para-display-medium">
									{i.emoji}
								</span>
								<span className="body-medium">{i.title}</span>
							</span>
						))}
					</div>
				</div>
				<div className="my-10 w-full overflow-hidden rounded-xl border border-gray-200 bg-white/10 shadow-[inset_10px_-50px_94px_0_rgb(199,199,199,0.2)] backdrop-blur ">
					<div className="grid grid-cols-1 gap-10 p-5 lg:grid-cols-12">
						<div className="lg:col-span-5">
							<Accordion
								type="single"
								collapsible
								className="w-full mt-5"
								value={selected}
								onValueChange={(e) => {
									if (e) setSelected(e);
								}}
							>
								{content.coreFeatures.map((feature) => (
									<FeatureListItem
										{...feature}
										key={feature.id}
										selectedContent={selectedContent}
									/>
								))}
							</Accordion>
						</div>
						<div className="lg:col-span-7 overflow-hidden hidden md:block ">
							<div
								className="relative -mb-6 aspect-[1735/990] overflow-hidden rounded-t-2xl shadow-2xl lg:mt-24 lg:h-[500px] lg:w-[800px] xl:mt-10 "
								style={{ opacity: 1, transform: 'none' }}
							>
								<img
									alt="features"
									loading="lazy"
									width={1735}
									height={990}
									decoding="async"
									data-nimg={1}
									className="blur-0 absolute h-full object-contain"
									style={{ color: 'transparent' }}
									src={selectedContent.image}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Features;

const FeatureListItem = ({ id, title, description, cta, icon, selectedContent }) => {
	return (
		<AccordionItem
			className="border-b border-b-slate-200 py-0 last:border-none"
			value={`${id}`}
		>
			<AccordionTrigger>
				<div className="flex items-center space-x-3 p-3 pb-0">
					<i className={`bi-${icon}`} />
					<h3 className="text-left text-base font-semibold text-gray-600">
						{title}
					</h3>
				</div>
			</AccordionTrigger>
			<AccordionContent className=" overflow-hidden text-sm text-gray-500 transition-all sm:text-base">
				<div className="p-3 pt-0">
					<p className="mb-5 text-sm text-gray-500">{description}</p>
					{/* <Link
						className="rounded-full mx-auto max-w-fit border px-5 py-2 text-sm font-medium shadow-sm transition-all hover:ring-4 hover:ring-gray-200 disabled:bg-gray-100 disabled:text-gray-500 disabled:cursor-not-allowed disabled:hover:ring-0 disabled:border-gray-200 border-black bg-black text-white hover:bg-gray-800"
						to={cta.link}
					>
						{cta.label}
					</Link> */}
					<div className="lg:col-span-7 overflow-hidden block md:hidden ">
						<div
							className="relative -mb-6 aspect-[1735/990] overflow-hidden rounded-t-2xl shadow-2xl lg:mt-24 lg:h-[500px] lg:w-[800px] xl:mt-10 "
							style={{ opacity: 1, transform: 'none' }}
						>
							<img
								alt="features"
								loading="lazy"
								width={1735}
								height={990}
								decoding="async"
								data-nimg={1}
								className="blur-0 absolute h-full object-contain"
								style={{ color: 'transparent' }}
								src={selectedContent.image}
							/>
						</div>
					</div>
				</div>
			</AccordionContent>
		</AccordionItem>
	);
};
