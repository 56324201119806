import { cn } from '@/lib/utils';
import { DialogTitle } from '@/components/ui/dialog';

const SpeedrunDetailsPostActive = () => {
	return (
		<div>
			<div className="mb-4">
				<DialogTitle className={cn(`text-2xl mb-1 `)}>
					Introducing Speedrun
				</DialogTitle>
				<div className="text-sm">
					Speedrun maximises user engagement by encouraging them to
					complete quests quickly through gamified rewards, enhancing your
					brand’s visibility and participation
				</div>
			</div>

			<div
				className={cn(
					`opacity-0 [--slidein-delay:300ms]`,
					'animate-slide-left',
				)}
			>
				<div className="mt-6 font-medium">How it works</div>
				<div className="mt-2 text-sm ">
					{howItWorks.map((i) => (
						<div
							className="px-3 py-2 bg-slate-100 border rounded-md mb-2 flex"
							key={i.title}
						>
							<div>
								<div className="text-sm ">{i.title}</div>
							</div>
						</div>
					))}
				</div>

				<div className="mt-6 mb-4 italic  text-sm">
					We have achieved
					<span className="font-medium mx-1 px-1 bg-yellow-100 rounded">
						CAC of $0.08/user
					</span>
					across Speedrun events conducted till date
				</div>
			</div>
		</div>
	);
};

export default SpeedrunDetailsPostActive;

const howItWorks = [
	{
		title: '🔘 User starts the speedrun to activate their participation',
	},
	{
		title: '⌛️ They finish curated quests within 1 hour',
	},
	{
		title: '🎁 User gets rewarded instantly with quest incentives',
	},
];
