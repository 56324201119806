import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { IAdminDaveActions } from '../../types/admin.types';
import { convertToTitleCase, getSentenceCase } from '@/utils/parsers';
import ChainBadge from '@/components/element/badges/ChainBadge';
import { Badge } from '@/components/ui/badge';
import dayjs from 'dayjs';
import { getScanLink } from '@/utils/blockChain';
import { Button } from '@/components/ui/button';

export const daveColumns = (options: any): ColumnDef<IAdminDaveActions>[] => {
	const cols: ColumnDef<IAdminDaveActions>[] = [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" />
			),
			cell: ({ row }) => <div className="py-1">{row.getValue('name')}</div>,
			enableSorting: true,
			enableHiding: false,
		},

		{
			accessorKey: 'chainId',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Chain" />
			),
			cell: ({ row }) => (
				<ChainBadge
					chainId={row.getValue('chainId')}
					className=" py-1 flex items-center"
					chain={row.original?.chain?.toString()}
					namespaceTag={row.original?.namespaceTag}
				/>
			),
			enableSorting: true,
			enableHiding: false,
		},

		{
			accessorKey: 'userAddressSource',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Filter Mechanism" />
			),
			cell: ({ row }) => (
				<Badge variant="secondary">
					{convertToTitleCase(row.original.filterMechanism)}
				</Badge>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'userAddressSource',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="User Address Source" />
			),
			cell: ({ row }) => (
				<Badge variant="secondary">
					{convertToTitleCase(row.original.userAddressSource)}
				</Badge>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'category',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Category" />
			),
			cell: ({ row }) => (
				<Badge className="" variant="secondary">
					{convertToTitleCase(row.getValue('category'))}
				</Badge>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'enterprise',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Enterprise" />
			),
			cell: ({ row }) => (
				<div className="py-1">{row.original?.enterprise?.name}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'createdAt',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Created At" />
			),
			cell: ({ row }) => (
				<div className="py-1">{dayjs(row.original.createdAt).fromNow()}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'isApproved',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Approved?" />
			),
			cell: ({ row }) => (
				<Badge
					variant="secondary"
					className={row.original.isApproved ? 'bg-green-100' : ''}
				>
					{row.original.isApproved ? 'Approved' : 'Unapproved'}
				</Badge>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => (
				<div className="flex items-center gap-2">
					{
						<Button
							size="icon"
							variant="ghost"
							onClick={(e) => {
								{
									e.stopPropagation();
									const link = getScanLink(
										row.original.transactionTo
											? row.original.transactionTo
											: row.original.transactionFrom,
										Number(row.original.chainId),
										'address',
									);
									window.open(link, '_blank');
								}
							}}
						>
							<i className="bi-box-arrow-in-up-right"></i>
						</Button>
					}
					<DataTableRowActions row={row} options={options} />
				</div>
			),
		},
	];
	return cols;
};
