import { useLoyaltyTasks } from './useLoyaltyTasks';
import { ITaskTemplates } from '@/features/campaigns/types';
import { v4 as uuidv4 } from 'uuid';
import {
	TaskStatus,
	MetricBasedXpFunction,
} from '@/features/campaigns/types/tasks.enums';
import { ILoyaltyTask } from '../../types/loyalty.type';
import { useTaskTemplates } from '@/features/campaigns/hooks/create/useTaskTemplates';
import { useLoyaltyCampaign } from '../useLoyaltyCampaign';
import { getTimezone } from '@/lib/utils';

export const useLoyaltyTasksInitialise = () => {
	const { rawTemplates } = useTaskTemplates();
	const { setTemplate, setTask, setShowAddTask, setIsUpdate } = useLoyaltyTasks();
	const { campaign } = useLoyaltyCampaign();

	const formatTaskTemplate = (template: ITaskTemplates) => {
		setTemplate(template);
		setTask({
			_id: uuidv4(),
			logo: template.logo,
			description: template.name,
			xp: template.xp,
			adminInputs: template.adminInputs,
			templateFamily: template.templateFamily,
			templateType: template.templateType,
			slides: [],
			maxFailedCountConfig: {
				isMaxFailedCountEnabled: false,
				maxFailedCount: 0,
				isDiminishingXpEnabled: false,
			},
			isRequiredTask: true,
			powVerifyConfig: {
				isPOWVerify: template?.featureApplicability?.powVerification,
			},
			recurrenceConfig: {
				isRecurring: false,
				frequencyInDays: 0,
			},
			appliedFeatures: {
				slides: {
					enabled: false,
				},
				recurrance: {
					enabled: false,
				},
				maxFailedCount: {
					enabled: false,
				},
				powVerification: {
					enabled: false,
				},
				metricBasedXp: {
					enabled: false,
				},
			},
			featureApplicability: {
				recurrence: template?.featureApplicability?.recurrence || false,
				metricBasedXp:
					template?.featureApplicability?.metricBasedXp || false,
			},
			customInitiationURL: '',
			status: TaskStatus.Draft,
			taskEndDate: null,
			taskEndDateTz: getTimezone(),
			taskStartDate: null,
			taskStartDateTz: getTimezone(),
			startImmediately: true,
			noEndTime: true,
			isVisibleAfterCompletion:
				campaign?.metadata?.isVisibleAfterCompletion ?? false,
			requiredLogins:
				template.requiredLogins && template.requiredLogins?.length > 1
					? [template.requiredLogins[0]]
					: template.requiredLogins,
		});
		setShowAddTask(true);
		setIsUpdate(false);
	};

	const updateAdminInputs = (
		templateAdminInputs: any[],
		activeTaskAdminInputs: any[],
	) => {
		return templateAdminInputs?.map((i) => {
			const activeInput = activeTaskAdminInputs.find((ai) => ai.key === i.key);
			let value = activeInput?.value;

			if (i.inputType === 'INPUT_STRING_ARRAY') {
				if (typeof value === 'string') {
					// Convert comma-separated string to array of objects
					value = value.split(',').map((v) => ({
						id: uuidv4(),
						value: v.trim(),
					}));
				} else if (Array.isArray(value) && typeof value[0] === 'string') {
					// Convert array of strings to array of objects
					value = value.map((v) => ({
						id: uuidv4(),
						value: v.trim(),
					}));
				}
			}

			return {
				...i,
				value: value,
			};
		});
	};

	const editDraftTask = (draftTaskId: string) => {
		const draftTask = campaign?.quest?.tasks.find(
			(t) => t._id === draftTaskId,
		) as ILoyaltyTask;
		const template_ = rawTemplates.find(
			(t) => t.templateType === draftTask.templateType,
		);
		setTemplate(template_);

		const updatedAdminInputs = updateAdminInputs(
			template_.adminInputs,
			draftTask.adminInputs,
		);
		setTask({
			...draftTask,
			adminInputs: updatedAdminInputs,
		});
		setIsUpdate(true);
		setShowAddTask(true);
	};

	const editActiveTask = (taskId: string) => {
		const activeTask = campaign?.quest?.tasks?.find(
			(t) => t._id === taskId,
		) as ILoyaltyTask;

		const template_ = rawTemplates.find(
			(t) => t.templateType === activeTask.templateType,
		);

		setTemplate(template_);

		const updatedAdminInputs = updateAdminInputs(
			template_.adminInputs,
			activeTask.adminInputs,
		);

		setTask({
			...activeTask,
			adminInputs: updatedAdminInputs,
		});

		setIsUpdate(true);
		setShowAddTask(true);
	};

	const duplicateTask = (taskId: string) => {
		const task = campaign?.quest?.tasks?.find(
			(t) => t._id === taskId,
		) as ILoyaltyTask;
		const template_ = rawTemplates.find(
			(t) => t.templateType === task.templateType,
		);
		const newTask: ILoyaltyTask = {
			_id: uuidv4(),
			logo: task.logo,
			description: task.description,
			xp: task.xp,
			templateFamily: task.templateFamily,
			templateType: task.templateType,
			slides: task.slides ?? [],
			maxFailedCountConfig: task.maxFailedCountConfig,
			isRequiredTask: task.isRequiredTask,
			powVerifyConfig: task.powVerifyConfig,
			recurrenceConfig: task.recurrenceConfig,
			customInitiationURL: task.customInitiationURL,
			status: TaskStatus.Draft,
			adminInputs: template_.adminInputs?.map((i) => ({
				...i,
				value: task.adminInputs.find((ai) => ai.key === i.key)?.value,
			})),
			taskEndDate: null,
			taskStartDate: null,
			taskEndDateTz: getTimezone(),
			taskStartDateTz: getTimezone(),
			startImmediately: true,
			noEndTime: true,
			isVisibleAfterCompletion: task.isVisibleAfterCompletion,
			requiredLogins: task.requiredLogins,
			appliedFeatures: {
				slides: {
					enabled: task.slides?.length > 0,
				},
				recurrance: {
					enabled: task.recurrenceConfig?.isRecurring,
				},
				maxFailedCount: {
					enabled: task.maxFailedCountConfig?.isMaxFailedCountEnabled,
				},
				powVerification: {
					enabled: task.powVerifyConfig?.isPOWVerify,
				},
				metricBasedXp: {
					enabled:
						task.metricBasedXpConfig.functionType !==
						MetricBasedXpFunction.None,
				},
			},
			featureApplicability: {
				recurrence: template_.featureApplicability?.recurrence || false,
				metricBasedXp:
					template_.featureApplicability?.metricBasedXp || false,
			},
		};
		setTask(newTask);
		setTemplate(template_);
		setShowAddTask(true);
		setIsUpdate(false);
	};

	return {
		formatTaskTemplate,
		editDraftTask,
		editActiveTask,
		duplicateTask,
	};
};
