import { Button } from '@/components/ui/button';

const SpeedrunEnrollmentComplete = ({ setOpen }) => {
	return (
		<div className="w-full flex flex-col items-center justify-center h-full py-10">
			<div className="bg-green-100 h-16 w-16 flex items-center justify-center rounded-full relative animate-zoom-in">
				<i className="bi bi-check-circle-fill text-5xl text-green-500 z-10 relative"></i>
			</div>
			<div className="text-center px-10">
				<div className="text-md font-semibold text-gray-800 mt-4">
					Enrolled in Speedrun Booster
				</div>
				<div className="text-sm text-gray-500 mt-1 ">
					You have successfully enrolled in the Speedrun Booster. We will
					add your campaign soon to our next speedrun to bring you some
					extra beatuful users.
				</div>
				<div className="mt-10 px-5">
					<Button
						variant="secondary"
						className="w-full"
						onClick={() => setOpen(false)}
					>
						Got It
					</Button>
				</div>
			</div>
		</div>
	);
};

export default SpeedrunEnrollmentComplete;
