import { memo, useMemo } from 'react';
import StepCard from './StepCard';
import { useConnectAPI } from '../../hooks/useConnectAPI';
import en from '../../../../locales/en.json';
import { GetStepsConfig } from './StepsConfig';
import { CreateTaskAPISteps } from '../../types/task-apis.enum';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';

const DetailsSection = () => {
	const {
		step,
		setStep,
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		parsedData,
		setParsedData,
		completedSteps,
		updateCompletedSteps,
		isLoadingApiDetails,
	} = useConnectAPI();

	const { taskApiIntegration } = en;

	const stepsConfig = GetStepsConfig({
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		parsedData,
		setParsedData,
	});

	const DETAILS_STEPS = [
		CreateTaskAPISteps.Basic,
		CreateTaskAPISteps.Curl,
		CreateTaskAPISteps.Payload,
	];

	const handleStepHop = (clickedStep: CreateTaskAPISteps) => {
		if (!completedSteps?.includes(clickedStep)) return;

		const isInDetailsSection = DETAILS_STEPS.includes(clickedStep);
		const currentStepInDetails = DETAILS_STEPS.includes(step);

		// If we're moving out of details section, keep last details step open
		if (!isInDetailsSection && currentStepInDetails) {
			const lastDetailsStep = DETAILS_STEPS[DETAILS_STEPS.length - 1];
			// updateCompletedSteps(lastDetailsStep);
		}

		// updateCompletedSteps(clickedStep);
		setStep(clickedStep);
	};

	if (isLoadingApiDetails) return <FullPageLoading />;

	return (
		<div className="space-y-5">
			{stepsConfig.map(({ key, component }) => (
				<StepCard
					key={key}
					stepKey={key}
					isActive={
						step === key ||
						// Keep last step open if we're in verify section
						(!DETAILS_STEPS.includes(step) &&
							key === DETAILS_STEPS[DETAILS_STEPS.length - 1])
					}
					title={taskApiIntegration[key.toLowerCase()]?.title}
					onStepClick={(key: CreateTaskAPISteps) => handleStepHop(key)}
					completedSteps={completedSteps}
				>
					{component}
				</StepCard>
			))}
		</div>
	);
};

const MemoizedDetailsSection = memo(DetailsSection);
export default MemoizedDetailsSection;
