import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import Spinner from '@/components/element/loading/Spinner';
import { cn } from '@/lib/utils';

const IntroducingChallengerSeries = ({ step, setStep, setOpen }) => {
	return (
		<>
			<div
				className="w-[680px] h-[480px] rounded-l-lg flex items-center justify-center p-8"
				style={{
					background:
						'linear-gradient(147.15deg, rgb(65, 10, 182) 30.17%, rgb(212, 92, 255) 100.01%)',
				}}
			>
				<img
					src="https://static.highongrowth.xyz/enterprise/64638464f5e380ef566bca30/a4a2839681184840b64f864e04af28df.png"
					className=" rounded-lg"
				/>
			</div>
			<DialogHeader className="p-4 opacity-0 [--slidein-delay:200ms] animate-slide-left pb-8 pt-0  h-[480px] w-[900px] flex justify-between py-10 flex-col">
				<div>
					<div>
						<div className="mb-4">
							<DialogTitle className={cn(`text-2xl mb-1 `)}>
								Introducing Challenger Series
							</DialogTitle>
							<div className="text-sm">
								One Click on-chain tasks for genuine product
								engagement
							</div>
						</div>

						<div
							className={cn(
								`opacity-0 [--slidein-delay:300ms]`,
								'animate-slide-left',
							)}
						>
							<div className="mt-6 font-medium">How it works</div>
							<div className="mt-2 text-sm ">
								{howItWorks.map((i) => (
									<div
										className="px-3 py-2 bg-slate-100 border rounded-md mb-2 flex"
										key={i.title}
									>
										<div>
											<div className="text-sm ">{i.title}</div>
										</div>
									</div>
								))}
							</div>

							<div className="mt-6 mb-4 italic  text-sm">
								We have achieved
								<span className="font-medium mx-1 px-1 bg-yellow-100 rounded">
									CAC of $0.08/user
								</span>
								across Speedrun events conducted till date
							</div>
						</div>
					</div>
				</div>

				{step !== 'speedrun.complete' && (
					<div className="flex">
						{step === 'speedrun.enrollment' && (
							<Button
								variant="outline"
								className="me-2"
								onClick={() => setStep('speedrun.introduction')}
							>
								Back
							</Button>
						)}
						<Button className="w-full">
							<span>Enroll in SpeedRun</span>
						</Button>
					</div>
				)}
			</DialogHeader>
		</>
	);
};

export default IntroducingChallengerSeries;

const howItWorks = [
	{
		title: '🔘 User starts the speedrun to activate their participation',
	},
	{
		title: '⌛️ They finish curated quests within 1 hour',
	},
	{
		title: '🎁 User gets rewarded instantly with quest incentives',
	},
];
