import { cn } from '@/lib/utils';
import { ITaskInputsProps } from './TaskInputs';
import { useEffect, useState } from 'react';
import { axios } from '@/lib/axios';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import {
	IdentityNamespaceTag,
	TaskKeys,
	TemplateType,
} from '@/features/campaigns/types';
import { Button } from '@/components/ui/button';
import Spinner from '@/components/element/loading/Spinner';

// [TODO] Check the whole logic properly. @nischal
const TaskInputSelectAsync = ({
	adminInput,
	taskId,
	error,
	editTaskAdminInput,
	templateType,
	deleteTask,
	isUpdate,
	src,
}: ITaskInputsProps) => {
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoading2, setIsLoading2] = useState(false);
	const isAPI = templateType === TemplateType.ApiBasedVerification;
	const isDAVE = templateType === TemplateType.NoCodeQueryVerification;

	useEffect(() => {
		fetchOptions();
	}, [adminInput.optionsFrom]);

	const fetchOptions = () => {
		setIsLoading2(true);
		axios.get(adminInput.optionsFrom).then(({ data }) => {
			setIsLoading(false);
			setIsLoading2(false);
			if (data.length === 0) {
				editTaskAdminInput(taskId, 'disabled', true);
			}
			if (isAPI) {
				const apiTask =
					data?.length &&
					data.find((item) => {
						return item.value === adminInput.value;
					});

				if (apiTask) {
					editTaskAdminInput(
						taskId,
						apiTask.isRecurring ? 'recurrance' : 'metricBasedXp',
						apiTask.isRecurring
							? apiTask.isRecurring
							: apiTask.isMetricBased,
					);
				}
			}

			const uniqueNamespaceTags = [
				...new Set(data.map((option) => option.namespaceTag)),
			];

			if (adminInput.key === TaskKeys.NameSpaceTag) {
				editTaskAdminInput(
					taskId,
					adminInput.key,
					IdentityNamespaceTag.EvmEvm,
				);
			}

			setOptions(
				(adminInput.key === TaskKeys.NameSpaceTag
					? uniqueNamespaceTags
					: adminInput.key === TaskKeys.Chain
						? data.filter(
								(chain) =>
									chain.namespaceTag ===
									IdentityNamespaceTag.EvmEvm,
							)
						: data
				).map((item) => {
					const option =
						adminInput.key === TaskKeys.NameSpaceTag
							? data.find((d) => d.namespaceTag === item)
							: item;

					return {
						label: (
							<div>
								{adminInput.key === TaskKeys.NameSpaceTag
									? option.namespaceTag
									: adminInput.key === TaskKeys.Chain
										? option.chainName
										: option.label}
								<div className="text-xs text-muted-foreground mt-1">
									{option.description}
								</div>
							</div>
						),
						value:
							adminInput.key === TaskKeys.NameSpaceTag
								? option.namespaceTag
								: adminInput.key === TaskKeys.Chain
									? option.chain
									: option.value,
						name:
							adminInput.key === TaskKeys.NameSpaceTag
								? option.namespaceTag
								: adminInput.key === TaskKeys.Chain
									? option.chainName
									: option.label,
						...(isAPI && {
							isRecurring: option.isRecurring,
							isMetricBased: option.isMetricBased,
						}),
					};
				}),
			);
		});
	};

	if (isLoading) {
		return (
			<div className="flex justify-center items-center py-10">
				<Spinner />
			</div>
		);
	}

	if ((isAPI || isDAVE) && !isLoading && options?.length === 0) {
		const title = isAPI
			? 'No API connected yet'
			: 'Create your first DAVE query';
		const description = isAPI
			? `You can connect your own APIs for custom verification enabling all sort of tasks.`
			: `DAVE is a powerful tool that allows you to query blockchain actions without writing any code.`;
		const link = isAPI ? '/app/tools/task-apis' : '/app/tools/dave/actions';
		return (
			<div
				className={`flex items-center justify-center border rounded-lg bg-slate-100 py-12 flex-col mt-2 relative ${error ? ' border-destructive' : ''}`}
			>
				{deleteTask && (
					<div className="absolute top-0 end-0 p-2">
						<div className="flex space-x-4">
							<div
								className="text-xs bg-slate-100 rounded-full px-2 py-2 font-medium hover:bg-slate-300 transition-all duration-200 cursor-pointer "
								onClick={() => {
									deleteTask(taskId);
								}}
							>
								<i className="bi-trash"></i>
							</div>
						</div>
					</div>
				)}
				<div className="text- font-medium">{title}</div>
				<div className="text-xs text-muted-foreground mt-1 max-w-[400px] text-center">
					{description}
				</div>
				<div className="mt-5 flex flex-row">
					<Button
						size="sm"
						onClick={() => {
							window.open(`${link}`, '_blank');
						}}
					>
						<i className="bi bi-plus-circle-fill me-2"></i>
						{isAPI ? 'Connect API' : 'Create DAVE query'}
					</Button>
					<Button
						variant="link"
						className="text-xs font-normal"
						onClick={() => fetchOptions()}
					>
						{isLoading2 ? 'Loading...' : 'Refetch Data'}
					</Button>
				</div>
			</div>
		);
	}

	return (
		<>
			<div className="relative">
				<div className="text-sm font-medium mb-1">{adminInput.label}</div>
				{adminInput.description && (
					<div className="text-xs text-muted-foreground mb-1">
						{adminInput.description}
					</div>
				)}
				{/* TODO: change this once wallet infra changes are integrated */}
				<Select
					value={adminInput.value as string}
					onValueChange={(e) => {
						editTaskAdminInput(taskId, adminInput.key, e);
						if (isAPI) {
							const option = options.find((i) => i.value === e);

							editTaskAdminInput(
								taskId,
								option.isRecurring ? 'recurrance' : 'metricBasedXp',
								option.isRecurring
									? option.isRecurring
									: option.isMetricBased,
							);
						}
					}}
					disabled={
						!options ||
						options.length === 0 ||
						adminInput.key === TaskKeys.NameSpaceTag
					}
				>
					<SelectTrigger
						aria-label="Area"
						className={cn('text-sm', error && 'border-destructive')}
					>
						<div className="flex justify-between">
							{adminInput.value ? (
								<SelectValue placeholder={adminInput.placeholder}>
									{
										options.find(
											(i) => i.value === adminInput.value,
										)?.name
									}
								</SelectValue>
							) : (
								<SelectValue placeholder={adminInput.placeholder}>
									{adminInput.placeholder}
								</SelectValue>
							)}
						</div>
					</SelectTrigger>
					<SelectContent>
						{options.map((i) => (
							<SelectItem key={i.value} value={i.value}>
								{i.label}
								{i.description}
							</SelectItem>
						))}
					</SelectContent>
				</Select>

				{error && (
					<div className="text-xs text-destructive mt-1">
						This field is required
					</div>
				)}
			</div>
		</>
	);
};

export default TaskInputSelectAsync;
