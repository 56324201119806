import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useCreateCampaign } from '@/features/campaigns/hooks/create/useCreateCampaign';
import { useMemo } from 'react';

const EditTaskLayout = ({ children }) => {
	const { isReordering, setIsReordering, tasks } = useCreateCampaign();
	const xp = useMemo(() => {
		return tasks?.reduce((acc, task) => {
			return acc + task.task?.xp;
		}, 0);
	}, [tasks]);
	return (
		<div className="relative">
			<div className="flex flex-col items-center justify-center pt-5 relative">
				<div className="absolute top-0 end-0 h-full p-3">
					{/* {xp > 0 && <Badge className="me-2 h-8">{xp} XP</Badge>}
					<Button
						variant={isReordering ? 'default' : 'outline'}
						size="sm"
						onClick={() => setIsReordering((prev) => !prev)}
					>
						<i className="bi bi-sort-down me-1"></i>
						{!isReordering ? 'Reorder Tasks' : 'Save Ordering'}
					</Button> */}
				</div>
				<div className="absolute top-0 h-full">
					<Separator
						className=" h-full  -z-10 bg-slate-300"
						orientation="vertical"
					/>
				</div>
				<div className="relative flex flex-col items-center justify-center z-30">
					{children}
				</div>
			</div>
		</div>
	);
};

export default EditTaskLayout;
