import { defineChain } from 'viem';

export const apeChain = defineChain({
	id: 33139,
	name: 'ApeChain',
	nativeCurrency: { decimals: 18, name: 'APE', symbol: 'APE' },
	rpcUrls: {
		default: {
			http: ['https://apechain.calderachain.xyz/http'],
		},
	},
	blockExplorers: {
		default: {
			name: 'ApeChain Explorer',
			url: 'https://apechain.calderaexplorer.xyz/',
		},
	},
	testnet: false,
});
