import { defineChain } from 'viem';

export const taikoMainnet = defineChain({
	id: 167000,
	name: 'Taiko Mainnet',
	nativeCurrency: { decimals: 18, name: 'ETH', symbol: 'ETH' },
	rpcUrls: {
		default: {
			http: ['https://rpc.mainnet.taiko.xyz'],
		},
	},
	blockExplorers: {
		default: {
			name: 'Taiko Mainnet Explorer',
			url: 'https://taikoscan.io',
		},
	},
	testnet: false,
});
