import { toast } from 'sonner';
import { createContext, useEffect, useState } from 'react';
import { createNftContract, uploadImage } from '../services/nfts.service';
import { handleErrorMessage } from '@/utils/notifications';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/lib/react-query';
import { useCreateNftTxn } from '../hooks/useCreateNftTxn';
import { ContractType, useGetContract } from '@/hooks/useGetContract';
import { useNavigate } from 'react-router-dom';
import { IdentityNamespaceTag } from '@/features/campaigns/types';

export interface INFTs {
	_id?: string;
	tokenId?: string;
	name: string;
	nftSymbol: string;
	chainId: string;
	chain: string;
	namespaceTag: IdentityNamespaceTag;
	imageUrl: string;
	image: File | any;
	imgPosition: { x: number; y: number };
}
const defaultValue = {
	name: '',
	nftSymbol: '',
	chainId: '8453',
	chain: 'base',
	namespaceTag: IdentityNamespaceTag.EvmEvm,
	imageUrl: '',
	image: null,
	resetForm: () => {},
	imgPosition: { x: 0, y: 0 },
};

export const useNFTContract = ({ open, setOpen }) => {
	const [formFields, setFormFields] = useState<INFTs>(defaultValue);
	const [formErrors, setFormErrors] = useState<any>({});
	const [step, setStep] = useState<'form' | 'transaction' | 'completed'>('form');
	const [loading, setLoading] = useState(false);

	const { contract } = useGetContract(
		ContractType.IntractCampaignRewardNft,
		formFields?.chain,
		formFields?.namespaceTag,
	);

	const { startTxn, txnLoading } = useCreateNftTxn({
		open,
	});

	const resetForm = () => {
		setFormFields(defaultValue);
		setFormErrors({});
	};

	const createMutation = useMutation({
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['nfts'] });
		},
		mutationFn: createNftContract,
	});

	const uploadFileHelper = async (file: File) => {
		return new Promise((resolve) => {
			toast.promise(
				Promise.all([
					uploadImage(file).then((res) => {
						resolve(res);
					}),
				]),
				{
					loading: 'Uploading image...',
					success: 'NFT Image uploaded successfully',
					error: 'Failed to upload',
				},
			);
		});
	};

	const formatRequestData = async () => {
		try {
			const data: any = {
				...formFields,
			};
			if (data?.image && data.imageUrl?.startsWith('blob:')) {
				const uploadedImage = await uploadFileHelper(
					data?.image[0] || data?.image,
				);
				data.imageUrl = uploadedImage;
				data.imagePosition = formFields?.imgPosition;
				setFormFields((p) => ({
					...p,
					imageUrl: uploadedImage as string,
				}));
			}
			return data;
		} catch (err) {
			handleErrorMessage(err);
		}
	};
	const handleCreateNFT = async () => {
		try {
			const data = await formatRequestData();
			if (!data) return;
			const dbRewardNft = await createMutation.mutateAsync(data);
			setStep('transaction');
			await startTransaction(dbRewardNft?._id);
		} catch (error) {
			handleErrorMessage(error);
		} finally {
			setLoading(false);
		}
	};

	const startTransaction = async (contract_id: string) => {
		if (!contract_id) return;
		try {
			await startTxn(
				contract?.address,
				Number(formFields?.chainId),
				contract_id,
			);
			setStep('completed');
			setOpen(false);
		} catch (err) {
			console.log(err);
		}
	};

	const verifyFormFields = () => {
		const error: any = {};
		if (!formFields?.name || !formFields?.name.trim()) {
			error.name = `NFT name is required`;
		}
		if (!formFields?.nftSymbol || !formFields?.nftSymbol.trim()) {
			error.nftSymbol = `NFT symbol is required`;
		}
		if (!formFields?.chainId) {
			error.chainId = `Please select a chain`;
		}
		let isImageValid = formFields?.image;
		if (!formFields?.image || formFields?.image?.length === 0) {
			error.image = 'Please upload the image';
		}
		if (isImageValid) {
			const image = formFields?.image;
			const isImageSizeValid = image.size < 2 * Math.pow(10, 6);
			if (!isImageSizeValid) {
				error.image = 'Size should be less than 2 MB';
				isImageValid = false;
			}
		}

		setFormErrors(error);
		return Object.keys(error).length === 0;
	};

	const handleSubmit = () => {
		try {
			if (!verifyFormFields()) return;
			setLoading(true);
			handleCreateNFT();
		} catch (err) {
			handleErrorMessage(err);
			console.log(err);
		}
	};

	useEffect(() => {
		setFormErrors((prev: any) => ({
			...prev,
			name: '',
			nftSymbol: '',
			image: '',
		}));
	}, [formFields.name, formFields.nftSymbol, formFields?.image, setFormErrors]);

	return {
		open,
		setOpen,
		formFields,
		setFormFields,
		formErrors,
		setFormErrors,
		handleCreateNFT,
		resetForm,
		step,
		setStep,
		startTransaction,
		isTxnLoading: txnLoading,
		loading,
		setLoading,
		handleSubmit,
	};
};
