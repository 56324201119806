import { getUser } from '@/features/auth/services/auth.service';
import analytics from '@/lib/analytics';
import { queryClient } from '@/lib/react-query';
import { UserType } from '@/providers/AuthProvider';
import { TrackingEvents } from '@/types/tracking.type';
import { handleErrorMessage } from '@/utils/notifications';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import {
	checkIfTokenRewardTxnAlreadyDone,
	enrollCampaignInSpeedRunPreActive,
	getCampaign,
	updateCampaignStatus,
} from '../../services/campaigns.service';
import { CampaignRewardCategory, ICampaign, Status } from '../../types';
import { useCampaignReport } from '../report/useCampaignReport';
import { useDepositToken } from './useDepositToken';
import { SPEEDRUN_MIN_TOKEN_AMOUNT } from '@/config';

export enum CampaignLaunchStep {
	Confirmation = 'confirmation',
	Token = 'token',
	SpeedRun = 'speedrun',
	TokenWithSpeedrun = 'token-with-speedrun',
	TransactionStatus = 'transaction-status',
}

export const useCampaignLaunch = ({
	campaignId,
	startImmediately,
}: {
	campaignId: string;
	startImmediately: boolean;
	setOpen: (value: boolean) => void;
}) => {
	const navigate = useNavigate();
	const { botFilteringMethods } = useCampaignReport();

	const { data: campaign, isLoading: isCampaignLoading } = useQuery<ICampaign>({
		queryKey: ['campaign', campaignId],
		queryFn: () => getCampaign(campaignId),
		enabled: !!campaignId,
	});
	const tokenReward = campaign?.reward?.find(
		(reward) => reward.category === CampaignRewardCategory.Token,
	);
	const [step, setStep] = useState<CampaignLaunchStep>(
		CampaignLaunchStep.Confirmation,
	);
	const [isLoading, setIsLoading] = useState(false);

	const [speedRunConfig, setSpeedRunConfig] = useState<{
		isEnrolled: boolean;
		speedRunAmount: number;
		questRewardPool: number;
		totalRewardPool?: number;
	}>({
		isEnrolled: false,
		speedRunAmount: +campaign?.speedRunConfig?.tokenAmount || 0,
		questRewardPool:
			tokenReward?.numRewards * +tokenReward?.tokenReward?.tokenAmountPerUser,
		totalRewardPool: tokenReward?.tokenReward?.originalPool
			? +tokenReward?.tokenReward?.originalPool
			: tokenReward?.numRewards *
				+tokenReward?.tokenReward?.tokenAmountPerUser,
	});

	const {
		startProcess,
		status,
		isLoading: isDeposting,
		userBalance,
		steps,
	} = useDepositToken({
		campaignId: campaignId,
		tokenReward,
		speedRunConfig: campaign.speedRunConfig,
	});

	const [isTxnRequired, setIsTxnRequired] = useState(tokenReward ? true : false);

	const {
		data,
		error,
		isLoading: isCheckingIfLogExists,
	} = useQuery({
		queryKey: ['campaign', 'reward-txn', campaignId],
		queryFn: () => checkIfTokenRewardTxnAlreadyDone(campaignId),
		enabled:
			!!campaignId &&
			!!tokenReward &&
			!isCampaignLoading &&
			!campaign?.rewardTransaction?.txHash,
	});

	useEffect(() => {
		if (data?.logFound) {
			toast.error('Token deposit txn already done');
			setIsTxnRequired(false);
		}
	}, [data, error]);

	useEffect(() => {
		if (!campaign) return;
		if (tokenReward) {
			if (campaign.rewardTransaction?.txHash) {
				setIsTxnRequired(false);
			} else {
				setIsTxnRequired(true);
			}
		}
		if (
			campaign?.speedRunConfig?.isEnrolled &&
			campaign?.speedRunConfig?.transaction?.txHash
		) {
			setStep(CampaignLaunchStep.TransactionStatus);
		}
	}, [campaign, tokenReward]);

	const launchCampaign = async () => {
		try {
			setIsLoading(true);

			await updateCampaignStatus(campaignId, {
				newStatus: Status.Active,
				includeCaptcha: botFilteringMethods?.includeCaptcha,
				includePOH: botFilteringMethods?.includePOH,
			});

			analytics.track(TrackingEvents.LaunchQuestCompleted, {
				campaignId,
				campaignStatus: startImmediately ? Status.Active : Status.Scheduled,
			});

			toast.success('Campaign has been launched successfully');

			await queryClient.invalidateQueries({
				queryKey: ['campaign', campaignId],
			});

			// Update the campaigns table data if it exists in the cache
			const existingTableData = queryClient.getQueryData<any[]>(['campaigns']);
			if (existingTableData) {
				queryClient.setQueryData(
					['campaigns'],
					existingTableData.map((item) =>
						item._id === campaignId
							? {
									...item,
									status: startImmediately
										? Status.Active
										: Status.Scheduled,
								}
							: item,
					),
				);
			}

			// Fetch updated user and check if triggerFlags is set
			const updatedUser: UserType = await getUser();
			queryClient.setQueryData(['user'], updatedUser);
			const triggerFlags = updatedUser?.triggerFlags;
			const shouldOpen =
				triggerFlags?.credits &&
				Object.values(triggerFlags.credits).some(Boolean);

			// Define navigation URL based on the credits flag
			if (!shouldOpen) {
				navigate(`/app/campaign/quests/${campaignId}?share-celebrate=true`);
			}

			await queryClient.invalidateQueries({ queryKey: ['enterprise'] });

			setIsLoading(false);
			// setOpen?.(false);
		} catch (err) {
			console.error(err);
			handleErrorMessage(err);
			setIsLoading(false);
		}
	};

	const showSpeedrunConfig = useMemo(() => {
		if (!tokenReward) return false;
		if (!tokenReward?.tokenReward?.isWhitelistedToken) return false;
		const tokenAmount =
			+tokenReward?.tokenReward?.tokenAmountPerUser * tokenReward?.numRewards;
		if (tokenAmount > SPEEDRUN_MIN_TOKEN_AMOUNT) return true;
		else return false;
	}, [tokenReward]);

	const updateSpeedRunConfig = async () => {
		setIsLoading(true);
		try {
			if (speedRunConfig?.isEnrolled) {
				const rewardId = tokenReward?._id;
				await enrollCampaignInSpeedRunPreActive(campaignId, {
					rewardId,
					speedRunAmount: speedRunConfig.speedRunAmount,
					questRewardPool: speedRunConfig.questRewardPool,
				});
				await queryClient.invalidateQueries({
					queryKey: ['campaign', campaignId],
				});
			}
			setIsLoading(false);
			setStep(CampaignLaunchStep.TransactionStatus);
		} catch (err) {
			await queryClient.invalidateQueries({
				queryKey: ['campaign', campaignId],
			});
			setIsLoading(false);
			console.log(err);
		}
	};

	const initiateTransactions = async () => {
		try {
			const res = await startProcess();
			if (res) {
				await launchCampaign();
			}
			setIsLoading(false);
			return;
		} catch (err) {
			setIsLoading(false);
			console.log(err);
		}
	};

	const handleNext = async (boolean: boolean) => {
		if (step === CampaignLaunchStep.Confirmation) {
			analytics.track(TrackingEvents.LaunchQuestConfirmed, {
				campaignId,
				isTokenReward: !!tokenReward,
				isTxnRequired: isTxnRequired,
			});
		}
		if (step === CampaignLaunchStep.SpeedRun) {
			if (boolean) {
				setSpeedRunConfig((p) => ({ ...p, isEnrolled: true }));
				setStep(CampaignLaunchStep.TokenWithSpeedrun);
			} else {
				setStep(CampaignLaunchStep.Token);
			}
		}
		if (
			step === CampaignLaunchStep.Confirmation &&
			tokenReward &&
			isTxnRequired
		) {
			if (showSpeedrunConfig) {
				setStep(CampaignLaunchStep.SpeedRun);
			} else {
				setStep(CampaignLaunchStep.Token);
			}
			return;
		} else if (step === CampaignLaunchStep.Confirmation) {
			await launchCampaign();
			return;
		} else if (
			step === CampaignLaunchStep.Token ||
			step === CampaignLaunchStep.TokenWithSpeedrun
		) {
			await updateSpeedRunConfig();
		} else if (step === CampaignLaunchStep.TransactionStatus) {
			await initiateTransactions();
			return;
		}
	};

	const btnLabel = useMemo(() => {
		if (step === CampaignLaunchStep.Confirmation) {
			if (tokenReward && isTxnRequired) return 'Continue to Deposit Tokens';
			return 'Launch Campaign';
		}
		if (step === CampaignLaunchStep.SpeedRun) {
			return 'Allocate % Tokens to Speedrun';
		}
		if (step === CampaignLaunchStep.TokenWithSpeedrun) {
			return 'Continue to Deposit Tokens';
		}
		if (step === CampaignLaunchStep.Token) {
			return 'Continue to Deposit Tokens';
		}
		if (step === CampaignLaunchStep.TransactionStatus) {
			return status;
		}
	}, [step, tokenReward, status, isTxnRequired]);

	const handleBack = () => {
		if (step === CampaignLaunchStep.TransactionStatus) {
			if (showSpeedrunConfig) {
				setStep(CampaignLaunchStep.TokenWithSpeedrun);
			} else {
				setStep(CampaignLaunchStep.Token);
			}
		}
	};

	return {
		step,
		setStep,
		handleNext,
		isLoading: isLoading || isDeposting,
		btnLabel,
		isCampaignLoading,
		speedRunConfig,
		setSpeedRunConfig,
		userBalance,
		steps,
		handleBack,
	};
};
