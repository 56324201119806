import { homepageContent, testimonials } from '../../content/homepage.content';
import SectionHeader from '../ui/SectionHeader';
import { cn } from '@/lib/utils';
import DOMPurify from 'dompurify';

const Testimonials = () => {
	return (
		<div className="mx-auto px-2.5 lg:px-20 relative pt-10 pb-28 lg:pb-0 my-20 overflow-hidden">
			<SectionHeader
				title={homepageContent.testimonials.title}
				description={homepageContent.testimonials.description}
				titleGradient={homepageContent.testimonials.titleGradient}
			/>

			<ul className="flex items-end space-y-6 space-x-16 py-8 animate-marquee">
				{testimonials.map((testimonial, index) => (
					<TestimonialCard key={index} {...testimonial} />
				))}
			</ul>
		</div>
	);
};

export default Testimonials;

const TestimonialCard = ({
	companyLogo,
	testimonial,
	name,
	profile,
	backgroundVariant,
	isDark,
}) => {
	const variantClassNames = {
		default: '',
		'gradient-purple': 'bg-gradient-to-tr from-purple-500 to-indigo-500',
		'gradient-blue': 'bg-gradient-to-tr from-blue-500 to-teal-500',
		'gradient-pink': 'bg-gradient-to-tr from-pink-500 to-rose-500',
		'gradient-green': 'bg-gradient-to-tr from-green-500 to-emerald-500',
		'gradient-yellow': 'bg-gradient-to-tr from-yellow-500 to-amber-500',
		'gradient-red': 'bg-gradient-to-tr from-red-500 to-pink-500',
		'gradient-black': 'bg-gradient-to-tr from-black to-gray-800',
		white: 'bg-white/20',
		// Add more variants as needed
	};
	const variantClassName = variantClassNames[backgroundVariant] || ''; // Default to empty string if variant not found

	const safeHTML = DOMPurify.sanitize(testimonial);

	return (
		<li
			className={`min-w-[24.5rem] min-h-[20rem] max-h-[21rem] group relative list-none break-inside-avoid rounded-3xl border border-gray-300 ${variantClassName} backdrop-blur-lg transition-all hover:scale-105`}
		>
			<img
				src="https://assets.dub.co/testimonials/card-dotted-grid-dark.png"
				alt="Dotted grid background"
				className="pointer-events-none absolute right-0 top-0"
			/>
			<div className="flex h-full flex-col justify-between p-8">
				<div
					className={cn(
						'flex-grow max-h-[12rem] overflow-y-auto',
						isDark ? 'text-gray-800' : 'text-gray-200',
					)}
					dangerouslySetInnerHTML={{ __html: safeHTML }}
				></div>
				<div className="absolute bottom-8 flex items-center justify-between mt-[10%]">
					<div className="flex flex-col space-y-0.5">
						<div
							className={`font-semibold ${isDark ? 'text-gray-800' : 'text-gray-200'}`}
						>
							{name}
						</div>
						<div
							className={`text-sm ${isDark ? 'text-gray-500' : 'text-white'}`}
						>
							{profile}
						</div>
					</div>
				</div>
			</div>
		</li>
	);
};
