import { IdentityNamespaceTag } from '@/features/campaigns/types';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';

const ChainBadge = ({
	chainId,
	chain,
	namespaceTag,
	className,
	hideName = false,
	showAdditionalChains = false,
	fetchEnterpriseChains = false,
	showNonEVM = false,
}: {
	chainId: string;
	chain?: string;
	namespaceTag?: IdentityNamespaceTag;
	className?: string;
	hideName?: boolean;
	showAdditionalChains?: boolean;
	fetchEnterpriseChains?: boolean;
	showNonEVM?: boolean;
}) => {
	const { getChainName, getNativeChainLogo } = useGetSupportedChains({
		showAdditionalChains,
		fetchEnterpriseChains,
		showNonEVM,
	});

	const chainLogo = getNativeChainLogo(chainId || chain);
	const chainName = getChainName(chainId || chain);

	if (!chainLogo || !chainName) return null;

	return (
		<div className={`py-1 flex items-center ${className} font-normal`}>
			<img
				src={chainLogo}
				className="size-5 rounded-full object-contain me-2 border border-black/5"
			/>

			{!hideName && chainName}
		</div>
	);
};

export default ChainBadge;
