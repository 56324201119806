import { useCampaigns } from '@/features/campaigns/hooks/report/useCampaigns';
import { IdentityNamespaceTag } from '@/features/campaigns/types';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createContext, useEffect, useMemo, useState } from 'react';
import {
	createRewardSection,
	fetchRewards,
	updateRewardSectionsOrdering,
	updateSection,
} from '../services/reward-store.service';
import {
	EligibilityType,
	RewardSectionTabs,
	RewardStatus,
	RewardType,
} from '../types/reward-store.enums';
import { IReward, IRewardStore } from '../types/reward-store.type';

interface RewardStoreProviderState {
	rewardStore: IRewardStore[];
	isLoading: boolean;
	id: string;
	open: boolean;
	setOpen: (open: boolean) => void;
	isCreating: boolean;
	createSection: (section: { name: string; description: string }) => void;
	selectedTab: string;
	setSelectedTab: (tab: RewardSectionTabs) => void;
	formFields: IReward;
	setFormFields: (fields: IReward) => void;
	formErrors: any;
	setFormErrors: (errors: any) => void;
	quests: { label: string; value: string }[];
	resetForm: () => void;
	deleteSection: (sectionId: string) => void;
	editSection: (data: {
		_id: string;
		name?: string;
		description?: string;
	}) => void;
	setSections: (sections: IRewardStore[]) => void;
	moveRewardSection: (sectionId: string, dir: 'up' | 'down') => void;
	isMoving: {
		up: boolean;
		down: boolean;
	};
	moveReward: (sectionId: string, rewardId: string, dir: 'left' | 'right') => void;
	section: {
		name: string;
		description: string;
	};
	setSection: (section: { name: string; description: string }) => void;
	resetRewardForm: ({ sectionId }: { sectionId?: string }) => void;
}

const initialFormFields: IReward = {
	_id: '',
	name: '',
	description: '',
	image: '',
	imageFile: null,
	imagePosition: {
		x: 0,
		y: 0,
	},
	enterpriseId: '',
	sectionId: '',
	status: RewardStatus.Active,
	rewardType: RewardType.Nft,
	totalClaims: 0,
	eligibilityParams: [
		{
			eligibilityType: EligibilityType.NumberOfQuestsCompleted,
			minCount: 0,
		},
	],
	isDeleted: false,
	rewardDetails: {
		discordRoleDetails: {
			discordRoleId: '',
			discordGuildId: '',
		},
		nftDetails: {
			nftDocId: '',
			nftContractAddress: '',
			nftChain: 'base',
			nftTokenId: '',
			namespaceTag: IdentityNamespaceTag.EvmEvm,
		},
		customDetails: {},
	},
	editMode: false,
};

const defaultValue = {
	rewardStore: [],
	isLoading: false,
	id: '',
	open: false,
	setOpen: () => {},
	createSection: () => {},
	isCreating: false,
	selectedTab: RewardSectionTabs.Store,
	setSelectedTab: () => {},
	formFields: initialFormFields,
	setFormFields: () => {},
	formErrors: {},
	setFormErrors: () => {},
	quests: [],
	resetForm: () => {},
	deleteSection: () => {},
	editSection: () => {},
	setSections: () => {},
	moveRewardSection: () => {},
	isMoving: {
		up: false,
		down: false,
	},
	moveReward: () => {},
	section: {
		name: '',
		description: '',
	},
	setSection: () => {},
	resetRewardForm: () => {},
};

export const RewardStoreContext =
	createContext<RewardStoreProviderState>(defaultValue);

export function RewardStoreProvider({ children }) {
	const [id, setId] = useState('');
	const [open, setOpen] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [selectedTab, setSelectedTab] = useState(RewardSectionTabs.Store);
	const [formFields, setFormFields] = useState<IReward>(initialFormFields);
	const [formErrors, setFormErrors] = useState({});
	const [quests, setQuests] = useState([]);
	const [sections, setSections] = useState<IRewardStore[]>([]);
	const [isMoving, setIsMoving] = useState({
		up: false,
		down: false,
	});
	const [section, setSection] = useState({
		name: '',
		description: '',
	});

	const { campaigns } = useCampaigns();

	const formattedData = useMemo(() => {
		return campaigns.map((item) => ({
			label: item.name,
			value: item._id,
		}));
	}, [campaigns]);

	const { data, isLoading } = useQuery<IRewardStore[]>({
		queryKey: ['rewardStore'],
		queryFn: () => fetchRewards(),
	});

	const updateMutation = useMutation({
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['rewardStore'] });
		},
		mutationFn: updateSection,
	});

	const reorderMutation = useMutation({
		onSuccess: async () => {
			queryClient.invalidateQueries({ queryKey: ['rewardStore'] });
		},
		mutationFn: updateRewardSectionsOrdering,
	});

	const resetForm = () => {
		setFormFields(initialFormFields);
		setFormErrors({});
	};

	const createSection = async ({
		name,
		description,
	}: {
		name?: string;
		description?: string;
	}) => {
		try {
			setIsCreating(true);
			const resp = await createRewardSection({
				name: name,
				description: description,
			});

			queryClient.setQueryData<IRewardStore[]>(
				['rewardStore'],
				(oldData = []) => {
					return [...oldData, { ...resp, rewards: [] }];
				},
			);

			queryClient.invalidateQueries({
				queryKey: ['rewardStore'],
			});
		} catch (error) {
			console.log(error);
			handleErrorMessage(error);
		} finally {
			setIsCreating(false);
		}
	};

	const deleteSection = async (sectionId: string) => {
		try {
			await updateMutation.mutateAsync({
				_id: sectionId,
				isDeleted: true,
			});
		} catch (error) {
			handleErrorMessage(error);
		}
	};

	const editSection = async (data: {
		_id: string;
		name?: string;
		description?: string;
	}) => {
		try {
			await updateMutation.mutateAsync(data);
		} catch (error) {
			handleErrorMessage(error);
		}
	};

	const moveRewardSection = async (sectionId: string, dir: 'up' | 'down') => {
		const sectionIndex = sections.findIndex(
			(section) => section._id === sectionId,
		);
		const totalSections = sections.length;

		const newArrangedSections = [...sections];
		if (sectionIndex === 0 && dir === 'up') return;
		if (sectionIndex === totalSections - 1 && dir === 'down') return;

		if (dir === 'up') {
			const temp = newArrangedSections[sectionIndex];
			newArrangedSections[sectionIndex] =
				newArrangedSections[sectionIndex - 1];
			newArrangedSections[sectionIndex - 1] = temp;
		} else {
			const temp = newArrangedSections[sectionIndex];
			newArrangedSections[sectionIndex] =
				newArrangedSections[sectionIndex + 1];
			newArrangedSections[sectionIndex + 1] = temp;
		}

		const formatArrangedSections = newArrangedSections.map((section, index) => ({
			sectionId: section._id,
			index: index,
			rewardIds: section.rewardIds,
		}));
		try {
			setIsMoving((p) => ({ ...p, [dir]: true }));
			reorderMutation.mutateAsync(formatArrangedSections);
		} catch (error) {
			setIsMoving((p) => ({ ...p, [dir]: false }));
			handleErrorMessage(error);
		} finally {
			setIsMoving((p) => ({ ...p, [dir]: false }));
		}
	};

	const moveReward = async (
		sectionId: string,
		rewardId: string,
		dir: 'left' | 'right',
	) => {
		const section = sections.find((section) => section._id === sectionId);
		if (!section) return;

		const rewardIndex = section.rewardIds.findIndex((id) => id === rewardId);
		if (rewardIndex === -1) return;

		const totalRewards = section.rewardIds.length;
		const newRewardIds = [...section.rewardIds];

		if (
			(rewardIndex === 0 && dir === 'left') ||
			(rewardIndex === totalRewards - 1 && dir === 'right')
		)
			return;

		if (dir === 'left') {
			const temp = newRewardIds[rewardIndex];
			newRewardIds[rewardIndex] = newRewardIds[rewardIndex - 1];
			newRewardIds[rewardIndex - 1] = temp;
		} else {
			const temp = newRewardIds[rewardIndex];
			newRewardIds[rewardIndex] = newRewardIds[rewardIndex + 1];
			newRewardIds[rewardIndex + 1] = temp;
		}

		const formatRewards = sections.map((section) => {
			if (section._id === sectionId) {
				if (newRewardIds) {
					return {
						sectionId: section._id,
						rewardIds: newRewardIds,
						index: sections.findIndex((s) => s._id === sectionId),
					};
				}
			}
			return {
				sectionId: section._id,
				rewardIds: section.rewardIds,
				index: sections.findIndex((s) => s._id === section._id),
			};
		});

		try {
			reorderMutation.mutateAsync(formatRewards);
		} catch (error) {
			handleErrorMessage(error);
		} finally {
			//setIsMoving((p) => ({ ...p, [dir]: false }));
		}
	};

	const resetRewardForm = ({ sectionId }: { sectionId?: string }) => {
		setFormFields({
			...initialFormFields,
			sectionId: sectionId || '',
		});
		setFormErrors({});
	};

	useEffect(() => {
		setQuests(formattedData);
	}, [formattedData]);

	useEffect(() => {
		if (data && data.length > 0) {
			setSections(data);
			return;
		}
		setSections([]);
	}, [data]);

	return (
		<RewardStoreContext.Provider
			value={{
				rewardStore: sections,
				setSections,
				isLoading,
				id,
				open,
				setOpen,
				createSection,
				isCreating,
				selectedTab,
				setSelectedTab,
				formFields,
				setFormFields,
				formErrors,
				setFormErrors,
				quests,
				resetForm,
				deleteSection,
				editSection,
				moveRewardSection,
				isMoving,
				moveReward,
				section,
				setSection,
				resetRewardForm,
			}}
		>
			{children}
		</RewardStoreContext.Provider>
	);
}
