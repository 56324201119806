import ChainBadge from '@/components/element/badges/ChainBadge';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { DataTable } from '@/components/element/data-table/DataTable';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import InputText from '@/components/element/inputs/InputText';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { Button } from '@/components/ui/button';
import ImportTokenDialog from '@/features/campaigns/components/create-campaign/rewards/components/reward-methods/token/ImportTokenDialog';
import {
	ITokens,
	useGetAllTokensForEnterprise,
} from '@/features/campaigns/hooks/tokens/useGetAllTokens';
import { useQueryInitialise } from '@/hooks/useQueryInitialise';
import { cn } from '@/lib/utils';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';

const ImportedTokens = () => {
	const [open, setOpen] = useState(false);
	const { tokens, allTokens, isLoading, search, setSearch } =
		useGetAllTokensForEnterprise();

	useQueryInitialise('new', 'true', () => {
		setOpen(true);
	});

	if (isLoading) {
		return <FullPageLoading />;
	}

	return (
		<div>
			<div className={cn('flex items-center justify-between')}>
				<div className="space-y-1">
					<h2 className="text-xl font-medium tracking-tight">
						Imported Tokens
					</h2>
					<p className="text-sm text-muted-foreground max-w-[700px]">
						Import custom token to reward your questers
					</p>
				</div>
				<div className="ml-auto mr-4">
					<Button onClick={() => setOpen(true)}>
						<i className="bi-plus-circle-fill me-2"></i>Import Token
					</Button>
				</div>
			</div>
			<div className="mt-5">
				<div className="flex items-center mt-10 mb-5 space-x-4">
					<InputText
						value={search}
						setValue={setSearch as any}
						prepend={
							<i className="bi-search px-3 text-muted-foreground text-sm"></i>
						}
						placeholder="Search Tokens"
						className="w-[250px] lg:w-[250px]"
					/>
				</div>
				<DataTable
					data={allTokens}
					columns={columns()}
					totalCount={tokens?.length || 0}
				/>
			</div>
			<ImportTokenDialog open={open} setOpen={setOpen} defaultChainId={137} />
		</div>
	);
};

export default ImportedTokens;

const columns = (): ColumnDef<ITokens>[] => [
	{
		accessorKey: 'name',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Name" />
		),
		cell: ({ row }) => (
			<div className="flex items-center">
				<img src={row.original.logo} className="w-4 h-4 rounded-full me-2" />
				{row.getValue('name')}
			</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'symbol',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Symbol" />
		),
		cell: ({ row }) => (
			<div className="flex items-center">${row.getValue('symbol')}</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'address',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Contract Address" />
		),
		cell: ({ row }) => (
			<div className="w-[270px] truncate">{row.getValue('address')}</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'chainId',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Chain" />
		),
		cell: ({ row }) => (
			<ChainBadge
				chainId={row.getValue('chainId')}
				className="py-1 flex items-center"
				chain={row.original?.chain?.toString()}
				namespaceTag={row.original?.namespaceTag}
			/>
		),
		enableSorting: true,
		enableHiding: false,
	},
	{
		accessorKey: 'status',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="status" />
		),
		cell: ({ row }) => (
			<div className="py-1 flex items-center">
				<StatusBadge status={row.getValue('status')} />
			</div>
		),
		enableSorting: true,
		enableHiding: false,
	},

	{
		accessorKey: 'decimals',
		header: ({ column }) => (
			<DataTableColumnHeader column={column} title="Decimals" />
		),
		cell: ({ row }) => (
			<div className="truncate">{row.getValue('decimals')}</div>
		),
		enableSorting: true,
		enableHiding: false,
	},
];
