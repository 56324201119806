import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { ITokens } from '@/features/campaigns/hooks/tokens/useGetAllTokens';
import ChainBadge from '@/components/element/badges/ChainBadge';

export const tokenColumns = (options: any): ColumnDef<ITokens>[] => {
	const cols: ColumnDef<ITokens>[] = [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" />
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					<img
						src={row.original.logo}
						className="w-4 h-4 rounded-full me-2"
					/>
					{row.getValue('name')}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'symbol',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Symbol" />
			),
			cell: ({ row }) => (
				<div className="flex items-center">${row.getValue('symbol')}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'address',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Contract Address" />
			),
			cell: ({ row }) => (
				<div className="w-[160px] truncate">{row.getValue('address')}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'chainId',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Chain" />
			),
			cell: ({ row }) => (
				<ChainBadge
					chainId={row.getValue('chainId')}
					className="py-1 flex items-center"
					chain={row.original?.chain?.toString()}
					namespaceTag={row.original?.namespaceTag}
				/>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ row }) => (
				<div className="py-1 flex items-center">
					<StatusBadge status={row.getValue('status')} />
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'enterpriseId',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Enterprise" />
			),
			cell: ({ row }) => (
				<div className="py-1  truncate max-w-[140px]">
					{row.original?.enterpriseId?.name}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},

		{
			accessorKey: 'decimals',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Decimals" />
			),
			cell: ({ row }) => (
				<div className="truncate">{row.getValue('decimals')}</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => <DataTableRowActions row={row} options={options} />,
		},
	];
	return cols;
};
