import InputText from '@/components/element/inputs/InputText';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import { useCreateActionProvider } from '@/features/dave/hooks/actions/useCreateActionProvider';
import { useDaveUtility } from '@/features/dave/hooks/useDaveUtility';

const SelectAddress = () => {
	const { details, setDetails, errors } = useCreateActionProvider();
	const { supportedChains, isChainsLoading } = useDaveUtility();

	return (
		<div className="pb-20">
			<div className="mb-4">
				<h3 className="text-md mb-1 font-medium">
					<i className="bi-person me-2" />
					Which address is creating the transaction with user?
				</h3>
				<p className="text-xs text-muted-foreground">
					Enter the address that is creating the transaction with user.
				</p>
			</div>
			<div className="grid grid-cols-12 gap-2">
				<InputText
					value={details.transactionFrom}
					setValue={(e) => setDetails({ ...details, transactionFrom: e })}
					placeholder="0x7a5b652...75d5157D62dA4"
					className="bg-white dark:bg-gray-800 col-span-8"
					error={errors.transactionFrom}
					errorText="Please enter a valid address."
				/>
				<div className="col-span-4">
					<SelectNetwork2
						value={details.chainId}
						allowedChainIds={supportedChains?.map((chain) =>
							chain.chainId?.toString(),
						)}
						disabled={isChainsLoading}
						placeholder="Select Network"
						setValue={(e) =>
							setDetails({
								...details,
								chainId: e.chainId?.toString(),
								chain: e.chain,
								namespaceTag: e.namespaceTag,
							})
						}
					/>
				</div>
			</div>
		</div>
	);
};

export default SelectAddress;
