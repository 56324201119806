import ChainBadge from '@/components/element/badges/ChainBadge';
import { Media } from '@/components/element/media/Media';
import { IdentityNamespaceTag } from '@/features/campaigns/types';

export const NFT = ({
	image,
	title,
	setOpen,
	chainId,
	chain,
	namespaceTag,
}: {
	image: string;
	title: string;
	setOpen: () => void;
	chainId: string;
	chain: string;
	namespaceTag: IdentityNamespaceTag;
}) => {
	return (
		<div
			className="cursor-pointer hover:bg-slate-100 p-2 rounded-lg"
			onClick={() => {
				setOpen();
			}}
		>
			<Media
				className="rounded-lg aspect-square w-full object-cover"
				src={image}
				lazyLoading
			/>
			<div className="">
				<div className="mt-3">
					<div className=" text-sm mb-1">{title}</div>
					<ChainBadge
						chainId={chainId}
						className="text-sm"
						chain={chain}
						namespaceTag={namespaceTag}
					/>
				</div>
			</div>
		</div>
	);
};
