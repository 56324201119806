import { wagmiConfig } from '@/lib/wagmi';
import { handleErrorMessage } from '@/utils/notifications';
import { ethers } from 'ethers';
import { useEffect, useRef, useState } from 'react';
import { waitForTransactionReceipt } from 'wagmi/actions';
import { useAccount, useConnect, useSwitchChain, useWriteContract } from 'wagmi';
import { injected } from 'wagmi/connectors';
import { parseJsonFromEthers } from '@/utils/parsers';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { getScanLink } from '@/utils/blockChain';

export enum TxnStatus {
	Idle = 'idle',
	AccountRequested = 'account-request',
	WalletConnected = 'wallet-connected',
	ChainChangedRequested = 'chain-changed-request',
	ChainChanged = 'chain-changed',
	Hash = 'hash',
	Receipt = 'receipt',
	Completed = 'completed',
	Error = 'error',
	AccountRequestRejected = 'account-request-rejected',
	ProviderNotFound = 'provider-not-found',
}

export const useCreateTxn = () => {
	const { address, chainId: currentChainId } = useAccount();
	const { connectAsync } = useConnect();
	const { switchChainAsync } = useSwitchChain();
	const { writeContractAsync } = useWriteContract();

	const [status, setStatus] = useState<TxnStatus>(TxnStatus.Idle);
	const [loading, setLoading] = useState(false);
	const [txHash, setTxHash] = useState('');

	const currentChainIdRef = useRef<number>(currentChainId);

	currentChainIdRef.current = currentChainId;

	useEffect(() => {
		setLoading(false);
		setStatus(TxnStatus.Idle);
		setTxHash('');
	}, []);

	const connectWallet = async () => {
		try {
			if (!address) {
				await connectAsync({ connector: injected() });
			}
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	const changeNetwork = async (chainId: number) => {
		try {
			if (chainId !== currentChainIdRef?.current) {
				await switchChainAsync({ chainId: chainId });
				return true;
			}
			if (currentChainIdRef?.current === chainId) {
				return true;
			}
			return false;
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	const handleTxnFailure = async (error: any) => {
		setStatus(TxnStatus.Error);
		setLoading(false);
		return error;
	};

	const handleTxnSuccess = async (
		receipt: any,
		account: string,
		ABI: any,
		chainId: number,
	) => {
		try {
			setStatus(TxnStatus.Completed);
			const blockHash = receipt.blockHash;
			const blockNumber = receipt.blockNumber;
			const transactionHash = receipt.transactionHash;
			const logs = receipt.logs;
			const decodedLogs_ = decodeLogs(logs, ABI);
			return parseJsonFromEthers({
				account,
				blockHash,
				blockNumber,
				transactionHash,
				chainId,
				receipt: {
					...receipt,
					decodedLogs: decodedLogs_,
				},
			});
		} catch (err) {
			handleErrorMessage(err);
		}
	};

	const createTxn = async ({
		chainId,
		contractAddress,
		args,
		ABI,
		fnName,
	}: {
		chainId: number;
		contractAddress: string;
		args: any[];
		ABI: any;
		fnName: string;
		transactionValue?: string;
	}): Promise<{
		account: string;
		blockHash: string;
		blockNumber: number;
		transactionHash: string;
		chainId: number;
		receipt: any;
	} | void> => {
		try {
			setLoading(true);
			setStatus(TxnStatus.AccountRequested);
			const isConnected = await connectWallet();
			if (!isConnected) {
				setLoading(false);
				throw new Error(
					'There was an error connecting your wallet. Please try again!',
				);
			}
			setStatus(TxnStatus.ChainChangedRequested);
			const isChainChanged = await changeNetwork(Number(chainId));
			if (!isChainChanged) {
				setLoading(false);
				throw new Error(
					'There was an error requesting your Metamask to change the chain to start the transaction. Please change the chain manually and click on the button again!',
				);
			}
			setStatus(TxnStatus.ChainChanged);

			const txn = await writeContractAsync({
				abi: ABI,
				address: contractAddress,
				functionName: fnName,
				args: args,
				chainId: chainId,
				__mode: 'prepared',
			});
			toast('Transaction submitted, please wait for confirmation', {
				duration: 2000,
				action: (
					<Button
						onClick={() => {
							window.open(getScanLink(txn, chainId, 'tx'), '_blank');
						}}
					>
						View Transaction
					</Button>
				),
			});
			setTxHash(txn);
			setStatus(TxnStatus.Hash);

			const receipt = await waitForTransactionReceipt(wagmiConfig, {
				hash: txn,
			});
			setStatus(TxnStatus.Receipt);
			return handleTxnSuccess(receipt, address, ABI, chainId);
		} catch (err) {
			console.log(err);
			return handleTxnFailure(err);
		}
	};

	return {
		startTxn: createTxn,
		account: address,
		status,
		setStatus,
		txnLoading: loading,
		transactionHash: txHash,
	};
};

const decodeLogs = (
	logs: {
		data: string;
		topics: string[];
	}[],
	ABI: any,
) => {
	try {
		const iface = new ethers.utils.Interface(ABI);
		const decodedLogs = [];
		for (const log of logs) {
			try {
				const decodedLog = iface.parseLog(log);
				decodedLogs.push(decodedLog);
			} catch (err) {
				//
			}
		}
		return decodedLogs;
	} catch (err) {
		console.log(err);
		return { address: '' };
	}
};
