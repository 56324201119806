import InputText from '@/components/element/inputs/InputText';
import { Card, CardContent } from '@/components/ui/card';
import {
	CampaignRewardType,
	ICampaignAddReward,
	IdentityNamespaceTag,
} from '@/features/campaigns/types';
import { INFTs } from '@/features/nft-contracts/providers/NFTsContractProvider';
import { useMemo } from 'react';
import RewardMethodSelect from '../../input/RewardMethodSelect';
import RewardCardHeader from '../../others/RewardCardHeader';
import RewardNFTSelect from './RewardNFTSelect';

const NFTReward = ({
	nftReward,
	setNftReward,
	errors,
	noEndTime,
}: {
	nftReward: ICampaignAddReward;
	setNftReward: (reward: ICampaignAddReward) => void;
	errors: {
		nftContract: boolean;
		winners: boolean;
		method: boolean | string;
	};
	noEndTime: boolean;
}) => {
	const allowedMethods = useMemo(() => {
		const methods = [CampaignRewardType.Fcfs, CampaignRewardType.Unlimited];
		if (!noEndTime) {
			methods.push(CampaignRewardType.LuckyDraw);
			methods.push(CampaignRewardType.Leaderboard);
		}
		return methods;
	}, [noEndTime]);
	return (
		<Card className="mb-10 mt-10 shadow-none">
			<RewardCardHeader
				title="NFTs Rewards"
				description="NFTs are fungible tokens that are compatible with the Ethereum network."
				// tooltip="NFTs are unique digital assets that are stored on a blockchain."
			/>
			<CardContent className="space-y-8">
				<div>
					<div className="text-sm font-medium mb-1">Reward Method</div>
					<RewardMethodSelect
						allowedMethods={allowedMethods}
						value={nftReward.method}
						setValue={(value) =>
							setNftReward({
								...nftReward,
								method: value as CampaignRewardType,
							})
						}
						error={!!errors?.method}
						errorText={errors?.method || 'Please select a reward method'}
					/>
				</div>
				<div className="">
					<div className="text-sm font-medium mb-1">
						Select the deployed contract
					</div>
					<RewardNFTSelect
						value={nftReward.nftReward.nftDocId}
						setValue={(contract: INFTs) =>
							setNftReward({
								...nftReward,
								nftReward: {
									...nftReward.nftReward,
									nftDocId: contract._id,
									nftTokenId: contract.tokenId,
									chainId: nftReward?.nftReward?.chainId,
									chain: contract.chain || nftReward?.nftReward?.chain,
									namespaceTag: contract.namespaceTag || nftReward?.nftReward
										?.namespaceTag as IdentityNamespaceTag,
									name: contract.name,
									image: contract.imageUrl,
								},
							})
						}
						error={errors?.nftContract}
					/>
				</div>
				{nftReward?.method !== CampaignRewardType.Unlimited && (
					<InputText
						label="Number of winners"
						placeholder="100"
						value={nftReward?.numRewards}
						setValue={(e) =>
							setNftReward({
								...nftReward,
								numRewards: parseInt(e?.toString()),
							})
						}
						type="number"
						error={errors?.winners}
						errorText="Please enter a number of winners"
					/>
				)}
			</CardContent>
		</Card>
	);
};

export default NFTReward;
