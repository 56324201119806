import {
	Dispatch,
	SetStateAction,
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { ErrorsState, IAPIFormFields, ParsedData } from '../types/task-apis.type';
import {
	ApiCreateSectionEnum,
	ApiCredentialTypes,
	ApiDataEnums,
	CreateTaskAPISteps,
} from '../types/task-apis.enum';
import { usePrompt } from '@/hooks/usePrompt';
import { isValidEndpoint } from '@/utils/parsers';
import useTaskApiInitialize from '../hooks/useTaskApiInitialize';

const defaultValue: IAPIFormFields = {
	apiName: '',
	apiMethod: 'POST',
	apiEndpoint: '',
	apiPayloadMethod: 'BODY',
	apiVerificationFor: 'evm',
	apiPayload: [
		{
			key: 'address',
			value: ApiDataEnums.WalletAddress,
			testValue: '',
		},
	],
	apiHeaders: [
		{
			key: 'Authorization',
			value: '',
		},
	],
	isHeaderRequired: false,
	isRecurringTasksEnabled: false,
	isMetricBasedTaskEnabled: false,
	isApiTestDone: false,
	isApiSchemaValid: false,
	responseSchema: {},
	_id: '',
	apiCredentialsType: ApiCredentialTypes.Rest,
	apiConstantDataFields: [],
	graphQlQuery: '',
	apiOutputExpressions: {
		result: {
			expression: 'return response?.data?.result',
		},
		code: {
			expression: '',
		},
		message: {
			expression: '',
		},
	},
	curlCommand: '',
	isCustomConstantPayloadRequired: false,
	isAdvancedMode: false,
};

interface ConnectAPIState {
	formFields: IAPIFormFields;
	setFormFields: Dispatch<SetStateAction<IAPIFormFields>>;
	formErrors: any;
	setFormErrors: any;
	resetForm: any;
	isLoading: boolean;
	setIsLoading: any;
	step: CreateTaskAPISteps;
	setStep: Dispatch<SetStateAction<CreateTaskAPISteps>>;
	parsedData: ParsedData;
	setParsedData: Dispatch<SetStateAction<ParsedData>>;
	evaluationErrors: ErrorsState;
	setEvaluationErrors: Dispatch<SetStateAction<ErrorsState>>;
	evaluatedFields: Record<string, boolean>;
	setEvaluatedFields: Dispatch<SetStateAction<Record<string, boolean>>>;
	dialogMaxWidth: string;
	setDialogMaxWidth: Dispatch<SetStateAction<string>>;
	setIsDirty: Dispatch<SetStateAction<boolean>>;
	section: ApiCreateSectionEnum;
	setSection: Dispatch<SetStateAction<ApiCreateSectionEnum>>;
	contextualSuggestions: string | null;
	setContextualSuggestions: Dispatch<SetStateAction<string | null>>;
	completedSteps: CreateTaskAPISteps[];
	setCompletedSteps: Dispatch<SetStateAction<CreateTaskAPISteps[]>>;
	findFirstMissingStep: any;
	animateWiggle: boolean;
	updateCompletedSteps: any;
	isLoadingApiDetails: boolean;
}

const initialState: ConnectAPIState = {
	formFields: defaultValue,
	setFormFields: () => {},
	formErrors: {},
	setFormErrors: () => {},
	resetForm: () => {},
	isLoading: false,
	setIsLoading: () => {},
	step: CreateTaskAPISteps.Basic,
	setStep: () => {},
	parsedData: null,
	setParsedData: () => {},
	evaluationErrors: {},
	setEvaluationErrors: () => {},
	evaluatedFields: {},
	setEvaluatedFields: () => {},
	dialogMaxWidth: '35rem',
	setDialogMaxWidth: () => {},
	setIsDirty: () => {},
	section: ApiCreateSectionEnum.Details,
	setSection: () => {},
	contextualSuggestions: null,
	setContextualSuggestions: () => {},
	completedSteps: [],
	setCompletedSteps: () => {},
	findFirstMissingStep: () => {},
	animateWiggle: false,
	updateCompletedSteps: () => {},
	isLoadingApiDetails: false,
};

export const ConnectAPIContext = createContext<ConnectAPIState>(initialState);

export function ConnectAPIProvider({ children }) {
	const [formFields, setFormFields] = useState<IAPIFormFields>(defaultValue);
	const [formErrors, setFormErrors] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState<CreateTaskAPISteps>(CreateTaskAPISteps.Basic);
	const [parsedData, setParsedData] = useState<ParsedData | null>(null);
	const [evaluationErrors, setEvaluationErrors] = useState<ErrorsState>({});
	const [evaluatedFields, setEvaluatedFields] = useState<Record<string, boolean>>(
		{},
	);
	const [dialogMaxWidth, setDialogMaxWidth] = useState(
		initialState.dialogMaxWidth,
	);
	const [isDirty, setIsDirty] = useState(false);
	const [section, setSection] = useState<ApiCreateSectionEnum>(
		ApiCreateSectionEnum.Details,
	);
	const [contextualSuggestions, setContextualSuggestions] = useState<
		string | null
	>(null);
	const [completedSteps, setCompletedSteps] = useState<CreateTaskAPISteps[]>([]);
	const [animateWiggle, setAnimateWiggle] = useState(false);

	usePrompt({
		isDirty,
		subtitle:
			"All unsaved changes will be lost unless you 'Save As Draft'. Are you sure you want to leave this page?",
	});

	const { isLoadingApiDetails } = useTaskApiInitialize({
		setFormFields,
		setIsDirty,
		setCompletedSteps,
	});

	const resetForm = () => {
		setFormFields(defaultValue);
		setFormErrors({});
		setStep(CreateTaskAPISteps.Basic);
		setParsedData(null);
		setEvaluationErrors({});
		setEvaluatedFields({});
		setDialogMaxWidth(initialState.dialogMaxWidth);
		setIsDirty(false);
		setSection(ApiCreateSectionEnum.Details);
		setContextualSuggestions(null);
	};

	const findFirstMissingStep = (updatedCompletedSteps: CreateTaskAPISteps[]) => {
		const requiredSteps = [
			CreateTaskAPISteps.Basic,
			CreateTaskAPISteps.Curl,
			CreateTaskAPISteps.Payload,
		];

		const findLatestUpdated =
			completedSteps?.length > updatedCompletedSteps?.length
				? completedSteps
				: updatedCompletedSteps;

		// Find the first missing step in the defined order
		const firstMissingStep = requiredSteps.find(
			(step) => !findLatestUpdated?.includes(step),
		);

		if (firstMissingStep) {
			setAnimateWiggle(false);
			requestAnimationFrame(() => setAnimateWiggle(true));

			return firstMissingStep; // Return the first missing step
		}
		setContextualSuggestions(null);
		return null; // All steps are completed
	};

	const isStepComplete = useMemo(
		() =>
			(stepToCheck: CreateTaskAPISteps): boolean => {
				switch (stepToCheck) {
					case CreateTaskAPISteps.Basic:
						return !!formFields?.apiName?.trim();

					case CreateTaskAPISteps.Curl:
						if (
							formFields?.apiCredentialsType ===
							ApiCredentialTypes.GraphQl
						) {
							return !!formFields?.curlCommand;
						}
						return true; // For REST APIs, CURL is optional

					case CreateTaskAPISteps.Payload: {
						const hasValidEndpoint =
							!!formFields?.apiEndpoint?.trim() &&
							isValidEndpoint(formFields?.apiEndpoint?.trim()) &&
							!formFields?.apiEndpoint?.includes('?');

						let hasValidHeaders = true;
						if (formFields?.isHeaderRequired) {
							hasValidHeaders = formFields.apiHeaders?.every(
								(item) => item.key?.trim() && item.value?.trim(),
							);
						}

						let hasValidConstantPayload = true;
						if (formFields?.isCustomConstantPayloadRequired) {
							hasValidConstantPayload =
								formFields.apiConstantDataFields?.every(
									(item) =>
										item.key?.trim() && item.value !== undefined,
								);
						}

						return (
							hasValidEndpoint &&
							hasValidHeaders &&
							hasValidConstantPayload
						);
					}

					case CreateTaskAPISteps.Testing:
						return formFields?.apiPayload?.every(
							(item) => !!item.testValue,
						);

					default:
						return false;
				}
			},
		[formFields],
	);

	const updateCompletedSteps = useMemo(
		() => (currentStep: CreateTaskAPISteps) => {
			setCompletedSteps((prevSteps) => {
				if (isStepComplete(currentStep)) {
					return prevSteps.includes(currentStep)
						? prevSteps
						: [...prevSteps, currentStep];
				}
				return prevSteps.filter((step) => step !== currentStep);
			});
		},
		[isStepComplete],
	);

	return (
		<ConnectAPIContext.Provider
			value={{
				formFields,
				setFormFields,
				formErrors,
				setFormErrors,
				resetForm,
				isLoading,
				setIsLoading,
				step,
				setStep,
				parsedData,
				setParsedData,
				evaluationErrors,
				setEvaluationErrors,
				evaluatedFields,
				setEvaluatedFields,
				dialogMaxWidth,
				setDialogMaxWidth,
				setIsDirty,
				section,
				setSection,
				contextualSuggestions,
				setContextualSuggestions,
				completedSteps,
				setCompletedSteps,
				findFirstMissingStep,
				animateWiggle,
				updateCompletedSteps,
				isLoadingApiDetails,
			}}
		>
			{children}
		</ConnectAPIContext.Provider>
	);
}
