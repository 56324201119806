import NoDataCard from '@/components/element/cards/NoDataCard';
import { DataTable } from '@/components/element/data-table/DataTable';
import { FacetedFilter } from '@/components/element/inputs/FacetedFilter';
import InputText from '@/components/element/inputs/InputText';
import PageHeader from '@/components/layout/PageHeader';
import FullPageLoading from '@/components/layout/loading/FullPageLoading';
import { links } from '@/config/links';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { useQueryInitialise } from '@/hooks/useQueryInitialise';
import { useRouter } from '@/hooks/useRouter';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useGetTaskAPIs } from '../hooks/useGetTaskAPIs';
import { TaskAPIStatus } from '../types/task-apis.enum';
import { useAuth } from '@/hooks/useAuth';

const ApisPage = () => {
	const {
		apiRequestsQuery,
		taskApiColumns,
		showNew,
		setShowNew,
		filteredAPIs,
		filters,
		setFilters,
		search,
		setSearch,
	} = useGetTaskAPIs();
	const { navigate } = useRouter();
	const { user } = useAuth();

	const handleOpen = () => {
		analytics.track(TrackingEvents.TaskAPIsSectionViewed, {
			isUpdate: false,
		});
		navigate('/app/tools/task-apis/new');
		// setShowIntro(true);
	};

	useQueryInitialise('new', 'true', () => {
		handleOpen();
	});

	if (apiRequestsQuery.isLoading) return <FullPageLoading />;

	return (
		<div>
			<PageHeader
				title="Integrate your APIs - in under a minute"
				description="Simple process to add APIs and create custom tasks on your quests"
				btnLabel="Connect your API"
				btnOnClick={() => handleOpen()}
				learn={
					apiRequestsQuery?.data?.length === 0 ? links.docs.taskAPIs : ''
				}
				icon={'plug-fill'}
				btnLabel2="API Docs"
				btnOnClick2={() => {
					analytics.track(TrackingEvents.TaskAPIsDocsClicked, {
						userId: user?._id,
						enterpriseId: user?.enterpriseId,
					});
					window.open(links?.docs?.apiIntegration, '_blank');
				}}
			/>

			<div>
				{apiRequestsQuery?.data?.length === 0 ? (
					<NoDataCard
						title="No API connected yet"
						description="Connect your API to create custom quests and campaigns"
						btnLabel="Connect your API"
						btnOnClick={() => handleOpen()}
						className="mt-10"
						image="https://framerusercontent.com/images/axBztP7wGIM2Oz1MQwfrxJ0lg.png"
					/>
				) : (
					<>
						<div className="flex items-center mt-10 mb-5 space-x-4">
							<InputText
								value={search}
								setValue={setSearch as any}
								prepend={
									<i className="bi-search px-3 text-muted-foreground text-sm"></i>
								}
								placeholder="Search APIs"
								className="w-[250px] lg:w-[250px]"
							/>
							<FacetedFilter
								title="Status"
								options={apiFilters}
								setSelectedValues={(values: any) =>
									setFilters((prev) => ({
										...prev,
										status: values,
									}))
								}
								selectedValues={filters.status}
							/>
						</div>
						<DataTable
							columns={taskApiColumns}
							data={filteredAPIs}
							isLoading={apiRequestsQuery.isLoading}
							totalCount={apiRequestsQuery.data?.length || 0}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default ApisPage;

const apiFilters = [
	{
		value: TaskAPIStatus.Approved,
		label: <StatusBadge status={TaskAPIStatus.Approved} />,
	},
	{
		value: TaskAPIStatus.Rejected,
		label: <StatusBadge status={TaskAPIStatus.Rejected} />,
	},
	{
		value: TaskAPIStatus.Submitted,
		label: <StatusBadge status={TaskAPIStatus.Submitted} />,
	},
	{
		value: TaskAPIStatus.UnderReview,
		label: <StatusBadge status={TaskAPIStatus.UnderReview} />,
	},
	{
		value: TaskAPIStatus.Draft,
		label: <StatusBadge status={TaskAPIStatus.Draft} />,
	},
];
