import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { X, Plus } from 'lucide-react';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import useAdminCampaignTags from '../../hooks/useAdminCampaignTags';

const CampaignAddTags = ({ open, setOpen, campaign, setCampaign, refetch }) => {
	const { isLoading, handleUpdateTags, tags, setTags, commonTags } =
		useAdminCampaignTags({ campaign, setCampaign, refetch, setOpen });

	const addNewTag = () => {
		setTags([...tags, { key: '', value: '' }]);
	};

	const updateTag = (index, field, value) => {
		const newTags = [...tags];
		newTags[index] = { ...newTags[index], [field]: value };
		setTags(newTags);
	};

	const removeTag = (index) => {
		const newTags = tags.filter((_, i) => i !== index);
		setTags(newTags);
	};

	const isCommonKey = (key) => {
		return commonTags.some((commonTag) => commonTag.key === key);
	};

	const getValuesForKey = (key) => {
		const matchedTag = commonTags.find((commonTag) => commonTag.key === key);
		return matchedTag ? matchedTag.values : [];
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[570px] min-h-[10rem] overflow-visible">
				<DialogHeader className="border-b pb-3">
					<DialogTitle>Add Tags to Campaign</DialogTitle>
				</DialogHeader>

				<div className="w-full space-y-6">
					{tags.map((tag, index) => (
						<div key={index} className="flex space-x-2 items-center">
							<Input
								placeholder="Tag Key"
								value={tag.key}
								onChange={(e) =>
									updateTag(index, 'key', e.target.value)
								}
								className="flex-1"
							/>

							{isCommonKey(tag.key) ? (
								<Select
									value={tag.value}
									onValueChange={(value) =>
										updateTag(index, 'value', value)
									}
								>
									<SelectTrigger className="flex-1">
										<SelectValue placeholder="Select Value" />
									</SelectTrigger>
									<SelectContent>
										{getValuesForKey(tag.key).map((value) => (
											<SelectItem key={value} value={value}>
												{value}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							) : (
								<Input
									placeholder="Tag Value"
									value={tag.value}
									onChange={(e) =>
										updateTag(index, 'value', e.target.value)
									}
									className="flex-1"
								/>
							)}

							<Button
								variant="ghost"
								size="icon"
								onClick={() => removeTag(index)}
								className="text-destructive"
							>
								<X className="h-4 w-4" />
							</Button>
						</div>
					))}

					<Button variant="outline" onClick={addNewTag} className="w-full">
						<Plus className="mr-2 h-4 w-4" /> Add New Tag
					</Button>
				</div>

				<DialogFooter className="space-x-2 flex border-t pt-3">
					<Button variant="ghost" onClick={() => setOpen(false)}>
						Cancel
					</Button>
					<Button onClick={handleUpdateTags} disabled={isLoading}>
						Update Tags
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default CampaignAddTags;
