import { Checkbox } from '@/components/ui/checkbox';
import { useConnectAPI } from '../hooks/useConnectAPI';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { useAuth } from '@/hooks/useAuth';

const ApiMeta = () => {
	const { formFields, setFormFields, setIsDirty } = useConnectAPI();
	const { user } = useAuth();
	return (
		<div className="">
			<div className="flex flex-row items-start space-x-3 mt-4">
				<Checkbox
					className="mt-1"
					checked={
						formFields?.isMetricBasedTaskEnabled &&
						!formFields?.isRecurringTasksEnabled
					}
					onCheckedChange={(checked) => {
						setFormFields((prev: any) => ({
							...prev,
							isMetricBasedTaskEnabled: !!checked,
							isRecurringTasksEnabled: false,
							apiOutputExpressions: {
								...prev.apiOutputExpressions,
								metric: {
									expression: checked
										? 'return response?.data?.metric'
										: null,
								},
								metricDataType: {
									expression: checked
										? 'return response?.data?.metricDataType'
										: null,
								},
							},
						}));
						setIsDirty(true);
						if (checked) {
							analytics.track(
								TrackingEvents.TaskAPIsDynamicXPsCheckbox,
								{
									userId: user?._id,
									enterpriseId: user?.enterpriseId,
								},
							);
						}
					}}
				/>
				<div className="space-y-1 leading-none">
					<div className="text-sm font-medium ">
						Do you want to set up Dynamic XPs for any tasks using your
						API?
					</div>
					<div className="text-xs leading-[1.8]">
						Make sure to set up the{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
							Metric-based XP
						</code>{' '}
						setting on your task during quest creation.
					</div>
					<div className="flex text-xs leading-[1.8]">
						ℹ️
						<div className="ml-1">
							For your developer: To enable dynamic XPs for any task,
							your API needs to send {''}
							<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
								value
							</code>
							.
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-row items-start space-x-3 mt-4">
				<Checkbox
					className="mt-1"
					checked={
						formFields?.isRecurringTasksEnabled &&
						!formFields?.isMetricBasedTaskEnabled
					}
					onCheckedChange={(checked) => {
						setFormFields((prev: any) => ({
							...prev,
							isRecurringTasksEnabled: !!checked,
							isMetricBasedTaskEnabled: false,
						}));
						setIsDirty(true);
						if (checked) {
							analytics.track(
								TrackingEvents.TaskAPIsRecurringCheckbox,
								{
									userId: user?._id,
									enterpriseId: user?.enterpriseId,
								},
							);
						}
					}}
				/>
				<div className="space-y-1 leading-none">
					<div className="text-sm font-medium">
						Do you want to set up any recurring tasks using your API?
					</div>
					<div className="text-xs leading-[1.8]">
						Make sure to set up the{' '}
						<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
							Recurrence
						</code>{' '}
						setting on your task during quest creation.
					</div>

					<div className="flex text-xs leading-[1.8]">
						ℹ️
						<div className="ml-1">
							For your developer: we will send{' '}
							<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
								startTimestamp
							</code>{' '}
							and{' '}
							<code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono  font-semibold">
								endTimestamp
							</code>{' '}
							to verify user action during the period.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ApiMeta;
