import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { INFTContract } from '../types/nfts.enum';
import { getScanLink } from '@/utils/blockChain';
import { Media } from '@/components/element/media/Media';
import ChainBadge from '@/components/element/badges/ChainBadge';

const NFTCard = ({
	image,
	name,
	imageUrl,
	chainId,
	tokenId,
	transactionHash,
	imagePosition,
	chain,
	namespaceTag,
}: INFTContract) => {
	return (
		<Card className="relative">
			<CardHeader className="p-2">
				<Media
					className="rounded-lg h-[250px] object-cover aspect-square border"
					src={image || imageUrl}
					lazyLoading
					imagePosition={imagePosition}
				/>
			</CardHeader>
			<CardContent className="px-3 pt-3 pb-3">
				<div className="">
					<div>
						<div className="font-medium">{name}</div>
					</div>
					<div className="w-full">
						<div className="flex justify-between items-center mt-4">
							<div className="text-xs">Chain</div>
							<ChainBadge
								chainId={chainId}
								chain={chain}
								namespaceTag={namespaceTag}
								className="flex items-center text-xs"
							/>
						</div>
						<div className="flex justify-between items-center mt-4">
							<div className="text-xs">Token Id</div>
							<div className="text-xs">{tokenId}</div>
						</div>
						<div className="flex justify-between items-center mt-4">
							<div className="text-xs">Transaction Link</div>
							<div className="text-xs">
								<div
									className="text-xs cursor-pointer hover:underline"
									onClick={() =>
										window.open(
											getScanLink(
												transactionHash,
												Number(chainId),
												'tx',
											),
											'_blank',
										)
									}
								>
									Link
									<i className="bi-box-arrow-up-right ms-2"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default NFTCard;
