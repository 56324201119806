import { Button } from '@/components/ui/button';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { useEffect, useState } from 'react';
import IntroducingSpeedRun from '../speedrun/IntroducingSpeedrunBooster';
import IntroducingBoostersContent from './IntroducingBoostersContent';
import IntroducingChallengerSeries from '../challenger-series/IntroducingChallengerSeries';

const IntroducingBoosters = ({ open, setOpen }) => {
	const [step, setStep] = useState('introduction');

	useEffect(() => {
		setStep('introduction');
	}, [open]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent
				className={
					step === 'introduction'
						? 'sm:min-w-[400px] p-0 border-0 flex items-center'
						: 'sm:min-w-[825px] p-0 border-0 flex items-center'
				}
			>
				{step === 'introduction' && (
					<IntroducingBoostersContent setStep={setStep} />
				)}
				{step.includes('speedrun') && (
					<IntroducingSpeedRun
						step={step}
						setStep={setStep}
						setOpen={setOpen}
					/>
				)}
				{step.includes('challenger') && (
					<IntroducingChallengerSeries
						step={step}
						setStep={setStep}
						setOpen={setOpen}
					/>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default IntroducingBoosters;
