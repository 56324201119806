export enum CampaignSteps {
	Template = 'TEMPLATE',
	Details = 'DETAILS',
	Tasks = 'TASKS',
	Rewards = 'REWARDS',
	Eligibility = 'ELIGIBILITY',
}

export enum CampaignCategories {
	Custom = 'CUSTOM',
	Giveaway = 'GIVEAWAY',
	Collab = 'COLLAB',
	Kol = 'KOL',
	Community = 'COMMUNITY',
	Quest = 'QUEST',
	Ads = 'ADS',
	SpecialEvent = 'SPECIAL_EVENT',
	Anniversary = 'ANNIVERSARY',
}

export enum CampaignType {
	Custom = 'CUSTOM',
	Quest = 'QUEST',
}

export enum ChannelMedium {
	None = 'NONE',
	Default = 'DEFAULT',
	Discord = 'DISCORD',
	Twitter = 'TWITTER',
	Telegram = 'TELEGRAM',
	Instagram = 'INSTAGRAM',
	Linkedin = 'LINKEDIN',
	Facebook = 'FACEBOOK',
	Partnership = 'PARTNERSHIP',
	Snapchat = 'SNAPCHAT',
	Other = 'OTHER',
}

export enum Status {
	Active = 'ACTIVE',
	Draft = 'DRAFT',
	Completed = 'COMPLETED',
	Scheduled = 'SCHEDULED',
	Deleted = 'DELETED',
	Recommended = 'RECOMMENDED',
	InReview = 'IN_REVIEW',
	TransactionStarted = 'TRANSACTION_STARTED',
	Inactive = 'INACTIVE',
	Paused = 'PAUSED',
}

export enum UserInputTypes {
	Image = 'IMAGE',
	Textarea = 'TEXTAREA',
	Input = 'INPUT',
}

export enum CampaignAmountType {
	SameAmount = 'SAME_AMOUNT',
	SeparateAmount = 'SEPARATE_AMOUNT',
}

export enum CampaignRewardType {
	Fcfs = 'FCFS',
	LuckyDraw = 'LUCKY_DRAW',
	Unlimited = 'UNLIMITED',
	Leaderboard = 'LEADERBOARD',
	TieredLeaderboard = 'TIERED_LEADERBOARD',
	SmartRaffle = 'SMART_RAFFLE',
}

export enum CampaignWalletProviders {
	EVMWallet = 'EVMWallet',
	Bitfinity = 'Bitfinity',
	PlenaWalletConnect = 'PlenaWalletConnect',
}

export enum TemplateType {
	DiscordJoin = 'DiscordJoin',
	DiscordRole = 'DiscordRole',
	TwitterTweet = 'TwitterTweet',
	TwitterTweetHashTags = 'TwitterTweetHashTags',
	TwitterTweetMentions = 'TwitterTweetMentions',
	TwitterLike = 'TwitterLike',
	HoldCompassNftV2 = 'HoldCompassNftV2',
	TwitterRetweet = 'TwitterRetweet',
	TwitterQuoteTweet = 'TwitterQuoteTweet',
	CampaignCompletion = 'CampaignCompletion',
	CampaignCompletionWithinTimeFrame = 'CampaignCompletionWithinTimeFrame',
	EarnXpsWithinTimeFrame = 'EarnXpsWithinTimeFrame',
	GmStreakWithinTimeFrame = 'GmStreakWithinTimeFrame',
	TwitterReply = 'TwitterReply',
	TwitterReplyTagFriends = 'TwitterReplyTagFriends',
	TwitterFollow = 'TwitterFollow',
	TwitterVerified = 'TwitterVerified',
	WalletErc20Balance = 'WalletErc20Balance',
	WalletNftBalance = 'WalletNftBalance',
	WalletNativeBalance = 'WalletNativeBalance',
	WalletConnect = 'WalletConnect',
	TelegramJoin = 'TelegramJoin',
	TelegramJoinWithoutVerification = 'TelegramJoinWithoutVerification',
	WebsiteVisit = 'WebsiteVisit',
	SwapTransactionPlena = 'SwapTransactionPlena',
	PlenaWalletConnect = 'PlenaWalletConnect',
	BitfinityWalletConnect = 'BitfinityWalletConnect',
	XVerseWalletConnect = 'XVerseWalletConnect',
	TwitterNumFollowers = 'TwitterNumFollowers',
	VerifyEmail = 'VerifyEmail',
	VerifyEmailWithoutVerification = 'VerifyEmailWithoutVerification',
	OpenEndedQuestion = 'OpenEndedQuestion',
	AnswerQuestion = 'AnswerQuestion',
	Quiz = 'Quiz',
	MultipleChoiceFeedback = 'MultipleChoiceFeedback',
	OpenEndedQuiz = 'OpenEndedQuiz',
	InjectiveWalletVerification = 'InjectiveWalletVerification',
	ContractInteraction = 'ContractInteraction',
	ZkEvmContractInteraction = 'ZkEvmContractInteraction',
	ContractInteractionWithTxnHash = 'ContractInteractionWithTxnHash',
	ContractInteractionFromIntegration = 'ContractInteractionFromIntegration',
	OnChainTask = 'OnChainTask',
	PlenaOnramp = 'PlenaOnramp',
	Captcha = 'Captcha',
	MagicStoreIdVerification = 'MagicStoreIdVerification',
	LeapFiSubgraphInteraction = 'LeapFiSubgraphInteraction',
	TextUpload = 'TextUpload',
	FileUpload = 'FileUpload',
	ApiVerification = 'ApiVerification',
	ApiBasedVerification = 'ApiBasedVerification',
	RubicNomisScore = 'RubicNomisScore',
	SubgraphVerification = 'SubgraphVerification',
	PersonaNftBalance = 'PersonaNftBalance',

	LineaBridgeEthAmount = 'LineaBridgeEthAmount',
	LineaBridgeLargestEthAmount = 'LineaBridgeLargestEthAmount',
	LineaBridgeMultipleProject = 'LineaBridgeMultipleProject',
	LineaBridgeVolume = 'LineaBridgeVolume',
	MetaMaskBridgeAmount = 'MetaMaskBridgeAmount',
	MetamaskSwapAmount = 'MetaMaskSwapAmount',
	LineaSwapEthAmount = 'LineaSwapEthAmount',
	LineaSwapEthTxnVolume = 'LineaSwapEthTxnVolume',
	LineaAggregatorSwapEthAmount = 'LineaAggregatorSwapEthAmount',
	LineaSwapLstOrRwa = 'LineaSwapLstOrRwa',
	LineaSwapEthAmountVolume = 'LineaSwapEthAmountVolume',
	DappSheriffReview = 'DappSheriffReview',

	LineaBorrowEth = 'LineaBorrowEth',
	LineaBorrowOrLP = 'LineaBorrowOrLP',
	LineaAddCollateral = 'LineaAddCollateral',
	LineaRepayLoan = 'LineaRepayLoan',
	LineaLendBorrowTotalVolume = 'LineaLendBorrowTotalVolume',
	LineaLoanFromSpecificTokenCollateral = 'LineaLoanFromSpecificTokenCollateral',
	VeraxVerification = 'VeraxVerification',

	LineaAddLiquidity = 'LineaAddLiquidity',
	LineaAddVaults = 'LineaAddVaults',
	LineaHarvestRewards = 'LineaHarvestRewards',
	LineaTrade = 'LineaTrade',

	LineaAAWalletVerification = 'LineaAAWalletVerification',
	Inch1Swap = 'Inch1Swap',
	HoldIntractAchievement = 'HoldIntractAchievement',
	HoldCompassNft = 'HoldCompassNft',

	HoldNftsOutOfN = 'HoldNftsOutOfN',
	MultipleCampaignCompletion = 'MultipleCampaignCompletion',

	ModeKimExchangeSwapEthToUsdt = 'ModeKimExchangeSwapEthToUsdt',
	ModeKimExchangeLpEthUsdt = 'ModeKimExchangeLpEthUsdt',
	ModeStandardMarketSellEthToUsdt = 'ModeStandardMarketSellEthToUsdt',
	ModeIonicLendETH = 'ModeIonicLendETH',
	ModeIonicLendUSDT = 'ModeIonicLendUSDT',
	ModeNameServiceMint = 'ModeNameServiceMint',
	ModeBridgeETH = 'ModeBridgeETH',
	ModeStandardLimitSellEthToUsdt = 'ModeStandardLimitSellEthToUsdt',
	ModeStandardMarketBuyEthFromUsdt = 'ModeStandardMarketBuyEthFromUsdt',
	ModeStandardLimitBuyEthFromUsdt = 'ModeStandardLimitBuyEthFromUsdt',

	AstarBridgeETH = 'AstarBridgeETH',
	AstarHoldYoki = 'AstarHoldYoki',

	OvernightSwap = 'OvernightSwap',
	OvernightSwapPartnerAggregator = 'OvernightSwapPartnerAggregator',
	OvernightSwap1Inch = 'OvernightSwap1Inch',
	OvernightStakePancakeAndZapIn = 'OvernightStakePancakeAndZapIn',

	NoCodeQueryVerification = 'NoCodeQueryVerification',
	HoldCompassNftV3 = 'HoldCompassNftV3',
	IntractBridge = 'IntractBridge',

	HoldingNft = 'HoldingNft',
	IntractMedallions = 'IntractMedallions',
	IntractPersona = 'IntractPersona',
}

export enum TaskKeys {
	ApiEndpoint = 'API_ENDPOINT',
	StartDate = 'START_DATE',
	StartTime = 'START_TIME',
	EndDate = 'END_DATE',
	EndTime = 'END_TIME',
	DiscordInviteLink = 'DISCORD_INVITE_LINK',
	DiscordRoleId = 'DISCORD_ROLE_ID',
	DiscordRoleName = 'DISCORD_ROLE_NAME',
	TwitterHandle = 'TWITTER_HANDLE',
	TwitterTweetId = 'TWITTER_TWEET_ID',
	TwitterTweetUrl = 'TWITTER_TWEET_URL',
	TwitterAcknowledgementOpt = 'TWITTER_ACKNOWLEDGEMENT_OPT',
	IsTwitterAttributed = 'IS_TWITTER_ATTRIBUTED',
	IsUserTweetUnrestricted = 'IS_USER_TWEET_UNRESTRICTED',
	AllowedTokenAddresses = 'ALLOWED_TOKEN_ADDRESSES',
	TweetText = 'TWEET_TEXT',
	TweetFullMatchRequired = 'TWEET_FULL_MATCH_REQUIRED',
	TweetMentionsRequired = 'TWEET_MENTIONS_REQUIRED',
	TweetUrlsRequired = 'TWEET_URLS_REQUIRED',
	TweetHashtagsRequired = 'TWEET_HASHTAGS_REQUIRED',
	TweetTagsRequired = 'TWEET_TAGS_REQUIRED',
	TwitterIsVerified = 'TWITTER_IS_VERIFIED',
	TweetNumberOfTagsRequired = 'TWEET_NUMBER_OF_TAGS_REQUIRED',
	TwitterCheckFollowers = 'TWITTER_CHECK_FOLLOWERS',
	Erc20Address = 'ERC20_ADDRESS',
	Erc20Balance = 'ERC20_BALANCE',
	Erc20ChainId = 'ERC20_CHAIN_ID',
	Erc20Name = 'ERC20_NAME',
	Erc20Symbol = 'ERC20_SYMBOL',
	Erc20Decimals = 'ERC20_DECIMALS',
	NftAddress = 'NFT_ADDRESS',
	NftId = 'NFT_ID',
	NftBalance = 'NFT_BALANCE',
	NftChainId = 'NFT_CHAIN_ID',
	NftName = 'NFT_NAME',
	NftSymbol = 'NFT_SYMBOL',
	NativeBalance = 'NATIVE_BALANCE',
	NativeChainId = 'NATIVE_CHAIN_ID',
	TeleGramGroupLink = 'TELEGRAM_GROUP_LINK',
	TeleGramGroupName = 'TELEGRAM_GROUP_NAME',
	TaskTitle = 'TASK_TITLE',
	WebsiteUrl = 'WEBSITE_URL',
	TwitterNumFollowers = 'TWITTER_NUM_FOLLOWERS',
	Email = 'EMAIL',
	EmailVerificationCode = 'EMAIL_VERIFICATION_CODE',
	Question = 'QUESTION',
	IsTaskTitle = 'IS_TASK_TITLE',
	Answer = 'ANSWER',
	CampaignId = 'CAMPAIGN_ID',
	RightAnswer = 'RIGHT_ANSWER',
	MCQOptions = 'MCQ_OPTIONS',
	MaxRetries = 'MAX_RETRIES',
	IsXPToBeReduced = 'IS_XP_TO_BE_REDUCED',
	TweetMentions = 'TWEET_MENTIONS',
	TweetHashTags = 'TWEET_HASH_TAGS',
	SmartContractAddress = 'SMART_CONTRACT_ADDRESS',
	SmartContractName = 'SMART_CONTRACT_NAME',
	SmartContractChainId = 'SMART_CONTRACT_CHAIN_ID',
	SmartContractEventSignature = 'SMART_CONTRACT_EVENT_SIGNATURE',
	SmartContractTopic0 = 'SMART_CONTRACT_TOPIC_0',
	SmartContractEventName = 'SMART_CONTRACT_EVENT_NAME',
	TransactionHash = 'TRANSACTION_HASH',
	PlenaInrAmount = 'PLENA_INR_AMOUNT',
	CaptchaValue = 'CAPTCHA_VALUE',
	FileType = 'FILE_TYPE',
	// ApiIsAuthHeaderRequired = 'API_IS_AUTH_HEADER_REQUIRED',
	// ApiAuthHeaderKey = 'API_AUTH_HEADER_KEY',
	// ApiAuthHeaderValue = 'API_AUTH_HEADER_VALUE',
	ApiRequestId = 'API_REQUEST_ID',
	// ApiDataValues = 'API_DATA_VALUES',
	ApiRequestOptions = 'API_REQUEST_OPTIONS',
	NomisScore = 'NOMIS_SCORE',
	GraphApiRequestId = 'GRAPH_API_REQUEST_ID',
	LineaBridgeAmount = 'LINEA_BRIDGE_AMOUNT',
	LineaLiquidityAmount = 'LINEA_LIQUIDITY_AMOUNT',
	LineaTradingAmount = 'LINEA_TRADING_AMOUNT',
	LineaLiquidityProjectType = 'LINEA_LIQUIDITY_PROJECT_TYPE',
	LineaTradeType = 'LINEA_TRADE_TYPE',
	LineaSwapAmount = 'LINEA_SWAP_AMOUNT',
	LineaLendAmount = 'LINEA_LEND_AMOUNT',
	LineaRepayAmount = 'LINEA_REPAY_AMOUNT',
	LineaBorrowAmount = 'LINEA_BORROW_AMOUNT',
	lineaProjectId = 'LINEA_PROJECT_ID',
	MetamaskBridgeAmount = 'METAMASK_BRIDGE_AMOUNT',
	MetamaskSwapAmount = 'METAMASK_SWAP_AMOUNT',
	LineaSwapVolume = 'LINEA_SWAP_VOLUME',
	DappsheriffSlug = 'DAPPSHERIFF_SLUG',

	IsComplexLending = 'IS_COMPLEX_LENDING',
	LineaTradingLogicKey = 'LINEA_TRADING_LOGIC_KEY',
	SwapAmountUSD = 'SWAP_AMOUNT_USD',

	NftAddressArr = 'NFT_ADDRESS_ARR',
	NftTokenIdArr = 'NFT_TOKEN_ID_ARR',
	NftChainIdArr = 'NFT_CHAIN_ID_ARR',
	MinNftHolding = 'MIN_NFT_HOLDING',

	MultipleCampaignIds = 'MULTIPLE_CAMPAIGN_IDS',
	MinCampaignsToComplete = 'MIN_CAMPAIGNS_TO_COMPLETE',
	LpAmountUsd = 'LP_AMOUNT_USD',
	Amount = 'AMOUNT',
	AmountUSD = 'AMOUNT_USD',
	MinXpsToEarn = 'MIN_XPS_TO_EARN',
	MinGmStrearksToEarn = 'MIN_GM_STREAKS_TO_EARN',

	QueryId = 'QUERY_ID',
	FromChainId = 'FROM_CHAIN_ID',
	ToChainId = 'TO_CHAIN_ID',
	VerificationMarginPercentage = 'VERIFICATION_MARGIN_PERCENTAGE',
	QuesterWalletAddress = 'QUESTER_WALLET_ADDRESS',

	Erc1155TokenId = 'ERC1155_TOKEN_ID',

	HumanityScore = 'HUMANITY_SCORE',
	IntractMedallions = 'INTRACT_MEDALLIONS',
	NameSpaceTag = 'NAMESPACE_TAG',
	Chain = 'CHAIN',

	NameSpaceTagArr = 'NAMESPACE_TAG_ARR',
	ChainArr = 'CHAIN_ARR',
}

export enum CallbackParamsSource {
	AdminInputField = 'ADMIN_INPUT_FIELD',
	QuestUserInputField = 'QUEST_USER_INPUT_FIELD',
	QuestUserDbField = 'QUEST_USER_DB_FIELD',
	CampaignDbField = 'CAMPAIGN_DB_FIELD',
	EnterpriseDbField = 'ENTERPRISE_DB_FIELD',
	ClientVerificationObject = 'CLIENT_VERIFICATION_OBJECT',
	TaskVerificationObject = 'TASK_VERIFICATION_OBJECT',
}

export enum TemplateFamily {
	None = 'NONE',
	Discord = 'DISCORD',
	Twitter = 'TWITTER',
	Wallet = 'WALLET',
	Telegram = 'TELEGRAM',
	Others = 'OTHERS',
	Forms = 'FORMS',
	Email = 'EMAIL',
	Custom = 'CUSTOM',
	POH = 'PROOF-OF-HUMANITY',
	MostFrequent = 'MOST_FREQUENT',
	GitHub = 'GITHUB',
}

export enum AdminInputType {
	InputString = 'INPUT_STRING',
	InputMap = 'INPUT_MAP',
	InputStringArray = 'INPUT_STRING_ARRAY',
	InputNumber = 'INPUT_NUMBER',
	InputDate = 'INPUT_DATE',
	InputTime = 'INPUT_TIME',
	Textarea = 'TEXTAREA',
	Select = 'SELECT',
	SelectNetwork = 'SELECT_NETWORK',
	SelectAsync = 'SELECT_ASYNC',
	InputSwitch = 'INPUT_SWITCH',
	InputAddress = 'INPUT_ADDRESS',
	InputCheckbox = 'INPUT_CHECKBOX',
	TwitterTags = 'TWITTER_TAGS',
	SelectFromAdminInput = 'SELECT_FROM_ADMIN_INPUT',
	InputMultiSelectAsync = 'MULTI_SELECT_ASYNC',
}

export const chainOptions = [
	{
		value: 1,
		label: 'Ethereum',
	},
	{
		value: 137,
		label: 'Polygon',
	},
	{
		value: 56,
		label: 'Binance Smart Chain',
	},
	{
		value: 43114,
		label: 'Avalanche',
	},
];

export const booleanOptions = [
	{
		value: 1,
		label: 'Yes',
	},
	{
		value: 0,
		label: 'No',
	},
];

export enum WalletProvider {
	Bitfinity = 'Bitfinity',
}

export enum RequiredLogins {
	Twitter = 'Twitter',
	Discord = 'Discord',
	Telegram = 'Telegram',
	EVMWallet = 'EVMWallet',
	Bitfinity = 'Bitfinity',
	Injective = 'Injective',
	PlenaWalletConnect = 'PlenaWalletConnect',
}

export enum AmountType {
	SameAmount = 'SAME_AMOUNT',
	SeparateAmount = 'SEPARATE_AMOUNT',
}
export enum RewardChain {
	Polygon = 137,
	Mumbai = 80001,
	Arbitrum = 42161,
	Binance = 56,
	Mode = 34443,
	Blast = 81457,
}

export enum CampaignRewardCategory {
	Token = 'TOKEN',
	Nft = 'NFT',
	DiscordRole = 'DISCORD_ROLE',
	Whitelist = 'WHITELIST',
	SecretCode = 'SECRET_CODE',
	OfficialPoints = 'OFFICIAL_POINTS',
}
export enum CampaignRewardClaimStatus {
	Unclaimed = 'UNCLAIMED',
	Initiated = 'INITIATED',
	Claimed = 'CLAIMED',
}

export enum LeaderboardRankColumn {
	Xp = 'XP',
	TotalTransactions = 'TOTAL_TRANSACTIONS',
	TotalValueSpend = 'TOTAL_VALUE_SPEND',
}

export enum CreateCampaignSections {
	Details = 'DETAILS',
	Tasks = 'TASKS',
	Rewards = 'REWARDS',
	Eligibility = 'ELIGIBILITY',
}

export enum SectionDisplayType {
	Social = 'SOCIAL',
	OnChain = 'ONCHAIN',
	Recurring = 'RECURRING',
}

export enum DisplayCategories {
	Task = 'TASK',
	TaskGroup = 'TASK_GROUP',
}

export enum CompletionCondition {
	And = 'AND',
	Or = 'OR',
}

export enum ReportTabs {
	Overview = 'overview',
	Leaderboard = 'leaderboard',
	Analytics = 'analytics',
	Channels = 'channels',
	Winners = 'winners',
	Submissions = 'submissions',
}

export enum POWVerifyStatus {
	Initiated = 'INITIATED',
	Verified = 'VERIFIED',
	Failed = 'FAILED',
}

export enum ContractType {
	Erc721 = 'ERC721',
	Erc1155 = 'ERC1155',
}

export enum ReferralRewardType {
	Fixed = 'FIXED',
	Flexible = 'FLEXIBLE',
}

export const simpleTaskTemplates = [
	TemplateType.TwitterTweet,
	TemplateType.TwitterTweetHashTags,
	TemplateType.TwitterTweetMentions,
	TemplateType.TwitterLike,
	TemplateType.TwitterRetweet,
	TemplateType.TwitterQuoteTweet,
	TemplateType.TwitterReply,
	TemplateType.TwitterReplyTagFriends,
	TemplateType.TwitterFollow,
	TemplateType.TwitterVerified,
	TemplateType.TelegramJoin,
	TemplateType.TelegramJoinWithoutVerification,
	TemplateType.WebsiteVisit,
	TemplateType.VerifyEmail,
	TemplateType.VerifyEmailWithoutVerification,
	TemplateType.OpenEndedQuestion,
	TemplateType.AnswerQuestion,
	TemplateType.Quiz,
	TemplateType.MultipleChoiceFeedback,
	TemplateType.OpenEndedQuiz,
];

export enum IdentityNamespaceTag {
	EvmEvm = 'EVM::EVM',
	StarknetStarknet = 'STARKNET::STARKNET',
	SolanaSolana = 'SOLANA::SOLANA',
	CosmosCosmosHub = 'COSMOS::COSMOS_HUB',
	SuiSui = 'SUI::SUI',
	AptosAptos = 'APTOS::APTOS',
	TonTon = 'TON::TON',
}
export enum EcosystemFamily {
	Evm = 'EVM',
	Starknet = 'STARKNET',
	Solana = 'SOLANA',
	Cosmos = 'COSMOS',
	Sui = 'SUI',
	Aptos = 'APTOS',
	Ton = 'TON',
}
export enum EcosystemChain {
	Evm = 'EVM',
	Starknet = 'STARKNET',
	Solana = 'SOLANA',
	CosmosHub = 'COSMOS_HUB',
	Sui = 'SUI',
	Aptos = 'APTOS',
	Ton = 'TON',
}
