import StatusBadge from '@/components/element/badges/StatusBadge';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { Media } from '@/components/element/media/Media';
import { asset } from '@/config/asset-cdn';
import { convertToTitleCase } from '@/utils/parsers';
import { ColumnDef } from '@tanstack/react-table';
import { IAdminCredits } from '../../types/admin-credit.types';
import {
	CreditRequestStatusEnum,
	CreditRequestValueEnum,
} from '@/features/intract-credits/types/credits.enums';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

export const AdminCreditColumns = (options: any): ColumnDef<IAdminCredits>[] => {
	const cols: ColumnDef<IAdminCredits>[] = [
		{
			accessorKey: 'enterpriseName',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Project Name" />
			),
			cell: ({ row }) => (
				<div className="flex ">
					<Avatar className="size-6 me-2 border-[0.5px] rounded-full">
						<AvatarImage
							src={row?.original?.enterpriseDetails?.logo}
							alt={row?.original?.enterpriseName}
						/>
						<AvatarFallback name={row?.original?.enterpriseName} />
					</Avatar>
					<TooltipWrapper
						tooltip={row?.original?.enterpriseName}
						align="start"
					>
						<div className="flex w-[8rem] truncate">
							{row?.original?.enterpriseName}
						</div>
					</TooltipWrapper>
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'campaignName',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Quest" />
			),
			cell: ({ row }) => {
				const details = row.original?.details;
				const campaignName = details?.find(
					(detail) => detail.key === CreditRequestValueEnum.questTitle,
				)?.value;
				return (
					<TooltipWrapper tooltip={campaignName ?? ''} align="start">
						<div className="flex items-center w-[12rem] truncate">
							{campaignName ?? 'N/A'}
						</div>
					</TooltipWrapper>
				);
			},
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'requestType',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Requested Benefit" />
			),
			cell: ({ row }) => (
				<div className="flex items-center">
					{convertToTitleCase(row.getValue('requestType'))}
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Approval Status" />
			),
			cell: ({ row }) => <StatusBadge status={row.getValue('status')} />,
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'Price',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Price" />
			),
			cell: ({ row }) => {
				const status = row.getValue('status');

				const credits =
					status === CreditRequestStatusEnum.Pending
						? row.original?.lockedCredits
						: row.original?.consumedCredits ?? null;

				return credits !== null && credits !== undefined ? (
					<div className="flex items-center">
						<Media
							src={asset['credit-icon-primary']}
							altText="credit-icon"
							className="size-[16px] me-1"
						/>
						{credits as number}&nbsp;Credits
					</div>
				) : (
					<span className="">N/A</span>
				);
			},
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'creditsBalance',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Credits Balance" />
			),
			cell: ({ row }) => {
				const creditsBalance =
					row.original?.enterpriseDetails?.creditsBalance;

				return (
					<div className="flex items-center">
						<Media
							src={asset['credit-icon-primary']}
							altText="credit-icon"
							className="size-[16px] me-1"
						/>
						{creditsBalance}&nbsp;Credits
					</div>
				);
			},
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'userEmail',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="User Email" />
			),
			cell: ({ row }) => (
				<TooltipWrapper tooltip={row?.original?.userEmail ?? ''} align="end">
					<div className="flex items-center max-w-[10rem] truncate">
						{row.original?.userEmail}
					</div>
				</TooltipWrapper>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => <DataTableRowActions row={row} options={options} />,
		},
	];
	return cols;
};
