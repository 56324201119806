import { cn } from '@/lib/utils';

const IntroducingSpeedrun = () => {
	return (
		<div>
			<div className="flex flex-col items-center justify-center w-full">
				<div className="h-12 w-12 border rounded-lg flex items-center justify-center mb-2 bg-gray-100">
					<i className="bi bi-speedometer2 text-2xl"></i>
				</div>
				<div className="text-lg font-medium">Introducing Speedrun</div>
				<div className="text-sm text-center">
					Speedrun maximises user engagement by encouraging them to
					complete quests quickly through gamified rewards, enhancing your
					brand’s visibility and participation
				</div>
				<div className="bg-[#f2f7f5] rounded-lg p-4 w-full mt-3 flex items-center justify-center">
					<img
						src="https://static.highongrowth.xyz/enterprise/64638464f5e380ef566bca30/a4a2839681184840b64f864e04af28df.png"
						alt="Speedrun"
						className="h-48 rounded"
					/>
				</div>
				<div className="">
					<div className={cn()}>
						<div className="mt-6 font-medium">How it works</div>
						<div className="mt-2 text-sm grid grid-cols-9 ">
							{howItWorks.map((i, index) => (
								<>
									<div
										className="px-3 py-2 bg-slate-100 border rounded-md mb-2 flex col-span-3 me-2 relative"
										key={i.title}
									>
										<div>
											<div className="text-sm text-center">
												{i.title}
											</div>
										</div>
										{index !== 2 && (
											<div
												className="absolute top-1/2 -translate-y-1/2 z-10"
												style={{ right: '-12px' }}
											>
												<i className="bi-chevron-right bg-white text-xs rounded-full h-5 w-5 flex items-center justify-center border"></i>
											</div>
										)}
									</div>
								</>
							))}
						</div>

						<div className="mt-6 mb-4 italic  text-sm">
							We have achieved
							<span className="font-medium mx-1 px-1 bg-yellow-100 rounded">
								CAC of $0.08/user
							</span>
							across Speedrun events conducted till date
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default IntroducingSpeedrun;

const howItWorks = [
	{
		title: '🔘 User starts the speedrun to activate their participation',
	},
	{
		title: '⌛️ They finish curated quests within 1 hour',
	},
	{
		title: '🎁 User gets instant speedrun & quest rewards',
	},
];
