import { useQuery } from '@tanstack/react-query';
import {
	getDaveSupportedChains,
	getDaveSupportedTokens,
} from '../services/dave.service';
import { IDaveSupportedChains } from '../types/dave.types';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import { useMemo } from 'react';
import { IdentityNamespaceTag } from '@/features/campaigns/types';

export const useDaveUtility = (
	chain?: string,
	namespaceTag?: IdentityNamespaceTag,
) => {
	const { chains, isLoading } = useGetSupportedChains({
		showAdditionalChains: false,
	});

	const { data: tokens, isLoading: isTokensLoading } = useQuery({
		queryFn: () => getDaveSupportedTokens(chain, namespaceTag),
		queryKey: ['dave', 'supported-tokens', chain, namespaceTag],
		enabled: !!chain && !!namespaceTag,
	});

	const supportedChains = useMemo(() => {
		if (!chains) return [];
		else
			return chains.filter((c) => {
				return c.isNoCodeAvailable;
			});
	}, [chains]);

	return {
		supportedChains: supportedChains,
		supportedTokens: tokens,
		isChainsLoading: isLoading,
		isTokensLoading,
	};
};
