import { useQuery } from '@tanstack/react-query';
import { createContext, useEffect, useRef, useState } from 'react';
import {
	getEnterprise,
	updateEnterprise,
	updateEnterpriseSocials,
	uploadFile,
} from '../services/community.service';
import { toast } from 'sonner';
import { useAuth } from '@/hooks/useAuth';
import { queryClient } from '@/lib/react-query';
import { validateCompleteURL } from '@/utils/parsers';
import { IdentityNamespaceTag } from '@/features/campaigns/types';

export interface ICommunityHub {
	name: string;
	description: string;
	logoUrl: string;
	logo: File | string;
	bannerUrl: string;
	banner: File;
	bannerPosition: any;
	socials: {
		key: string;
		value: string;
		placeholder?: string;
	}[];
	// chainIds: number[];
	enterpriseSlug: string;
	category: string[];
	otherCategory: string;
	// customChainIds: number[];
	customEcosystems: {
		chainId: string;
		namespaceTag: string;
		chain: string;
	}[];
	availableEcosystems: {
		chainId: string;
		namespaceTag: string;
		chain: string;
	}[];
}

const defaultValue = {
	name: '',
	description: '',
	logoUrl: '',
	logo: null,
	bannerUrl: '',
	banner: null,
	bannerPosition: { x: 0, y: 0 },
	socials: [],
	// chainIds: [],
	resetForm: () => {},
	enterpriseSlug: '',
	category: [],
	otherCategory: '',
	// customChainIds: [],
	customEcosystems: [],
	availableEcosystems: [],
};

interface CommunityHubState {
	formFields: ICommunityHub;
	setFormFields: any;
	formErrors: any;
	setFormErrors: any;
	resetForm: any;
	bannerRef: any;
	logoRef: any;
	updateCommunityProfile: any;
	tab: string;
	setTab: any;
	loading?: boolean;
	setLoading?: any;
	updateCommunitySocials: any;
	isSubmitting?: boolean;
	setIsSubmitting?: any;
	updateLogo: any;
	setCustomChainByDefault: any;
}

const initialState: CommunityHubState = {
	formFields: defaultValue,
	setFormFields: () => {},
	formErrors: {},
	setFormErrors: () => {},
	resetForm: () => {},
	bannerRef: null,
	logoRef: null,
	updateCommunityProfile: () => {},
	tab: 'overview',
	setTab: () => {},
	loading: false,
	setLoading: () => {},
	updateCommunitySocials: () => {},
	isSubmitting: false,
	setIsSubmitting: () => {},
	updateLogo: () => {},
	setCustomChainByDefault: () => {},
};

export const CommunityHubContext = createContext<CommunityHubState>(initialState);

export const CommunityHubProvider = ({ children }) => {
	const [formFields, setFormFields] = useState<ICommunityHub>(defaultValue);
	const [formErrors, setFormErrors] = useState<any>({});
	const [tab, setTab] = useState('overview');
	const [loading, setLoading] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const bannerRef = useRef<HTMLInputElement>();
	const logoRef = useRef<HTMLInputElement>();

	const { user } = useAuth();

	const resetForm = () => {
		setFormFields(defaultValue);
		setFormErrors({});
	};

	const updateLogo = async () => {
		setFormFields({
			...formFields,
			logo: URL.createObjectURL(logoRef.current.files[0]),
		});
	};

	const enterpriseQuery = useQuery({
		queryKey: ['enterprise'],
		queryFn: () => getEnterprise(),
	});

	const verifyFormFields = () => {
		const error: any = {};
		const combinedChains = [
			...formFields.availableEcosystems,
			...formFields.customEcosystems,
		];
		if (!formFields?.name?.trim()) {
			error.name = 'Name is required';
		}
		if (!combinedChains?.length) {
			error.chainId = 'Chain is required';
		}

		setFormErrors(error);
		return Object.keys(error).length === 0;
	};

	const updateCommunityProfile = async () => {
		if (verifyFormFields()) {
			setLoading(true);
			if (logoRef.current?.files?.length) {
				const uploadedLogo = await uploadFile(logoRef.current.files[0]);
				formFields.logoUrl = uploadedLogo;
			}

			if (formFields?.banner) {
				const uploadedBanner = await uploadFile(formFields.banner);
				formFields.bannerUrl = uploadedBanner;
			}

			try {
				await updateEnterprise({
					name: formFields.name,
					description: formFields.description || '',
					logo: formFields.logoUrl,
					banner: formFields.bannerUrl,
					bannerPosition: formFields.bannerPosition,
					category: formFields.category,
					otherCategory: formFields.otherCategory,
					customEcosystems: formFields.customEcosystems,
					availableEcosystems: formFields.availableEcosystems,
				});
				queryClient.invalidateQueries({
					queryKey: ['enterprise'],
				});
				queryClient.invalidateQueries({
					queryKey: ['user'],
				});
				toast.success('Community Profile updated successfully');
			} catch (err) {
				console.error(err);
				toast.error(
					err?.response?.data?.message ||
						'Failed to update community profile',
				);
			} finally {
				setLoading(false);
			}
		}

		queryClient.invalidateQueries({
			queryKey: ['enterprise'],
		});
	};

	const updateCommunitySocials = async () => {
		const error: any = {};
		if (!formFields?.socials?.length || formFields?.socials?.length < 2) {
			error.socials = 'Connect at least 2 socials to verify your community.';
		}
		const linkValidationRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/\S*)?$/;

		if (formFields?.socials?.length) {
			for (const social of formFields.socials) {
				if (
					social.key === 'website' &&
					!linkValidationRegex.test(social.value)
				) {
					error[social.key] = `Invalid link for ${social.key}`;
				}
			}
		}
		setFormErrors(error);
		if (Object.keys(error).length === 0) {
			setIsSubmitting(true);
			const data = formFields.socials.reduce((acc, curr) => {
				if (curr.key === 'twitter') {
					if (curr.value.startsWith('@')) {
						acc[curr.key] =
							'https://twitter.com/' + curr.value.substring(1);
					} else if (
						curr.value.includes('x.com') ||
						curr.value.includes('twitter.com')
					) {
						acc[curr.key] =
							'https://twitter.com/' +
							curr.value.substring(curr.value.lastIndexOf('/') + 1);
					} else {
						acc[curr.key] = 'https://twitter.com/' + curr.value;
					}
				} else {
					acc[curr.key] = curr.value;
				}
				return acc;
			}, {});

			const correctedURLs = Object.keys(data).reduce((acc, key) => {
				acc[key] = validateCompleteURL(data[key]);
				return acc;
			}, {});

			const correctSocials = Object.entries(correctedURLs).map(
				([key, value]) => ({
					key: key as string,
					value: value as string,
					placeholder: '',
				}),
			);

			setFormFields((prev) => ({
				...prev,
				socials: correctSocials,
			}));

			try {
				const response = await updateEnterpriseSocials({
					socials: correctedURLs,
				});
				toast.success('Community Socials updated successfully');
				setIsSubmitting(false);
				setFormErrors({});
			} catch (err) {
				console.error(err);
				setFormErrors({
					...formErrors,
					socials: err?.response?.data?.message,
				});
				setIsSubmitting(false);
			}
		}
	};

	const setCustomChainByDefault = (
		chain: string,
		namespaceTag: IdentityNamespaceTag,
		chainId: string,
	) => {
		setFormFields((prev) => {
			return {
				...prev,
				customEcosystems: [
					...prev.customEcosystems,
					{
						namespaceTag: namespaceTag,
						chain: chain,
						chainId: chainId,
					},
				],
			};
		});
	};

	useEffect(() => {
		if (enterpriseQuery?.data) {
			const data = enterpriseQuery?.data;
			const tempSocials = [];

			if (data?.socials) {
				for (const [key, value] of Object.entries(data?.socials)) {
					tempSocials.push({
						key: key,
						value: value,
					});
				}
			}

			setFormFields((prev) => ({
				...prev,
				name: data?.name,
				enterpriseSlug: data?.slug,
				description: data?.description,
				logoUrl: data?.logo,
				bannerUrl: data?.banner,
				socials: tempSocials,
				bannerPosition: data?.bannerPosition,
				category: data?.metadata?.category || [],
				otherCategory: data?.otherCategory || '',
				availableEcosystems: data?.availableEcosystems || [],
				customEcosystems: data?.customEcosystems || [],
			}));
		}
	}, [enterpriseQuery?.data]);

	useEffect(() => {
		if (user?.primaryChainId) {
			setFormFields((prev) => ({
				...prev,
				chainIds: [user.primaryChainId],
			}));
		}
	}, [user]);

	// useEffect(() => {
	// 	if (!enterpriseQuery?.data?.chainId) {
	// 		enterpriseQuery.refetch();
	// 	}
	// }, []);

	return (
		<CommunityHubContext.Provider
			value={{
				formErrors,
				formFields,
				setFormErrors,
				setFormFields,
				resetForm,
				bannerRef,
				logoRef,
				updateCommunityProfile,
				tab,
				setTab,
				loading: loading || enterpriseQuery?.isLoading,
				updateCommunitySocials,
				isSubmitting,
				updateLogo,
				setCustomChainByDefault,
			}}
		>
			{children}
		</CommunityHubContext.Provider>
	);
};
