import { Dispatch, SetStateAction, createContext, useState } from 'react';
import {
	EventsCreateEnum,
	IEventDetails,
	IEventSections,
} from '../types/events.type';
import moment from 'moment';
import { usePrompt } from '@/hooks/usePrompt';
import { useParams } from 'react-router-dom';
import { useCreateEventInitialise } from '../hooks/create/useCreateEventInitialise';
import { IdentityNamespaceTag } from '@/features/campaigns/types';

interface EventCreateProviderState {
	section: EventsCreateEnum;
	details: IEventDetails;
	sections: IEventSections[];
	setSection: Dispatch<SetStateAction<EventsCreateEnum>>;
	setDetails: Dispatch<SetStateAction<IEventDetails>>;
	setSections: Dispatch<SetStateAction<IEventSections[]>>;
	setIsDirty: Dispatch<SetStateAction<boolean>>;
	errors: {
		details: {
			rewardDescription: boolean;
			title: boolean;
			startDate: boolean;
			endDate: boolean;
			duration: boolean;
			banner: boolean;
			description: boolean;
		};
		campaigns: boolean;
		sections: {
			details: boolean;
			campaigns: boolean;
		};
	};
	setErrors: Dispatch<SetStateAction<any>>;
	contextualSuggestions: {
		id: string;
		element: string | JSX.Element | Element;
	};
	setContextualSuggestions: Dispatch<
		SetStateAction<{
			element: string | JSX.Element | Element;
			id: string;
		}>
	>;
	isInitalising: boolean;
	isUpdate: boolean;
	id: string;
	moveEventSection: (sectionId: string, direction: 'up' | 'down') => void;
}

const defaultDetails = {
	title: '',
	description: '',
	startDate: moment().toDate(),
	endDate: moment().add(6, 'days').toDate(),
	bannerFile: null,
	bannerFileLink: '',
	network: '8453',
	chain: 'base',
	namespaceTag: IdentityNamespaceTag.EvmEvm,
	startImmediately: true,
	timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	noDeadline: true,
	bannerPosition: {
		x: 0,
		y: 0,
	},
	reward: {
		title: '',
		description: '',
	},
	projectsIncluded: [],
};

const initialState: EventCreateProviderState = {
	section: EventsCreateEnum.Details,
	details: defaultDetails,
	sections: [],
	setSection: () => {},
	setDetails: () => {},
	setSections: () => {},
	setIsDirty: () => {},
	errors: {
		details: {
			title: false,
			startDate: false,
			endDate: false,
			duration: false,
			banner: false,
			description: false,
			rewardDescription: false,
		},
		campaigns: false,
		sections: {
			details: false,
			campaigns: false,
		},
	},
	setErrors: () => {},
	contextualSuggestions: {
		id: '',
		element: '',
	},
	setContextualSuggestions: () => {},
	isInitalising: true,
	isUpdate: false,
	id: '',
	moveEventSection: () => {},
};

export const EventCreateContext =
	createContext<EventCreateProviderState>(initialState);

export function EventCreateProvider({
	children,
	section,
	setSection,
}: {
	children: JSX.Element;
	section: EventsCreateEnum;
	setSection: Dispatch<SetStateAction<EventsCreateEnum>>;
}) {
	const { id } = useParams();
	const [isDirty, setIsDirty] = useState(false);
	const [details, setDetails] = useState<IEventDetails>(defaultDetails);
	const [sections, setSections] = useState<IEventSections[]>([]);
	const [isInitalising, setIsInitalising] = useState(true);
	const [isUpdate, setIsUpdate] = useState(false);
	const [errors, setErrors] = useState({
		details: {
			title: false,
			startDate: false,
			endDate: false,
			duration: false,
			banner: false,
			description: false,
			rewardDescription: false,
		},
		campaigns: false,
		sections: {
			details: false,
			campaigns: false,
		},
	});
	const [contextualSuggestions, setContextualSuggestions] = useState({
		element: '',
		id: '',
	});

	usePrompt({ isDirty });
	useCreateEventInitialise({
		setDetails,
		setSections,
		setIsInitalising,
		setIsUpdate,
	});

	const moveEventSection = (sectionId: string, direction: 'up' | 'down') => {
		const sectionIndex = sections.findIndex(
			(section) => section._id === sectionId,
		);
		const totalSections = sections.length;

		const newArrangedSections = [...sections];
		if (sectionIndex === 0 && direction === 'up') return;
		if (sectionIndex === totalSections - 1 && direction === 'down') return;

		if (direction === 'up') {
			const temp = newArrangedSections[sectionIndex];
			newArrangedSections[sectionIndex] =
				newArrangedSections[sectionIndex - 1];
			newArrangedSections[sectionIndex - 1] = temp;
		} else {
			const temp = newArrangedSections[sectionIndex];
			newArrangedSections[sectionIndex] =
				newArrangedSections[sectionIndex + 1];
			newArrangedSections[sectionIndex + 1] = temp;
		}

		setSections(newArrangedSections);
	};

	return (
		<EventCreateContext.Provider
			value={{
				section,
				setSection,
				details,
				setDetails,
				sections,
				setSections,
				setIsDirty,
				errors,
				setErrors,
				id,
				isInitalising,
				isUpdate,
				contextualSuggestions,
				setContextualSuggestions,
				moveEventSection,
			}}
		>
			{children}
		</EventCreateContext.Provider>
	);
}
