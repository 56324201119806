import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { saveDraft } from '../services/task-apis.service';
import { ApiCreateSectionEnum, CreateTaskAPISteps } from '../types/task-apis.enum';
import { IAPIFormFields } from '../types/task-apis.type';
import { useConnectAPI } from './useConnectAPI';
import { useRouter } from '@/hooks/useRouter';

export const useCreateTaskApiNavigation = () => {
	const {
		section,
		setSection,
		setStep,
		setFormFields,
		setEvaluatedFields,
		setEvaluationErrors,
		setIsDirty,
		formFields,
		completedSteps,
		setCompletedSteps,
	} = useConnectAPI();
	const { navigate } = useRouter();

	const moveForward = () => {
		setIsDirty(true);
		if (section === ApiCreateSectionEnum.Details) {
			setSection(ApiCreateSectionEnum.Verify);
			return;
		}
	};

	const moveBack = () => {
		setIsDirty(true);

		if (section === ApiCreateSectionEnum.Verify) {
			setSection(ApiCreateSectionEnum.Details);
		}
		setStep(CreateTaskAPISteps.Payload);
		setFormFields((prev) => ({
			...prev,
			isApiSchemaValid: false,
			isApiTestDone: false,
		}));
		const updatedCompletedSteps = completedSteps.filter(
			(key, i) => key !== CreateTaskAPISteps.Testing,
		);
		setCompletedSteps(updatedCompletedSteps);
		setEvaluationErrors({});
		setEvaluatedFields({});
	};

	const saveMutation = useMutation({
		mutationFn: saveDraft,
		onSuccess: async (data) => {
			setIsDirty(false);
			toast.success('Draft saved successfully');
			navigate(`/app/tools/task-apis/${data?._id}/update`);
			setSection(ApiCreateSectionEnum.Details);
			await queryClient.invalidateQueries({
				queryKey: ['api-requests'],
			});
		},
		onError: () => {
			handleErrorMessage('Failed to save draft');
		},
	});

	const formatPayload = (
		payload: IAPIFormFields['apiPayload'],
		constantDataFields: IAPIFormFields['apiConstantDataFields'],
	) => {
		const constantKeys = new Set(constantDataFields.map((item) => item.key));
		const tempPayload = {};
		payload.forEach((item) => {
			if (!constantKeys.has(item.key)) {
				tempPayload[item.key] = item.value;
			}
		});

		return tempPayload;
	};
	const formatCustomPayload = (
		payload: IAPIFormFields['apiConstantDataFields'],
	) => {
		const tempPayload = {};
		payload.forEach((item) => {
			tempPayload[item.key] = item.value;
		});

		return tempPayload;
	};
	const formatCustomHeaders = (headers: IAPIFormFields['apiHeaders']) => {
		const tempHeaders: any = {};
		headers.forEach((item: any) => {
			tempHeaders[item.key] = item?.value?.trim();
		});

		return tempHeaders;
	};

	const handleSaveDraft = async () => {
		await saveMutation.mutateAsync({
			_id: formFields._id || null,
			apiName: formFields?.apiName?.trim() || 'Untitled API',
			apiEndpoint: formFields.apiEndpoint,
			apiMethod: formFields.apiMethod?.toUpperCase(),
			apiDataPassingMethod: formFields.apiPayloadMethod?.toUpperCase(),
			apiHeaders: formFields?.isHeaderRequired
				? formatCustomHeaders(formFields.apiHeaders)
				: {},
			apiDataFields: formatPayload(
				formFields.apiPayload,
				formFields.apiConstantDataFields,
			),
			apiVerificationFor: formFields.apiVerificationFor,
			isRecurringTaskEnabled: formFields.isRecurringTasksEnabled,
			isMetricBasedTaskEnabled: formFields.isMetricBasedTaskEnabled,
			apiCredentialsType: formFields.apiCredentialsType,
			apiOutputExpressions: formFields.apiOutputExpressions,
			apiConstantDataFields: formatCustomPayload(
				formFields.apiConstantDataFields,
			),
			graphQlQuery: formFields.graphQlQuery,
		});
	};

	return {
		moveForward,
		moveBack,
		forwardLabel: 'Next',
		saveDraft,
		isSaveDraftLoading: saveMutation.isPending,
		handleSaveDraft,
	};
};
