import { axios } from '@/lib/axios';
import defaultAxios from 'axios';
import { QUEST_API_URL } from '@/config';
import {
	ITask,
	ITaskSection,
	IdentityNamespaceTag,
	POWVerifyStatus,
} from '../types';

export const getCampaigns = async () => {
	const res = await axios.get(`/campaigns`);
	return res.data;
};

export const getCampaign = async (id: any) => {
	const res = await axios.get(`/campaigns/${id}`);
	return res.data;
};

export const getCampaignTemplates = async () => {
	const res = await axios.get(`/campaigns/templates`);
	return res.data;
};

export const getCampaignTemplate = async (id: string) => {
	const res = await axios.get(`/campaigns/templates/${id}`);
	return res.data;
};

export const getTaskTemplates = async () => {
	const res = await axios.get(`/campaigns/task-templates`);
	return res.data?.filter((i) => i.templateType !== 'ContractInteraction');
};

export const createDraftCampaign = async (data: any) => {
	const res = await axios.post(`/campaigns/create`, data);
	return res.data;
};

export const updateDraftCampaign = async (id: string, data: any) => {
	const res = await axios.post(`/campaigns/${id}/save`, data);
	return res.data;
};

export const validateAdminInput = async (body: {
	key: string;
	value: any;
	templateFamily: string;
	templateType: string;
}) => {
	const res = await axios.post(
		`${QUEST_API_URL}/api/qv1/task/validate-task-input`,
		body,
	);
	return res.data;
};

export const updateCampaignMetadata = async (id: string, data: any) => {
	const res = await axios.put(`/campaigns/${id}/metadata`, data);
	return res.data;
};

export const restartCampaign = async (id: string, data: any) => {
	const res = await axios.put(`/campaigns/${id}/restart`, data);
	return res.data;
};

export const updateCampaignStatus = async (
	id: string,
	data: { newStatus: string; includeCaptcha?: boolean; includePOH?: boolean },
) => {
	const res = await axios.put(`/campaigns/${id}/update-status`, data);
	return res.data;
};

export const enrollCampaignInSpeedRunPreActive = async (
	id: string,
	data: {
		rewardId?: string;
		speedRunAmount?: number;
		questRewardPool?: number;
	},
) => {
	const res = await axios.put(`/campaigns/${id}/enroll-in-speedrun/pre`, data);
	return res.data;
};

export const enrollCampaignInSpeedRunPostActive = async (
	id: string,
	data: {
		tokenAddress: string;
		chain: string;
		namespaceTag: IdentityNamespaceTag;
		tokenAmount: number;
	},
) => {
	const res = await axios.put(`/campaigns/${id}/enroll-in-speedrun/post`, data);
	return res.data;
};

export const reorderTasks = async ({
	id,
	taskSections,
}: {
	id: string;
	taskSections: ITaskSection[];
}) => {
	const res = await axios.put(`/campaigns/${id}/rearrange-task`, { taskSections });
	return res.data;
};

//rewards
export const updateTokenRewardApprovalTxn = async (
	id: string,
	data: {
		adminAddress: string;
		rewardId: string;
		txHash: string;
		status: string;
		chainId: number;
		receipt: any;
		chain: string;
		namespaceTag: IdentityNamespaceTag;
	},
) => {
	const res = await axios.post(`/campaigns/${id}/approval-status-update`, {
		...data,
	});
	return res.data;
};

export const updateCampaignAdminAddress = async (
	id: string,
	data: {
		adminAddress: string;
	},
) => {
	const res = await axios.post(`/campaigns/${id}/admin-address`, data);
	return res.data;
};

export const getTokenRewardTransferSignature = async (id: string) => {
	const res = await axios.get(`/campaigns/${id}/reward-signature`);
	return res.data;
};

export const updateTokenRewardTransferTxn = async (
	id: string,
	data: {
		txHash: string;
		status: string;
		chainId: number;
		chain: string;
		namespaceTag: string;
		receipt: any;
	},
) => {
	const res = await axios.post(`/campaigns/${id}/reward-transaction-update`, {
		...data,
	});
	return res.data;
};

export const updateSpeedrunTransferTxn = async (
	id: string,
	data: {
		txHash: string;
		status: string;
		chainId: number;
		chain: string;
		namespaceTag: string;
		receipt: any;
	},
) => {
	const res = await axios.post(`/campaigns/${id}/speedrun-transaction-update`, {
		...data,
	});
	return res.data;
};

export const checkIfTokenRewardTxnAlreadyDone = async (id: string) => {
	const res = await axios.post(`/campaigns/${id}/check-launch-event`);
	return res.data;
};

export const getPoWTaskSubmissions = async ({
	campaignId,
	taskId,
	page,
	limit,
	sorting,
	statusFilters,
	cursor,
	search,
}: {
	campaignId: string;
	taskId: string;
	page: number;
	limit: number;
	sorting: any[];
	statusFilters: string[];
	cursor: string;
	search: string;
}) => {
	const { data } = await axios.post(
		`/campaigns/${campaignId}/get-pow-submissions`,
		{
			taskIds: [taskId],
			page,
			limit,
			sorting,
			status: statusFilters,
			cursor,
			search,
		},
	);

	return data;
};

export const getPoWTaskSubmissionsCount = async ({
	campaignId,
	taskId,
	statusFilters,
	search,
}: {
	campaignId: string;
	taskId: string;
	statusFilters: string[];
	search;
}) => {
	const { data } = await axios.post(
		`/campaigns/${campaignId}/get-pow-submissions/count`,
		{
			taskIds: [taskId],
			status: statusFilters,
			search,
		},
	);

	return data;
};

export const getCampaignWinners = async ({
	campaignId,
	currentPage,
	perPage,
}: {
	campaignId: string;
	currentPage: number;
	perPage: number;
}) => {
	const { data } = await axios.post(`/campaigns/${campaignId}/winners`, {
		page: currentPage,
		limit: perPage,
	});
	return data;
};

export const getCampaignWinnersCount = async ({
	campaignId,
	getTotalCount,
}: {
	campaignId: string;
	getTotalCount: boolean;
}) => {
	const { data } = await axios.post(`/campaigns/${campaignId}/winners/count`, {
		getTotalCount: getTotalCount,
	});
	return data;
};

export const deleteCampaign = async (id: string) => {
	const res = await axios.delete(`/campaigns/${id}`);
	return res.data;
};

export const updateTaskAdminInputs = async (body: any) => {
	const res = await axios.put(`/tasks/edit-task-metadata/`, body);
	return res.data;
};

export const getTotalSuperUsers = async () => {
	const res = await axios.get(`/enterprise/total-users`);
	return res.data;
};

export const updateCampaignsSettings = async (data: any) => {
	const res = await axios.put(`/campaigns/settings`, data);
	return res.data;
};

export const updateSubmissionStatus = async (
	id: string,
	data: {
		results: {
			submissionId: string;
			status: POWVerifyStatus;
			customXp?: number;
		}[];
		taskId: string;
	},
) => {
	const res = await axios.post(`/campaigns/${id}/set-pow-results`, data);
	return res.data;
};

export const bulkUpdateSubmissionStatus = async (
	id: string,
	data: {
		action: POWVerifyStatus;
		taskId: string;
	},
) => {
	const res = await axios.post(`/campaigns/${id}/bulk-pow-verify`, data);
	return res.data;
};

export const exportWinners = async (campaignId: string) => {
	const res = await axios.get(`/campaigns/${campaignId}/winners/export`);
	return res.data;
};

export const exportPoWSubmissions = async (campaignId: string) => {
	const res = await axios.get(
		`/campaigns/${campaignId}/get-pow-submissions/export`,
	);
	return res.data;
};

export const markCampaignTokenRewardWithdrawn = async (
	campaignId: string,
	data: any,
) => {
	const res = await axios.post(
		`/campaigns/${campaignId}/mark-withdrawn-token-rewards`,
		{
			withdrawalMetadata: data,
		},
	);
	return res.data;
};

export const findContractType = async (chainId: number, address: string) => {
	const res = await axios.get(
		`/campaigns/contractType?contractAddress=${address}&chainId=${chainId}`,
	);
	return res.data;
};

export const updateTaskSlides = async ({
	campaignId,
	taskId,
	slides,
}: {
	campaignId: string;
	taskId: string;
	slides: ITask['slides'];
}) => {
	const res = await axios.put(`/campaigns/${campaignId}/update-task`, {
		taskId,
		slides,
	});
	return res.data;
};

export const unscheduleCampaign = async ({
	id,
	data,
}: {
	id: string;
	data: { newStatus: string };
}) => {
	const res = await axios.put(`/campaigns/${id}/change-status`, data);
	return res.data;
};

export const fetchRewardDetails = async (id: string) => {
	const res = await axios.get(`/campaigns/${id}/rewards`);
	return res.data;
};
