import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
} from '@/components/ui/select';
import { ITokens } from '@/features/campaigns/hooks/tokens/useGetAllTokens';
import { cn } from '@/lib/utils';

const RewardTokenSelect = ({
	tokens,
	value,
	setValue,
	error,
	errorText,
}: {
	tokens: ITokens[];
	value: string;
	setValue: (address: string) => void;
	error?: boolean;
	errorText?: string;
}) => {
	const selected = tokens?.find((token) => token.address === value) ?? null;
	return (
		<>
			<Select value={value} onValueChange={setValue}>
				<SelectTrigger
					aria-label="Area"
					className={cn('text-sm bg-white', error && 'border-destructive')}
				>
					{selected ? (
						<div className=" w-full py-1">
							<div className="flex items-center ">
								<img
									src={selected?.logo}
									alt=""
									className="h-5 w-5 object-cover rounded-lg me-2"
								/>
								<span className="truncate">{selected.name}</span>
							</div>
						</div>
					) : (
						<div>Select your token</div>
					)}
				</SelectTrigger>
				<SelectContent>
					{tokens.map((coin, idx) => (
						<SelectItem
							key={`${coin.address}_${idx}`}
							value={coin.address}
						>
							<div className="flex flex-col  w-[240px] ">
								<div className="flex items-center ">
									<img
										src={coin.logo}
										alt=""
										className="h-6 me-2"
									/>
									<div>
										{coin.name}
										<div className="text-xs text-muted-foreground  mt-">
											({coin?.address?.slice(0, 8)}...
											{coin?.address?.slice(-5)})
										</div>
									</div>
								</div>
							</div>
						</SelectItem>
					))}
				</SelectContent>
			</Select>
			{error && errorText && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</>
	);
};

export default RewardTokenSelect;
