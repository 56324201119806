import { memo } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import AnimateHeight from 'react-animate-height';
import { cn } from '@/lib/utils';
import { CreateTaskAPISteps } from '../../types/task-apis.enum';

interface StepCardProps {
	stepKey: CreateTaskAPISteps;
	isActive: boolean;
	title: string;
	onStepClick: (key: string) => void;
	children: React.ReactNode;
	completedSteps: CreateTaskAPISteps[];
}

const StepCard = ({
	stepKey,
	isActive,
	title,
	onStepClick,
	children,
	completedSteps,
}: StepCardProps) => {
	return (
		<Card
			className={cn(
				'w-[700px] relative p-5',
				!isActive && 'cursor-pointer border border-slate-300',
				!completedSteps?.includes(stepKey)
					? 'cursor-default'
					: 'cursor-pointer',
			)}
			onClick={() => {
				if (!isActive) onStepClick(stepKey);
			}}
		>
			<CardContent className="px-0 py-0">
				<div className="text-slate-800 text-sm font-medium flex items-center w-full">
					{completedSteps?.includes(stepKey as CreateTaskAPISteps) ? (
						<i className="bi-check-circle-fill me-2 text-violet-500"></i>
					) : (
						<i className="bi-circle me-2"></i>
					)}
					<div className="flex items-center justify-between w-full">
						{title}
						<i
							className={cn(
								'bi-chevron-down ms-2 transition-transform',
								isActive ? 'rotate-180' : 'rotate-0',
							)}
						></i>
					</div>
				</div>
				<AnimateHeight height={isActive ? 'auto' : 0} duration={300}>
					<div className="mt-5">{children}</div>
				</AnimateHeight>
			</CardContent>
		</Card>
	);
};

const MemoizedStepCard = memo(StepCard);
export default MemoizedStepCard;
