import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import { useMemo } from 'react';
import { useConnectAPI } from '../hooks/useConnectAPI';
import useCreateTaskAPI from '../hooks/useCreateTaskAPI';
import { useCreateTaskApiNavigation } from '../hooks/useCreateTaskApiNavigation';
import {
	ApiCreateSectionEnum,
	CreateTaskAPISteps,
	EvaluationFields,
	TaskAPIStatus,
} from '../types/task-apis.enum';
import Spinner from '@/components/element/loading/Spinner';
import { convertToTitleCase } from '@/utils/parsers';

const TaskApiCreateNavigation = () => {
	const {
		section,
		step,
		evaluationErrors,
		formFields,
		evaluatedFields,
		isLoading,
		contextualSuggestions,
		setStep,
		animateWiggle,
	} = useConnectAPI();

	const { moveBack, moveForward, handleSaveDraft, isSaveDraftLoading } =
		useCreateTaskApiNavigation();
	const { handleClick, getButtonLabel, getDescription, prevStepMap } =
		useCreateTaskAPI();

	const isDisabled = useMemo(() => {
		if (step === CreateTaskAPISteps.EvaluateResponse) {
			const hasErrors = Object.values(evaluationErrors).some(
				(error) => !!error,
			);

			const requiredFields = [EvaluationFields.DataResult];
			if (formFields?.isMetricBasedTaskEnabled) {
				requiredFields.push(
					EvaluationFields.DataMetric,
					EvaluationFields.DataMetricDataType,
				);
			}

			const allRequiredFieldsEvaluated = requiredFields.every(
				(field) => evaluatedFields[field],
			);

			return (
				hasErrors ||
				!allRequiredFieldsEvaluated ||
				isLoading ||
				isSaveDraftLoading
			);
		}
		return isLoading || isSaveDraftLoading;
	}, [
		evaluationErrors,
		evaluatedFields,
		isLoading,
		step,
		formFields?.isMetricBasedTaskEnabled,
		isSaveDraftLoading,
	]);

	const showToolTip = useMemo(() => {
		return step === CreateTaskAPISteps.EvaluateResponse && isDisabled;
	}, [isDisabled, step]);

	const buttonLabel = useMemo(() => {
		return getButtonLabel;
	}, [step, formFields?.curlCommand]);

	const isSaveDraftDisabled = useMemo(() => {
		return (
			isLoading ||
			isSaveDraftLoading ||
			formFields?.status === TaskAPIStatus.UnderReview ||
			formFields?.status === TaskAPIStatus.Submitted
		);
	}, [isLoading, isSaveDraftLoading, formFields?.status]);

	return (
		<div className="flex justify-between items-center space-x-4 border-t p-3 pb-3 px-4">
			<div className="min-w-[200px]">
				{section !== ApiCreateSectionEnum.Details && (
					<Button
						className=" px-10"
						variant="secondary"
						onClick={() => moveBack()}
						disabled={isSaveDraftLoading || isLoading}
					>
						<i className="bi bi-arrow-left me-2"></i>
						Back
					</Button>
				)}
			</div>
			<div>
				{contextualSuggestions && (
					<div
						className={`text-xs ${
							animateWiggle ? 'animate-wiggle repeat-[3]' : ''
						} text-destructive`}
					>
						{contextualSuggestions}
					</div>
				)}
			</div>
			<div className="flex space-x-4">
				<TooltipWrapper
					tooltip={
						formFields?.status === TaskAPIStatus.UnderReview ||
						formFields?.status === TaskAPIStatus.Submitted
							? `You cannot save this API as a draft because its current status is ${convertToTitleCase(formFields?.status)}. To create a new request, proceed to save this API.`
							: null
					}
					align="end"
				>
					<Button
						className=" px-10"
						variant="secondary"
						onClick={handleSaveDraft}
						disabled={isSaveDraftDisabled}
					>
						{isSaveDraftLoading && <Spinner className="me-2" />}
						<span>Save as Draft</span>
					</Button>
				</TooltipWrapper>
				<TooltipWrapper
					tooltip={showToolTip ? 'Evaluate the expressions first' : ''}
					align="end"
				>
					<Button
						onClick={handleClick}
						disabled={isDisabled}
						className=" px-10"
					>
						{isLoading ? (
							<i className="bi bi-arrow-clockwise animate-spin mr-1 text-sm"></i>
						) : null}
						{buttonLabel}
						<i className="bi bi-arrow-right ms-2"></i>
					</Button>
				</TooltipWrapper>
			</div>
		</div>
	);
};

export default TaskApiCreateNavigation;
