import { IdentityNamespaceTag } from '@/features/campaigns/types';
import { axios } from '@/lib/axios';

export const switchEnterprise = async (enterpriseId: string) => {
	try {
		const response = await axios.post('/auth/switch-enterprise', {
			enterpriseId,
		});
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const createEnterprise = async (data: {
	name: string;
	logo: string;
	chainIds: number[];
	category: string[];
	website?: string;
	twitter?: string;
	discord?: string;
}) => {
	try {
		const response = await axios.post('/auth/create-enterprise', data);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const createEnterpriseV2 = async (data: {
	name: string;
	twitter?: string;
	namespaceTag: IdentityNamespaceTag;
}) => {
	try {
		const response = await axios.post('/auth/create-enterprise-v2', data);
		return response.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const getUserNotifications = async () => {
	const response = await axios.get('/profile/notifications');
	return response.data;
};

export const readNotification = async (notificationId: string) => {
	const response = await axios.put('/profile/notifications/' + notificationId);
	return response.data;
};

export const readAllNotifications = async () => {
	const response = await axios.put('/profile/notifications/all');
	return response.data;
};
