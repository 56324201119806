import { IdentityNamespaceTag } from '@/features/campaigns/types';
import {
	getSupportedChains,
	getSupportedContracts,
} from '@/services/utility.service';
import { useQuery } from '@tanstack/react-query';

export enum ContractType {
	PersonaNftTire1 = 'PERSONA_NFT_TIER_1',
	PersonaNftTire2 = 'PERSONA_NFT_TIER_2',
	IntractCampaignRewardToken = 'INTRACT_CAMPAIGN_REWARD_TOKEN',
	IntractCampaignRewardNft = 'INTRACT_CAMPAIGN_REWARD_NFT',
}

interface IChain {
	chainId?: number;
	chain: string;
	namespaceTag: IdentityNamespaceTag;
	contractType: ContractType;
	address: string;
}

export const useGetContract = (
	contractType: ContractType,
	chain: string,
	namespaceTag: IdentityNamespaceTag,
) => {
	const { data, isLoading } = useQuery<IChain>({
		queryKey: ['contracts', chain, contractType],
		queryFn: () => getSupportedContracts(contractType, chain, namespaceTag),
		staleTime: Infinity,
		enabled: !!chain && !!namespaceTag,
	});

	return {
		contract: data,
		isLoading,
	};
};
