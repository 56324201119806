import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { Button } from '@/components/ui/button';
import { links } from '@/config/links';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { cn } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { useConnectAPI } from '../hooks/useConnectAPI';
import { ApiCreateSectionEnum, CreateTaskAPISteps } from '../types/task-apis.enum';

const ApiCreateSection = () => {
	const {
		section,
		setSection,
		evaluationErrors: errors,
		findFirstMissingStep,
		completedSteps,
		setStep,
	} = useConnectAPI();

	const { user } = useAuth();

	const sections = [
		{
			title: 'Add Your API',
			value: ApiCreateSectionEnum.Details,
			icon: 'file-text',
		},
		{
			title: 'Test Your API',
			value: ApiCreateSectionEnum.Verify,
			icon: 'ui-checks',
		},
	];

	const hasCompletedMandatorySteps = useMemo(
		() =>
			completedSteps?.includes(CreateTaskAPISteps.Basic) &&
			completedSteps?.includes(CreateTaskAPISteps.Curl) &&
			completedSteps?.includes(CreateTaskAPISteps.Payload),
		[completedSteps],
	);

	const handleSectionChange = (value: ApiCreateSectionEnum) => {
		if (hasCompletedMandatorySteps) {
			setSection(value);
			if (value === ApiCreateSectionEnum.Verify) {
				setStep(CreateTaskAPISteps.Testing);
			}
		}
	};

	return (
		<div className="relative flex items-center w-full">
			<div className="absolute left-0">
				{/* Left placeholder (if needed) */}
			</div>
			<div className="flex items-center justify-center mx-auto space-x-4">
				{sections.map((i, index) => (
					<Fragment key={i.value}>
						<TooltipWrapper
							tooltip={
								errors?.sections?.[i.value.toLowerCase()]
									? 'Some of the details are added wrongly'
									: ''
							}
						>
							<Button
								size="sm"
								className={cn(
									'rounded-full px-4',
									section !== i.value
										? ''
										: 'border-purple-700 bg-purple-100 text-purple-800 hover:bg-purple-100 hover:text-purple-800',
									errors?.sections?.[i.value]
										? 'border-red-200 border'
										: 'border-transparent',
									hasCompletedMandatorySteps
										? 'cursor-pointer'
										: 'cursor-default',
								)}
								variant="secondary"
								onClick={() => handleSectionChange(i.value)}
							>
								{errors?.sections?.[i.value] ? (
									<i className="bi bi-exclamation-triangle-fill me-2 text-red-500"></i>
								) : (
									<i className={`bi bi-${i.icon} me-2`}></i>
								)}
								{i.title}
							</Button>
						</TooltipWrapper>
						{sections.length !== index + 1 && (
							<div>
								<i className="bi bi-chevron-right text-muted-foreground"></i>
							</div>
						)}
					</Fragment>
				))}
			</div>
			<div className="absolute right-0">
				<Link
					to={links?.docs?.apiCreation}
					target="_blank"
					className="text-sm font-normal text-muted-foreground hover:text-primary/80 hover:underline"
					onClick={() => {
						analytics.track(TrackingEvents.TaskAPIsGuidelinesClicked, {
							userId: user?._id,
							enterpriseId: user?.enterpriseId,
						});
					}}
				>
					<i className="bi-question-circle me-1"></i>Guidelines
				</Link>
			</div>
		</div>
	);
};

export default ApiCreateSection;
