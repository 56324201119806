import { Media } from '@/components/element/media/Media';
import { useEffect, useRef, useState } from 'react';

const FullPageLoadingWithLogo = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState('auto');
	const [isScaled, setIsScaled] = useState(false); // State to trigger scale transition

	useEffect(() => {
		const updateHeight = () => {
			const topOffset = ref.current?.getBoundingClientRect().top || 0;
			const calculatedHeight = `calc(100vh - ${topOffset}px - 80px)`;
			setHeight(calculatedHeight);
		};

		window.addEventListener('resize', updateHeight);
		updateHeight(); // Initial call to set height

		// Trigger scaling effect after the component has mounted
		setIsScaled(true);

		return () => {
			window.removeEventListener('resize', updateHeight);
		};
	}, []);

	return (
		<div
			className="w-full flex items-center justify-center"
			style={{ minHeight: height }}
			ref={ref}
		>
			<div role="status">
				<Media
					src={
						'https://static.highongrowth.xyz/enterprise/660ed0280f5e5a9e8238d0f5/f86314f0919c48bf89dfb33c8956d7b8.svg'
					}
					altText="intract-logo"
					className={`size-32 transform transition-transform duration-500 ease-in-out ${
						isScaled ? 'scale-150' : 'scale-100'
					}`}
				/>
			</div>
		</div>
	);
};

export default FullPageLoadingWithLogo;
