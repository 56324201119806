import InputTextArea from '@/components/element/inputs/InputTextArea';
import { Button } from '@/components/ui/button';
import { links } from '@/config/links';
import { useAuth } from '@/hooks/useAuth';
import analytics from '@/lib/analytics';
import { copyText } from '@/lib/utils';
import { TrackingEvents } from '@/types/tracking.type';
import { Dispatch, SetStateAction } from 'react';
import { useCurlParser } from '../hooks/useCurlParser';
import { ApiCredentialTypes } from '../types/task-apis.enum';
import { IAPIFormFields, ParsedData } from '../types/task-apis.type';

const CurlParser = ({
	formFields,
	setFormFields,
	formErrors,
	setFormErrors,
	parsedData,
	setParsedData,
}: {
	formFields: IAPIFormFields;
	setFormFields: (fields: IAPIFormFields) => void;
	formErrors: any;
	setFormErrors: Dispatch<SetStateAction<any>>;
	parsedData: ParsedData;
	setParsedData: Dispatch<SetStateAction<ParsedData>>;
}) => {
	const { error, getPlaceholder } = useCurlParser(
		formFields,
		setFormFields,
		parsedData,
		setParsedData,
	);
	const { user } = useAuth();

	const textToCopy = `Intract's cURL format: 

${getPlaceholder()} 

ℹ️ For your developer: Accurately follow the above Intract’s API format for seamless setup. For any issues or API requirements, check here: [For Devs | Community Guide](${links?.docs?.apiCreation})`;

	return (
		<div className="max-w-2xl">
			<InputTextArea
				className="w-full rounded my-2"
				value={formFields?.curlCommand}
				setValue={(value) => {
					setFormFields({
						...formFields,
						curlCommand: value,
						apiConstantDataFields: [],
						apiPayload: [],
						isCustomConstantPayloadRequired: false,
						isApiSchemaValid: false,
						isApiTestDone: false,
					});
					setParsedData(null);
				}}
				placeholder={getPlaceholder()}
				error={!!error || !!formErrors?.curlCommand}
				errorText={error || formErrors?.curlCommand}
				inputClassName="h-[200px] w-full"
				labelClassName="flex items-center justify-between w-full text-slate-700"
				label={
					<div className="flex items-center justify-between w-full">
						<div
							className="flex items-center"
							onClick={(e) => e.stopPropagation()}
						>
							<span className="flex items-center">
								ℹ️<div className="ml-1">Ask your developer</div>
							</span>
							<p className="">
								{formFields?.apiCredentialsType ===
								ApiCredentialTypes.Rest
									? `for your API's RESTful cURL in Intract's format`
									: `for your API's GraphQL cURL command in Intract's format`}
							</p>
						</div>
						<div className="ml-[119px]">
							<Button
								variant="secondary"
								size="sm"
								onClick={() => {
									copyText(textToCopy, 'Sample cURL');
									analytics.track(
										TrackingEvents.TaskAPICopyFormatClicked,
										{
											userId: user?._id,
											enterpriseId: user?.enterpriseId,
										},
									);
								}}
							>
								<i className="bi-copy text-muted-foreground me-1"></i>
								<span>Copy</span>
							</Button>
						</div>
					</div>
				}
			/>

			{parsedData && (
				<div className="mt-4">
					<h2 className="font-medium text-sm mb-2">Parsed Data:</h2>
					<pre className="bg-gray-100 text-sm p-4 rounded overflow-x-auto">
						{JSON.stringify(parsedData, null, 2)}
					</pre>
				</div>
			)}
		</div>
	);
};

export default CurlParser;
