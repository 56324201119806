import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { IDaveContracts } from '../../types/dave.types';
import {
	checkIfContractIsProxy,
	createContract,
	fetchContractABI,
} from '../../services/dave.service';
import { validateContractABI, validateEVMAddress } from '@/utils/validation';
import { toast } from 'sonner';
import { queryClient } from '@/lib/react-query';
import { useGetContracts } from './useGetContracts';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';
import analytics from '@/lib/analytics';
import { TrackingEvents } from '@/types/tracking.type';
import { IdentityNamespaceTag } from '@/features/campaigns/types';

export const useCreateContract = (
	open: boolean,
	setOpen: Dispatch<SetStateAction<boolean>>,
	contract: IDaveContracts,
	onSuccess: any,
	chainId?: string,
) => {
	const { chains } = useGetSupportedChains({
		showAdditionalChains: false,
	});
	const { contracts } = useGetContracts();
	const [step, setStep] = useState<'details' | 'fetching' | 'confirm'>('details');
	const [isLoading, setIsLoading] = useState({
		fetchingABI: false,
		checkingProxy: false,
		creatingContract: false,
	});
	const [fetchStatus, setFetchStatus] = useState({
		fetchingABI: false,
		checkingProxy: false,
	});
	const [details, setDetails] = useState<IDaveContracts>({
		_id: '',
		contractAddress: '',
		abi: '',
		chainId: chainId ? chainId.toString() : '59144',
		name: '',
		isProxy: false,
		implementationAddress: '',
		chain: 'linea',
		namespaceTag: IdentityNamespaceTag.EvmEvm,
	});
	const [errors, setErrors] = useState({
		contractAddress: '',
		abi: false,
		existingContract: false,
	});

	const isBlockExplorerAvailable = useMemo(() => {
		const chain = chains?.find(
			(c) => c.chainId?.toString() === details.chainId?.toString(),
		);
		return chain?.isBlockExplorerAvailable;
	}, [details.chainId, chains]);

	useEffect(() => {
		setStep('details');
		setErrors({
			contractAddress: '',
			abi: false,
			existingContract: false,
		});
		setIsLoading({
			fetchingABI: false,
			checkingProxy: false,
			creatingContract: false,
		});
		setFetchStatus({
			fetchingABI: false,
			checkingProxy: false,
		});

		if (contract) {
			setDetails({
				_id: contract._id,
				contractAddress: contract.contractAddress,
				abi: contract.abi,
				chainId: contract.chainId,
				name: contract.name,
				isProxy: contract.isProxy,
				implementationAddress: contract.implementationAddress,
				chain: contract.chain,
				namespaceTag: contract.namespaceTag,
			});
		} else {
			setDetails({
				contractAddress: '',
				abi: '',
				chainId: chainId ? chainId?.toString() : '59144',
				name: '',
				isProxy: false,
				implementationAddress: '',
				chain: 'linea',
				namespaceTag: IdentityNamespaceTag.EvmEvm,
			});
		}
	}, [open, contract, chains]);

	useEffect(() => {
		setErrors({
			abi: false,
			contractAddress: '',
			existingContract: false,
		});
	}, [details]);

	const validateContractDetails = () => {
		if (
			!details._id &&
			contracts?.find(
				(i) =>
					i.contractAddress?.toLowerCase() ===
						details.contractAddress?.toLowerCase() &&
					i.chainId === details.chainId,
			)
		) {
			setErrors((prev) => ({
				...prev,
				contractAddress: 'This contract is already added',
				existingContract: true,
			}));
			return false;
		}
		if (
			!details.contractAddress ||
			!validateEVMAddress(details.contractAddress)
		) {
			setErrors((prev) => ({
				...prev,
				contractAddress: 'Invalid contract address',
			}));
			return false;
		}
		return true;
	};

	const validateContract = () => {
		const isAbiValid = validateContractABI(details.abi) && details.abi;
		if (!isAbiValid) {
			setErrors((prev) => ({ ...prev, abi: true }));
		}
		return isAbiValid;
	};

	const handleContractDetails = async () => {
		try {
			if (!validateContractDetails()) return;
			if (!isBlockExplorerAvailable) {
				setStep('confirm');
				return;
			}
			setStep('fetching');
			setIsLoading((prev) => ({ ...prev, checkingProxy: true }));
			const isProxy = await checkIfContractIsProxy(
				details.contractAddress,
				details.chain,
				details.namespaceTag,
			);
			if (isProxy) {
				setDetails((prev) => ({
					...prev,
					isProxy: true,
					implementationAddress: isProxy,
				}));
			}
			setIsLoading((prev) => ({ ...prev, checkingProxy: false }));
			setFetchStatus((prev) => ({ ...prev, checkingProxy: true }));
			setIsLoading((prev) => ({ ...prev, fetchingABI: true }));

			const abi = await fetchContractABI(
				details.contractAddress,
				details.chain,
				details.namespaceTag,
			);
			if (abi) {
				setDetails((prev) => ({
					...prev,
					abi: JSON.stringify(abi, null, 2),
				}));
			}
			if (isProxy) {
				const abi2 = await fetchContractABI(
					isProxy,
					details.chain,
					details.namespaceTag,
				);
				if (abi2) {
					setDetails((prev) => {
						return {
							...prev,
							abi: mergeABIs(prev.abi, JSON.stringify(abi2, null, 2)),
						};
					});
				}
			}
			setIsLoading((prev) => ({ ...prev, fetchingABI: false }));
			setFetchStatus((prev) => ({ ...prev, fetchingABI: true }));
		} catch (err) {
			console.log(err);
		}
	};

	const handleCreateContract = async () => {
		try {
			if (!validateContract()) return;
			setIsLoading((prev) => ({ ...prev, creatingContract: true }));
			const contract = await createContract(details);
			await queryClient.invalidateQueries({
				queryKey: ['dave', 'contracts'],
			});
			analytics.track(TrackingEvents.DaveContractAdded, {
				chainId: details.chainId,
				contractAddress: details.contractAddress,
				isBlockExplorerAvailable,
			});
			toast.success(
				'Contract added successfully, you can now create actions with it',
			);
			await new Promise((resolve) => setTimeout(resolve, 1000));
			onSuccess?.(contract);
			setOpen(false);
			setIsLoading((prev) => ({ ...prev, creatingContract: false }));
		} catch (err) {
			setIsLoading((prev) => ({ ...prev, creatingContract: false }));
			console.log(err);
		}
	};

	const handleNext = async () => {
		try {
			if (step === 'details') {
				handleContractDetails();
			}
			if (step === 'fetching') {
				setStep('confirm');
			}
			if (step === 'confirm') {
				handleCreateContract();
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleBack = () => {
		if (step === 'fetching') {
			setStep('details');
		}
		if (step === 'confirm') {
			setStep('details');
		}
	};

	const mergeABIs = (abi1: string, abi2: string) => {
		try {
			const a1 = JSON.parse(abi1);
			const a2 = JSON.parse(abi2);
			return JSON.stringify([...a1, ...a2]);
		} catch (err) {
			console.log(err);
			toast.error('Failed to merge proxy and implementation contract ABIs');
			return abi1;
		}
	};

	return {
		details,
		setDetails,
		step,
		setStep,
		handleNext,
		isLoading,
		fetchStatus,
		errors,
		handleBack,
	};
};
