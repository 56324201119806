import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import clsx from 'clsx';

const InputTextArea = ({
	labelClassName,
	className,
	placeholder,
	label,
	required,
	tooltip,
	error,
	value,
	setValue,
	errorText,
	subLabel,
	subLabelClassName,
	inputClassName,
}: {
	labelClassName?: string;
	inputClassName?: string;
	className?: string;
	label?: string | JSX.Element;
	required?: boolean;
	tooltip?: string;
	error?: boolean | string;
	setValue: (value: string) => void;
	value: string;
	errorText?: string;
	placeholder?: string;
	subLabel?: string;
	subLabelClassName?: string;
}) => {
	return (
		<div className={className}>
			{label && (
				<Label className={clsx('flex items-center mb-2', labelClassName)}>
					<div className="flex flex-col">
						<div className="flex">
							{label}
							{tooltip && (
								<TooltipProvider delayDuration={0}>
									<Tooltip>
										<TooltipTrigger className="ms-2">
											<i className="bi-info-circle-fill block text-muted-foreground text-xs cursor-pointer"></i>
										</TooltipTrigger>
										<TooltipContent>
											<div className="text-sm font-normal max-w-[400px]">
												{tooltip}
											</div>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
							)}
							{required && (
								<span className="text-red-400 ms-1">*</span>
							)}
						</div>
						{subLabel ? (
							<p
								className={clsx(
									'text-xs text-muted-foreground',
									subLabelClassName,
								)}
							>
								{subLabel}
							</p>
						) : null}
					</div>
				</Label>
			)}
			<Textarea
				placeholder={placeholder}
				autoCorrect="off"
				value={value}
				onChange={(e) => setValue(e.target.value)}
				className={cn(inputClassName, error ? 'border-destructive' : '')}
			/>
			{error && errorText && (
				<p className={cn('text-xs  text-destructive mt-1')}>{errorText}</p>
			)}
		</div>
	);
};

export default InputTextArea;
