import DropzoneWithReposition from '@/components/element/dropzone/DropzoneWithReposition';
import InputText from '@/components/element/inputs/InputText';
import { Card, CardContent } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import {
	CampaignRewardType,
	ICampaignAddReward,
	ICreateCampaignDetails,
} from '@/features/campaigns/types';
import { TrackingEvents } from '@/types/tracking.type';
import { Dispatch, SetStateAction, useMemo } from 'react';
import RewardMethodSelect from '../input/RewardMethodSelect';
import RewardCardHeader from '../others/RewardCardHeader';
import TieredWinners from './TieredWinners';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';
import { cn } from '@/lib/utils';

const AddOfficialPoints = ({
	officialPointsReward,
	setOfficialPointsReward,
	errors,
	noEndTime,
	referralTaskEnabled,
	enableCustomReferralPoints,
	customPoints,
	details,
	setDetails,
	disableCustomTierLeaderboard,
}: {
	officialPointsReward: ICampaignAddReward;
	setOfficialPointsReward: (reward: ICampaignAddReward) => void;
	noEndTime: boolean;
	errors: {
		name: boolean;
		image: boolean;
		winners: boolean;
		method: boolean | string;
		enableCustomPoints?: boolean;
		pointsPerUser?: boolean;
		customPoints?: string;
	};
	referralTaskEnabled?: boolean;
	enableCustomReferralPoints?: boolean;
	customPoints?: number;
	details: ICreateCampaignDetails;
	setDetails: Dispatch<SetStateAction<ICreateCampaignDetails>>;
	disableCustomTierLeaderboard: boolean;
}) => {
	const allowedMethods = useMemo(() => {
		const methods = [CampaignRewardType.Fcfs, CampaignRewardType.Unlimited];
		if (!noEndTime) {
			methods.push(CampaignRewardType.LuckyDraw);
			methods.push(CampaignRewardType.Leaderboard);
		}
		return methods;
	}, []);

	const initialTiers = useMemo(() => {
		return [
			{
				_id: 1,
				tierName: 'Tier 1',
				from: 1,
				to: null,
				rewardPerUser: 1,
				errors: { from: '', to: '', rewardPerUser: '' },
			},
			{
				_id: 2,
				tierName: 'Tier 2',
				from: null,
				to: officialPointsReward?.numRewards || null,
				rewardPerUser: null,
				errors: { from: '', to: '', rewardPerUser: '' },
			},
		];
	}, [officialPointsReward?.numRewards]);

	const totalRewardPool = useMemo(() => {
		if (!officialPointsReward?.whitelistReward?.name) {
			return 0;
		}

		if (
			officialPointsReward.customTieredLeaderboard &&
			Array.isArray(officialPointsReward.tiers)
		) {
			// Calculate total reward pool for custom tiered distribution
			return officialPointsReward?.tiers?.reduce((total, tier) => {
				const from = tier.from || 0;
				const to = tier.to || 0;
				const amount = tier.rewardPerUser || 0;
				const winners = to - from + 1;
				return total + winners * amount;
			}, 0);
		}
	}, [
		officialPointsReward?.whitelistReward?.name,
		officialPointsReward.customTieredLeaderboard,
		officialPointsReward.tiers,
	]);

	return (
		<Card className="mb-10 mt-10 shadow-none">
			<RewardCardHeader
				title="Official Points"
				description="Official Points are fungible tokens that are compatible with the Ethereum network."
				tooltip="Choose this method only if you have an active airdrop points system. Clarify how users can claim their points."
				trackingEvent={TrackingEvents.OfficialPointsProTipViewed}
			/>
			<CardContent>
				<div className="grid grid-cols-9 gap-8">
					<div className=" col-span-5">
						<div>
							<div className="text-sm font-medium mb-1">
								Reward Method
							</div>
							<RewardMethodSelect
								allowedMethods={allowedMethods}
								value={officialPointsReward?.method}
								setValue={(value) =>
									setOfficialPointsReward({
										...officialPointsReward,
										method: value as CampaignRewardType,
									})
								}
								errorText={
									errors?.method || 'Please select a reward method'
								}
								error={!!errors?.method}
							/>
						</div>
						<InputText
							label="Reward Name"
							placeholder="Intract Whitelist to early holders"
							setValue={(e) => {
								setOfficialPointsReward({
									...officialPointsReward,
									whitelistReward: {
										...officialPointsReward.whitelistReward,
										name: e,
									},
								});
							}}
							value={officialPointsReward?.whitelistReward?.name}
							error={errors?.name}
							errorText="Please enter a reward name"
							className="mt-6"
						/>

						{officialPointsReward?.method !==
							CampaignRewardType.Unlimited && (
							<InputText
								label="Total Winners"
								placeholder="100"
								type="number"
								setValue={(e) => {
									setOfficialPointsReward({
										...officialPointsReward,
										numRewards: parseInt(e?.toString()),
									});
								}}
								value={officialPointsReward?.numRewards}
								error={errors?.winners}
								errorText="Please enter a number of winners"
								className="w-full mt-6"
							/>
						)}
						{!officialPointsReward?.customTieredLeaderboard ? (
							<>
								<InputText
									label="Points per Winner"
									placeholder="100"
									type="number"
									setValue={(e) => {
										setOfficialPointsReward({
											...officialPointsReward,
											whitelistReward: {
												...officialPointsReward.whitelistReward,
												pointsPerUser: parseInt(
													e?.toString(),
												),
											},
										});
									}}
									value={
										officialPointsReward?.whitelistReward
											?.pointsPerUser
									}
									error={errors?.pointsPerUser}
									errorText="Please enter a points per winners"
									className="w-full mt-6"
								/>
							</>
						) : null}
					</div>
					<div className="col-span-4">
						<DropzoneWithReposition
							file={officialPointsReward.whitelistReward?.nftImage}
							fileLink={officialPointsReward.whitelistReward?.image}
							clearSelection={() => {
								setOfficialPointsReward({
									...officialPointsReward,
									whitelistReward: {
										...officialPointsReward.whitelistReward,
										nftImage: null,
										image: '',
									},
								});
							}}
							setFile={(file: File | File[], fileLink: string) => {
								setOfficialPointsReward({
									...officialPointsReward,
									whitelistReward: {
										...officialPointsReward.whitelistReward,
										nftImage: file,
										image: fileLink,
									},
								});
							}}
							description="Recommended size: 400x400px"
							label="Reward Image"
							cta="Upload Image"
							aspectRatio={1 / 1}
							error={errors?.image}
							errorText="Please upload a valid image or video file."
							bannerPosition={
								officialPointsReward.whitelistReward?.imagePosition
							}
							onImageReposition={({ x, y }) => {
								setOfficialPointsReward({
									...officialPointsReward,
									whitelistReward: {
										...officialPointsReward.whitelistReward,
										imagePosition: { x, y },
									},
								});
							}}
							allowedFiles={['images']}
							maxSize={3}
						/>
					</div>

					{referralTaskEnabled && (
						<div className="flex items-center w-full col-span-9 -mt-3">
							<Checkbox
								checked={enableCustomReferralPoints}
								onCheckedChange={(check) =>
									setDetails({
										...details,
										referral: {
											...details.referral,
											enableCustomReferralPoints: !!check,
											customPoints: 0,
										},
									})
								}
								className="me-2"
							/>
							<div className="text-sm flex items-center gap-2">
								Give
								<InputText
									className="min-w-14 max-w-20"
									value={customPoints}
									setValue={(value) => {
										const numericValue = Number(value);
										if (Number.isNaN(numericValue)) {
											return;
										}
										setDetails({
											...details,
											referral: {
												...details.referral,
												customPoints: Number(value),
											},
										});
									}}
									placeholder="100"
									disabled={!enableCustomReferralPoints}
									onFocus={(e) => e.target.select()}
									error={!!errors?.customPoints}
									errorText={errors?.customPoints}
								/>
								Official Points as reward, on your Referral task.
							</div>
						</div>
					)}
				</div>
				{officialPointsReward?.method === CampaignRewardType.Leaderboard ? (
					<>
						<div className="mt-5 flex items-center">
							<TooltipWrapper
								tooltip={
									disableCustomTierLeaderboard
										? 'You can only have one tiered leaderboard reward in your campaign.'
										: ''
								}
								align="start"
							>
								<Checkbox
									checked={
										officialPointsReward?.customTieredLeaderboard
									}
									onCheckedChange={(check) =>
										setOfficialPointsReward({
											...officialPointsReward,
											customTieredLeaderboard:
												check as boolean,
											tiers: check ? initialTiers : null,
											whitelistReward: {
												...officialPointsReward.whitelistReward,
												pointsPerUser: check
													? null
													: officialPointsReward
															?.whitelistReward
															?.pointsPerUser,
											},
										})
									}
									className="me-2"
									disabled={disableCustomTierLeaderboard}
								/>
							</TooltipWrapper>
							<div
								className={cn(
									'text-sm font-medium text-slate-700',
									disableCustomTierLeaderboard
										? 'text-muted-foreground line-through'
										: '',
								)}
							>
								Add tiers to your leaderboard’s reward distribution
								<TooltipWrapper
									tooltip={
										<span>
											Give a better share of the reward pool to
											your top reward winners.
											<p>
												Example: Top 10 winners get 30% of
												your reward pool, the next 50 winners
												get 50%, and the last 40 get the
												remaining.
											</p>
										</span>
									}
								>
									<i className="bi-info-circle ms-1 cursor-pointer"></i>
								</TooltipWrapper>
							</div>
						</div>
						{officialPointsReward?.customTieredLeaderboard ? (
							<div className="mt-2">
								<TieredWinners
									reward={officialPointsReward}
									setReward={setOfficialPointsReward}
								/>
							</div>
						) : null}
					</>
				) : null}
			</CardContent>
			{totalRewardPool > 0 ? (
				<CardContent className=" py-5 border-t">
					<div className="flex justify-between items-center">
						<div className="me-8">
							<div className="text-sm font-medium mb-1">
								<span className="text-muted-foreground">
									Total Reward Pool:
								</span>{' '}
								{totalRewardPool}
								{` `}
								{officialPointsReward?.whitelistReward?.name}
							</div>
							<div className="text-xs text-muted-foreground">
								You can test the campaign before launching it and
								depositing the tokens
							</div>
						</div>
					</div>
				</CardContent>
			) : (
				<></>
			)}
		</Card>
	);
};

export default AddOfficialPoints;
