export const links = {
	productGuide: 'https://docs.intract.io/product-guide',
	docs: {
		campaigns:
			'https://docs.intract.io/product-guide/community-hub-guides/quests',
		loyalty:
			'https://docs.intract.io/product-guide/community-hub-guides/loyalty-program',
		events: 'https://docs.intract.io/product-guide/community-hub-guides/events',
		taskAPIs:
			'https://docs.intract.io/product-guide/tools-guides/integrate-apis',
		dave: 'https://docs.intract.io/product-guide/tools-guides/dave-no-code-task-verification',
		nfts: 'https://docs.intract.io/product-guide/tools-guides/your-contracts/nft-rewards-contracts',
		tokenApproval:
			'https://docs.intract.io/product-guide/community-hub-guides/quests/launching-your-quests/setting-up-rewards/adding-custom-token-as-reward',
		updatingTasks:
			'https://docs.intract.io/product-guide/community-hub-guides/quests/launching-your-quests/setting-up-tasks',
		rewards:
			'https://docs.intract.io/product-guide/community-hub-guides/quests/launching-your-quests/setting-up-rewards',
		addingContract:
			'https://docs.intract.io/product-guide/tools-guides/dave-no-code-task-verification/understanding-and-creating-actions',
		needHelpAddingContract:
			'https://docs.intract.io/product-guide/tools-guides/dave-no-code-task-verification/understanding-and-creating-actions',
		apiCreation:
			'https://docs.intract.io/product-guide/tools-guides/integrate-apis/for-devs',
		apiIntegration:
			'https://docs.intract.io/product-guide/tools-guides/integrate-apis',
		exports:
			'https://docs.intract.io/product-guide/community-hub-guides/community-settings#id-5.-data-exports',
		discordIntegration:
			'https://docs.intract.io/product-guide/community-hub-guides/integrations/discord',
		telegramIntegration:
			'https://docs.intract.io/product-guide/community-hub-guides/integrations/telegram',
		kols: 'https://docs.intract.io/product-guide/community-hub-guides/kols-marketplace',
		rewardStore:
			'https://docs.intract.io/product-guide/community-hub-guides/reward-store',
		apiTokensDocs:
			'https://docs.intract.io/product-guide/tools-guides/api-tokens',
		apiDocs:
			'https://documenter.getpostman.com/view/25023496/2sA3dsmZUS#c7be9dbb-2e56-46b9-85a4-fcc3d5183822',
		bestPractices:
			'https://docs.intract.io/product-guide/resources/best-practices',
	},
	telegram: 'https://t.me/Venky3456',
	TOS: 'https://intract.gitbook.io/website-policies/intract-terms-of-use',
	privacyPolicy:
		'https://intract.gitbook.io/website-policies/intract-privacy-policy',
	generalDisclaimer:
		'https://intract.gitbook.io/website-policies/general-disclaimers',
	featureRequest: 'https://intract-dashboard.canny.io/feature-requests',
	kolProfile: 'https://t.me/AbbyS_web3',
	redirect: {
		createNewQuest: '/app/campaign/quests/new',
	},
	feedbackForm: 'https://forms.gle/SL6A1tr72ekPjvrY6',
	challengerSeriesForm: 'https://forms.gle/tv7tNFwVDPxLSkyS6',
};
