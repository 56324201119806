import InputFile from '@/components/element/inputs/InputFile';
import InputText from '@/components/element/inputs/InputText';
import { SelectNetwork2 } from '@/components/element/inputs/SelectNetworks2';
import Spinner from '@/components/element/loading/Spinner';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogDescription,
	DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Skeleton } from '@/components/ui/skeleton';
import { useCreateToken } from '@/features/campaigns/hooks/tokens/useCreateToken';
import { useGetSupportedChains } from '@/hooks/useGetSupportedChains';

const ImportTokenDialog = ({ open, setOpen, defaultChainId }) => {
	const { token, setToken, saveToken, isLoading, errors } = useCreateToken(
		setOpen,
		undefined
	);
	const { tokenChains, isLoading: isChainsLoading } = useGetSupportedChains({
		showAdditionalChains: false,
	});

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[555px] overflow-visible">
				<DialogHeader className="border-b pb-3">
					<DialogTitle className="font-medium text-xl">
						Add your Token
					</DialogTitle>
					<DialogDescription className="text-xs text-muted-foreground mt-3">
						Once you add token, we will verify it and make it available
						for your campaign.
					</DialogDescription>
				</DialogHeader>
				<div className="space-y-10 mt-4">
					<InputFile
						label="Token Logo"
						required={true}
						value={token.logoFile}
						setValue={(e) =>
							setToken((p) => ({
								...p,
								logoFile: e,
							}))
						}
						errorText="Please upload your token logo"
						error={errors?.logo}
						description="Upload a logo. Recommended size is 200x200px"
						acceptableFiles="image/*"
					/>
					<InputText
						label="Token Address"
						required={true}
						placeholder="0xc217d85f85b36b06308dc20ebc5cebe21a30c57a"
						value={token.address}
						setValue={(e) =>
							setToken((p) => ({
								...p,
								address: e.toLowerCase(),
							}))
						}
						errorText="Please enter a valid token address"
						error={errors?.address}
					/>
					<div className="grid grid-cols-2 gap-4">
						<InputText
							label="Token Name"
							required={true}
							placeholder="Uniswap"
							value={token.name}
							setValue={(e) =>
								setToken((p) => ({
									...p,
									name: e,
								}))
							}
							errorText="Please enter your token name"
							error={errors?.name}
						/>
						<div>
							<div>
								<Label className="font- mb-2 block">Chain</Label>
								{isChainsLoading ? (
									<Skeleton className="h-[38px] w-full" />
								) : (
									<SelectNetwork2
										placeholder="Uniswap"
										value={token.chainId}
										setValue={({
											chainId,
											chain,
											namespaceTag,
										}) =>
											setToken((p) => ({
												...p,
												chainId: chainId as any,
												chain: chain,
												namespaceTag: namespaceTag,
											}))
										}
										errorMsg='Please enter a chain'
										error={errors?.chain}
										allowedChainIds={
											tokenChains?.map((c) =>
												c.chainId?.toString(),
											) ?? []
										}
										withoutPortal
									/>
								)}
							</div>
						</div>
					</div>
					<div className="grid grid-cols-2 gap-4">
						<InputText
							label="Token Symbol"
							required={true}
							placeholder="USDC"
							value={token.symbol}
							setValue={(e) =>
								setToken((p) => ({
									...p,
									symbol: e,
								}))
							}
							errorText="Please enter your token symbol"
							error={errors?.symbol}
						/>
						<InputText
							label="Token Decimals"
							required={true}
							placeholder="8"
							type="number"
							value={token.decimals}
							setValue={(e) =>
								setToken((p) => ({
									...p,
									decimals: e,
								}))
							}
							errorText="Please enter your token decimals"
							error={errors?.decimals}
						/>
					</div>
				</div>

				<DialogFooter className="mt-10">
					<Button onClick={() => saveToken()} disabled={isLoading}>
						{isLoading && <Spinner className="me-2" />}
						<span>Import Token</span>
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default ImportTokenDialog;
