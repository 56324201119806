import {
	EcosystemChain,
	EcosystemFamily,
	IdentityNamespaceTag,
} from '@/features/campaigns/types';
import { additionalChainsNotInDB } from './../utils/chains/additionalChainsNotInDB';
import { getSupportedChains } from '@/services/utility.service';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export interface IChain {
	chainId: number;
	chainSymbol?: string;
	chainName?: string;
	chainLogo?: string;
	openSeaUrl?: string;
	blockExplorerDomain: string;
	isCampaignRewardErc20: boolean;
	isCampaignRewardErc1155: boolean;
	isNoCodeAvailable: boolean;
	isBlockExplorerAvailable: boolean;
	isRpcUrlsAvailable: boolean;
	isTestnet: boolean;
	isMoralisAvailable: boolean;
	isCustom?: boolean;
	chain: string; // always lowercase and no spaces (use -)
	namespaceTag: IdentityNamespaceTag;
	ecosystemFamily: EcosystemFamily;
	ecosystemChain: EcosystemChain;
}

export const useGetSupportedChains = ({
	showAdditionalChains = false,
	fetchEnterpriseChains = false,
	showNonEVM = false,
}: {
	showAdditionalChains?: boolean;
	fetchEnterpriseChains?: boolean;
	showNonEVM?: boolean;
}) => {
	const [mergedChains, setMergedChains] = useState([]);

	const { data, isLoading } = useQuery<IChain[]>({
		queryKey: ['chains', fetchEnterpriseChains],
		queryFn: () => getSupportedChains(fetchEnterpriseChains),
		staleTime: Infinity,
	});

	function mergeChains(originalChains: any[], newChains: any[]): any[] {
		if (!originalChains) return newChains;
		const existingChainNames = new Set(
			originalChains.map((chain) => chain.chainName),
		);
		const filteredNewChains = newChains.filter(
			(chain) => !existingChainNames?.has(chain.chainName),
		);
		return [...originalChains, ...filteredNewChains];
	}
	const getChainOptions = (chains: IChain[]) => {
		return (
			chains?.map((chain) => ({
				label: chain.chainName,
				value: chain.chainId,
				img: chain.chainLogo,
			})) ?? []
		);
	};

	const getChainName = (chainId: string) => {
		if (!chainId) return '';

		const extractedChain = mergedChains?.find(
			(_chain) =>
				_chain.chainId?.toString() === chainId?.toString() ||
				_chain.chain?.toString() === chainId?.toString(),
		);

		return extractedChain?.chainName;
	};

	const getNativeChainLogo = (chainId: string) => {
		if (!chainId) return '';

		const extractedChain = mergedChains?.find(
			(_chain) =>
				_chain.chainId?.toString() === chainId?.toString() ||
				_chain.chain?.toString() === chainId?.toString(),
		);

		return extractedChain?.chainLogo;
	};

	useEffect(() => {
		let updatedChains = data;
		if (data && showAdditionalChains) {
			updatedChains = mergeChains(data, additionalChainsNotInDB);
		}

		setMergedChains(updatedChains);
	}, [data, showAdditionalChains, showNonEVM]);

	return {
		chains: data,
		isLoading,
		getChainOptions,
		tokenChains: data?.filter((chain) => chain.isCampaignRewardErc20),
		nftChains: data?.filter((chain) => chain.isCampaignRewardErc1155),
		noCodeChains: data?.filter((chain) => chain.isNoCodeAvailable),
		getChainName,
		getNativeChainLogo,
	};
};
