import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { cn } from '@/utils/utils';

const Slider = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
	<SliderPrimitive.Root
		ref={ref}
		className={cn(
			'relative flex w-full touch-none select-none items-center',
			className,
		)}
		{...props}
	>
		<SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-secondary">
			<SliderPrimitive.Range className="absolute h-full bg-primary" />
		</SliderPrimitive.Track>
		<SliderPrimitive.Thumb className="block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
	</SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

interface SliderProps
	extends React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> {
	labels?: string[] | ((value: number) => string);
	showLabels?: boolean;
}

const SliderWithLabels = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	SliderProps
>(({ className, labels, showLabels = true, ...props }, ref) => {
	const { max = 100, step = 1 } = props;

	// Generate labels if not provided
	const generateLabels = () => {
		if (Array.isArray(labels)) return labels;

		if (typeof labels === 'function') {
			return Array.from({ length: Math.floor(max / step) + 1 }, (_, i) =>
				labels(i * step),
			);
		}

		return Array.from(
			{ length: Math.floor(max / step) + 1 },
			(_, i) => `${i * step}`,
		);
	};

	const sliderLabels = labels ? generateLabels() : [];

	return (
		<div className="w-full relative ">
			<div className="">
				<SliderPrimitive.Root
					ref={ref}
					className={cn(
						'relative flex w-full touch-none select-none items-center ',
						className,
					)}
					{...props}
				>
					<SliderPrimitive.Track className="relative h-4 w-full grow overflow-hidden rounded-full bg-white border">
						<SliderPrimitive.Range className="absolute h-full bg-primary" />
					</SliderPrimitive.Track>
					<SliderPrimitive.Thumb className="block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
				</SliderPrimitive.Root>
			</div>

			{showLabels && (
				<div className="flex justify-between w-full absolute top-full mt-2 text-sm text-gray-600 ">
					{sliderLabels.map((label, index) => (
						<div
							key={index}
							className="absolute text-center"
							style={{
								left: `${(index / (sliderLabels.length - 1)) * 100}%`,
								transform:
									index === 0
										? 'translateX(0%)'
										: index === sliderLabels.length - 1
											? 'translateX(-100%)'
											: 'translateX(-50%)',
							}}
						>
							{label}
						</div>
					))}
				</div>
			)}
		</div>
	);
});

export { Slider, SliderWithLabels };
