import { useEffect, useState } from 'react';
import { updateCampaignTags } from '../services/admin.service';

const useAdminCampaignTags = ({ campaign, setCampaign, refetch, setOpen }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [tags, setTags] = useState([]);

	useEffect(() => {
		if (!campaign?.internalTags || campaign?.internalTags?.length === 0) {
			setTags([
				{
					key: 'Project Type',
					value: '',
				},
			]);
		} else setTags(campaign?.internalTags ?? []);
	}, [campaign, campaign?._id]);

	const handleUpdateTags = async () => {
		// Validate tags before submission
		const validTags = tags.filter((tag) => tag.key.trim() && tag.value.trim());

		// if (validTags.length === 0) {
		// 	// Optionally show an error toast or alert
		// 	return;
		// }

		setIsLoading(true);
		try {
			await updateCampaignTags(campaign?._id, {
				tags: validTags,
			});
			setOpen(false);
			refetch && refetch();
		} catch (error) {
			console.error('Failed to update tags:', error);
			// Optionally show error toast
		} finally {
			setIsLoading(false);
		}
	};

	const commonTags = [
		{
			key: 'Project Type',
			values: ['Enterprise', 'PLG'],
		},
	];

	return {
		isLoading,
		setTags,
		tags,
		handleUpdateTags,
		commonTags,
	};
};

export default useAdminCampaignTags;
