import { memo } from 'react';
import en from '../../../../locales/en.json';
import { useConnectAPI } from '../../hooks/useConnectAPI';
import { CreateTaskAPISteps } from '../../types/task-apis.enum';
import VerifyCard from './VerifyCard';
import { GetVerifyStepsConfig } from './VerifyStepsConfig';

const VerifySection = () => {
	const { step, setStep, formFields, completedSteps } = useConnectAPI();
	const { taskApiIntegration } = en;

	const VERIFY_STEPS = [
		CreateTaskAPISteps.Testing,
		CreateTaskAPISteps.EvaluateResponse,
		CreateTaskAPISteps.ReadyToSave,
		CreateTaskAPISteps.Upsert,
	];

	const stepsConfig = GetVerifyStepsConfig({
		setStep: (clickedStep: CreateTaskAPISteps) => {
			const isInVerifySection = VERIFY_STEPS.includes(clickedStep);
			const currentStepInVerify = VERIFY_STEPS.includes(step);

			// If we're moving out of verify section, keep last verify step open
			if (!isInVerifySection && currentStepInVerify) {
				const lastVerifyStep = stepsConfig[stepsConfig.length - 1].key;
				setStep(lastVerifyStep);
			} else {
				setStep(clickedStep);
			}
		},
		showEvaluateResponse:
			!formFields.isApiSchemaValid && formFields.isApiTestDone,
	});

	return (
		<div className="space-y-5">
			{stepsConfig.map(({ key, component }) => (
				<VerifyCard
					key={key}
					stepKey={key}
					isActive={
						step === key ||
						// Keep last step open if we're in details section
						key === stepsConfig[stepsConfig.length - 1].key
					}
					title={taskApiIntegration[key.toLowerCase()]?.title}
					onStepClick={(key: string) => setStep(key as any)}
					completedSteps={completedSteps}
				>
					{component}
				</VerifyCard>
			))}
		</div>
	);
};

const MemoizedVerifySection = memo(VerifySection);
export default MemoizedVerifySection;
