import { ERC20RewardABI } from '@/config/abi/ERC20RewardABI';
import { useCreateTxn } from '@/hooks/useCreateTxn';
import { TxnStatus, useReadContracts } from '@/hooks/useReadContracts';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import { formatObjectID } from '@/utils/parsers';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { markCampaignTokenRewardWithdrawn } from '../../services/campaigns.service';
import { CampaignRewardCategory } from '../../types';
import { useCampaignReport } from './useCampaignReport';
import dayjs from 'dayjs';

export const useCampaignWithdrawFunds = () => {
	const { id } = useParams();
	const { campaign } = useCampaignReport();
	const [isClaimable, setIsClaimable] = useState(false);
	const [txnStatus, setTxnStatus] = useState({
		isFailed: false,
		isSucceeded: false,
		isPending: false,
	});

	const { startTxn } = useCreateTxn();
	const { readContract, status } = useReadContracts();
	const [isLoading, setIsLoading] = useState(false);

	const tokenReward = useMemo(() => {
		const tokenReward = campaign?.reward?.find(
			(i) => i.category === CampaignRewardCategory.Token,
		);
		return tokenReward;
	}, [campaign]);

	const campaignId = useMemo(() => {
		return formatObjectID(id)?.toString();
	}, [id]);

	const handleRetry = () => {
		setTimeout(() => {
			setTxnStatus({
				isFailed: false,
				isSucceeded: false,
				isPending: false,
			});
		}, 1500);
	};

	const checkIfClaimable = async () => {
		const campaignConfig = await readContract({
			chainId: Number(tokenReward?.tokenReward?.chainId),
			contractAddress: campaign?.vaultAddress,
			ABI: ERC20RewardABI,
			fnName: 'campaignConfigs',
			args: [campaignId],
		});

		if (!campaignConfig) {
			throw new Error(
				'There was an error changing the network. Please try again.',
			);
		}

		const claimDateActual = new Date(+campaignConfig[2].toString() * 1000);
		const isClaimable =
			claimDateActual < new Date() &&
			!campaign?.tokenRewardWithdrawal?.isWithdrawn;

		return {
			isClaimable,
			claimDateActual,
		};
	};

	const processClaimTransaction = async () => {
		const txn: any = await startTxn({
			chainId: Number(tokenReward?.tokenReward?.chainId),
			contractAddress: campaign?.vaultAddress,
			ABI: ERC20RewardABI,
			fnName: 'withdraw',
			args: [campaignId],
		});

		if (!txn || !txn?.transactionHash || !txn.receipt) {
			throw new Error(txn?.shortMessage || 'Transaction failed');
		}

		return txn;
	};

	const handleTransactionCompletion = async (txnData) => {
		await markCampaignTokenRewardWithdrawn(id, txnData);
		queryClient.invalidateQueries({
			queryKey: ['campaign', id],
		});
	};

	const claimTokens = async () => {
		try {
			setIsLoading(true);

			const { isClaimable, claimDateActual } = await checkIfClaimable();
			const claimDate_ = dayjs(new Date(claimDateActual));
			if (!isClaimable) {
				handleErrorMessage(
					`The campaign tokens are not yet claimable. You can withdraw ${claimDate_?.fromNow()}.`,
				);
				setIsClaimable(false);
				return;
			}

			const txnData = await processClaimTransaction();
			setIsClaimable(true);

			await handleTransactionCompletion(txnData);
			setTxnStatus({
				isFailed: false,
				isSucceeded: true,
				isPending: false,
			});
		} catch (err) {
			console.log(err);
			handleErrorMessage(err.message || 'An error occurred.');
			setTxnStatus({
				isFailed: true,
				isSucceeded: false,
				isPending: false,
			});
			handleRetry();
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (
			status === TxnStatus.Completed ||
			status === TxnStatus.Error ||
			status === TxnStatus.Idle
		) {
			setTxnStatus((prev) => ({
				...prev,
				isPending: false,
			}));
		} else {
			setTxnStatus((prev) => ({
				...prev,
				isPending: true,
			}));
		}
	}, [status]);

	return {
		isClaimable,
		claimTokens,
		isLoading,
		txnStatus,
	};
};
