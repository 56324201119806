import { Button } from '@/components/ui/button';
import { DialogHeader } from '@/components/ui/dialog';
import SpeedrunEnrollmentPostActive from './SpeedrunEnrollmentPostActive';
import SpeedrunDetailsPostActive from './SpeedrunDetailsPostActive';
import { useCampaignBoosters } from '@/features/campaigns/hooks/report/useCampaignBoosters';
import Spinner from '@/components/element/loading/Spinner';
import SpeedrunEnrollmentComplete from './SpeedrunEnrollmentComplete';

const IntroducingSpeedRun = ({ step, setStep, setOpen }) => {
	const {
		tokens,
		tokenChains,
		speedRunConfig,
		setSpeedRunConfig,
		enrollInSpeedRun,
		isLoading,
		errors,
	} = useCampaignBoosters({ step, setStep });
	return (
		<>
			<div
				className="w-[680px] h-[480px] rounded-l-lg flex items-center justify-center p-8"
				style={{
					background:
						'linear-gradient(147.15deg, rgb(65, 10, 182) 30.17%, rgb(212, 92, 255) 100.01%)',
				}}
			>
				<img
					src="https://static.highongrowth.xyz/enterprise/64638464f5e380ef566bca30/a4a2839681184840b64f864e04af28df.png"
					className=" rounded-lg"
				/>
			</div>
			<DialogHeader className="p-4 opacity-0 [--slidein-delay:200ms] animate-slide-left pb-8 pt-0  h-[480px] w-[900px] flex justify-between py-10 flex-col">
				<div>
					{step === 'speedrun.introduction' && (
						<SpeedrunDetailsPostActive />
					)}
					{step === 'speedrun.enrollment' && (
						<SpeedrunEnrollmentPostActive
							tokens={tokens}
							tokenChains={tokenChains}
							speedRunConfig={speedRunConfig}
							setSpeedRunConfig={setSpeedRunConfig}
							errors={errors}
						/>
					)}
					{step === 'speedrun.complete' && (
						<SpeedrunEnrollmentComplete setOpen={setOpen} />
					)}
				</div>

				{step !== 'speedrun.complete' && (
					<div className="flex">
						{step === 'speedrun.enrollment' && (
							<Button
								variant="outline"
								className="me-2"
								onClick={() => setStep('speedrun.introduction')}
							>
								Back
							</Button>
						)}
						<Button
							className="w-full"
							onClick={() => enrollInSpeedRun()}
						>
							{isLoading ? (
								<Spinner className="me-2" />
							) : (
								<span></span>
							)}
							<span>Enroll in SpeedRun</span>
						</Button>
					</div>
				)}
			</DialogHeader>
		</>
	);
};

export default IntroducingSpeedRun;
