import { useMutation, useQuery } from '@tanstack/react-query';
import { getQuestOfTheDay, updateQuestOfTheDay } from '../../services/cms.service';
import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { Media } from '@/components/element/media/Media';
import { Badge } from '@/components/ui/badge';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { QuestOfTheDayStatus } from '../../types/cms.types';
import { toast } from 'sonner';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { useState } from 'react';
import { queryClient } from '@/lib/react-query';
import { handleErrorMessage } from '@/utils/notifications';
import dayjs from 'dayjs';
import useQuestOfTheDayColumns from './useQuestOfTheDayColumns';

export interface IQuestOfTheDay {
	_id: string;
	name: string;
	banner: string;
	questOfTheDay: {
		date: Date;
		isActive?: boolean;
	};
}

const initialValue = {
	_id: '',
	name: '',
	banner: '',
	questOfTheDay: {
		date: new Date(),
	},
	startImmediately: true,
};

const useQuestOfTheDay = ({ setOpen }) => {
	const [showQuestModal, setShowQuestModal] = useState(false);
	const [formFields, setFormFields] = useState<IQuestOfTheDay | null>(
		initialValue,
	);

	const { data, isLoading } = useQuery({
		queryKey: ['cms-qotd'],
		queryFn: getQuestOfTheDay,
	});
	const resetForm = () => {
		setFormFields(initialValue);
		setOpen(false);
	};

	const updateMutation = useMutation({
		mutationFn: updateQuestOfTheDay,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ['cms-qotd'],
			});
			resetForm();
		},
		onError: (error) => {
			handleErrorMessage(error);
		},
	});

	const handleQuestAddition = () => {
		updateMutation.mutateAsync({
			campaignId: formFields?._id,
			date: formFields?.questOfTheDay?.date
				? dayjs(formFields.questOfTheDay.date).format('YYYY-MM-DD')
				: null,
		});
	};

	return {
		questOfTheDay: data,
		isLoading,
		showQuestModal,
		setShowQuestModal,
		formFields,
		setFormFields,
		handleQuestAddition,
		isSubmitting: updateMutation.isPending,
		resetForm,
	};
};

export default useQuestOfTheDay;
