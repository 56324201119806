import { ColumnDef } from '@tanstack/react-table';
import { DataTableColumnHeader } from '@/components/element/data-table/components/data-table-column-header';
import { DataTableRowActions } from '@/components/element/data-table/components/DataTableRowActions';
import { IAdminCampaigns } from '../../types/admin.types';
import dayjs from 'dayjs';
import { formatCompactNumber } from '@/utils/parsers';
import { Status } from '@/features/campaigns/types';
import StatusBadge from '@/components/element/badges/StatusBadge';
import { Button } from '@/components/ui/button';
import { QUEST_URL } from '@/config';
import { Badge } from '@/components/ui/badge';
import TooltipWrapper from '@/components/element/tooltips/TooltipWrapper';

export const campaignColumns = (options: any): ColumnDef<IAdminCampaigns>[] => {
	const cols: ColumnDef<IAdminCampaigns>[] = [
		{
			accessorKey: 'name',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" />
			),
			cell: ({ row }) =>
				row.getValue('name') ? (
					<TooltipWrapper tooltip={row.getValue('name')}>
						<div className="max-w-[200px] py-1 truncate">
							{row.getValue('name')}
						</div>
					</TooltipWrapper>
				) : (
					<div className="text-muted-foreground"></div>
				),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'enterpriseName',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Enterprise Name" />
			),
			cell: ({ row }) => (
				<TooltipWrapper tooltip={row.getValue('enterpriseName')}>
					<div className="flex items-center py-1 max-w-[455px] truncate">
						{row.getValue('enterpriseName')}
					</div>
				</TooltipWrapper>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'status',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ row }) => (
				<div className="w-[80px] max-w-[90px]">
					<StatusBadge status={row.getValue('status')} />
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'publishCampaign',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Published?" />
			),
			cell: ({ row }) => (
				<Badge
					variant="secondary"
					className={row.original.publishCampaign ? 'bg-green-100' : ''}
				>
					{row.original.publishCampaign ? 'Published' : 'Unpublished'}
				</Badge>
			),
			enableSorting: false,
			enableHiding: false,
		},

		{
			accessorKey: 'pageViewUsersCount',
			accessorFn: (row) => row?.pageViewUsersCount ?? 0,
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Funnel" />
			),
			cell: ({ row }) => (
				<div className="w-[100px]">
					{row.original.status === Status.Draft
						? ''
						: `${formatCompactNumber(
								row?.getValue('pageViewUsersCount'),
							)} > ${formatCompactNumber(
								row?.original.initiatedUsersCount,
							)} > ${formatCompactNumber(
								row?.original.completedUsersCount,
							)}`}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'createdByName',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Created By" />
			),
			cell: ({ row }) => (
				<TooltipWrapper tooltip={row.getValue('createdByName')}>
					<div className="max-w-[150px] truncate">
						{row.getValue('createdByName')}
					</div>
				</TooltipWrapper>
			),
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: 'createdAt',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Created Date" />
			),
			cell: ({ row }) => (
				<div className="flex items-center py-1 max-w-[450px] truncate">
					{dayjs(row.getValue('createdAt')).fromNow()}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			accessorKey: 'startDate',
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Start Date" />
			),
			cell: ({ row }) => (
				<div className="flex items-center py-1 w-[100px] truncate">
					{dayjs(row.getValue('startDate')).fromNow()}
				</div>
			),
			enableSorting: true,
			enableHiding: false,
		},
		{
			id: 'actions',
			cell: ({ row }) => (
				<div className="flex items-center gap-2">
					<DataTableRowActions
						row={row}
						options={Array.isArray(options) ? options : [options]}
					/>
				</div>
			),
		},
	];
	return cols;
};
