import { IdentityNamespaceTag } from '@/features/campaigns/types';
import { File } from 'buffer';

export enum AlphaHubNarrativeStatus {
	Active = 'ACTIVE',
	Draft = 'DRAFT',
	InActive = 'INACTIVE',
	Completed = 'COMPLETED',
	Scheduled = 'SCHEDULED',
}

export interface IAlphaHubNarrativeIcon {
	type: string;
	value: string;
}

export interface IEvent {
	_id: string;
	title: string;
	description: string;
	network?: string;
	chain: string;
	namespaceTag: IdentityNamespaceTag;
	enterpriseId?: string;
	status: AlphaHubNarrativeStatus;
	startImmediately: boolean;
	bannerPosition: {
		x: number;
		y: number;
	};
	timezone: string;
	startDate: Date;
	endDate: Date;
	banner: string;
	campaigns: string[];
	sections?: {
		_id: string;
		title: string;
		campaigns: {
			campaignId: string;
			tag: string;
		}[];
	}[];
	rewards: {
		title: string;
		description: string;
		ids: string[];
		custom: {
			label: string;
			icon: IAlphaHubNarrativeIcon;
		}[];
	};
	projectsIncluded: {
		name: string;
		logo: string;
		keyMetric: {
			value: string;
			label: string;
		};
	}[];
	funnel: {
		logins: {
			uniqueUsers: number;
		};
		completed: {
			uniqueUsers: number;
		};
	};
	publishNarrative: boolean;
	showBanner: boolean;
}

export interface ICampaignEvents {
	_id: string;
	name: string;
	description: string;
	startDate: string;
	endDate: string;
	status: string;
	initiations: number;
	completions: number;
	link: string;
}

export interface IEventDetails {
	_id?: string;
	title: string;
	description: string;
	startDate: Date;
	endDate: Date;
	bannerFile: File | any;
	bannerFileLink: string;
	network: string;
	chain: string;
	namespaceTag: IdentityNamespaceTag;
	startImmediately: boolean;
	timezone: string;
	bannerPosition: {
		x?: number;
		y?: number;
	};
	projectsIncluded: {
		name: string;
		logo: string;
		keyMetric: {
			value: string;
			label: string;
		};
	}[];
	reward: {
		title: string;
		description: string;
	};
}

export interface IEventCampaigns {
	name: string;
	startDate: Date;
	endDate: Date;

	banner: string;
	status: string;
	isComingSoon: boolean;
	index?: number;
	_id?: string;
}

export interface IEventSections {
	_id?: string;
	title: string;
	index?: number;
	campaigns: IEventCampaigns[];
}

export interface IEventReward {
	name: string;
	description: any[];
	startDate: Date;
	endDate: Date;
	startTime: string;
	endTime: string;
	isSequential: boolean;
	bannerFile: File;
	network: string;
}

export enum EventsCreateEnum {
	Details = 'details',
	Campaigns = 'campaigns',
}

export interface IEventReport {
	campaignCompletionTimeSeries: {
		_id: {
			date: Date;
			campaignId: string;
		};
		count: number;
	}[];
	uniqueUsersCompletions: number;
}
