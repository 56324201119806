import { IdentityNamespaceTag } from '@/features/campaigns/types';
import { axios } from '@/lib/axios';

export const uploadFile = async (file: any) => {
	const formData = new FormData();
	formData.append('file', file);
	const response = await axios.post('/utility/upload-file', formData);
	return response.data[0].location;
};

export const getSupportedChains = async (fetchEnterpriseChains: boolean) => {
	const res = await axios.get(
		`/enterprise/chains?fetchEnterpriseChains=${fetchEnterpriseChains}`,
	);
	return res.data;
};

export const getSupportedContracts = async (
	contractType: string,
	chain: string,
	namespaceTag: IdentityNamespaceTag,
) => {
	const res = await axios.get(
		`/utility/contracts?contractType=${contractType}&chain=${chain}&namespaceTag=${namespaceTag}`,
	);
	return res.data;
};

export const getSupportedTokens = async () => {
	const res = await axios.get('/enterprise/tokens');
	return res.data;
};

export const getSupportedTokensForChain = async (
	chain: string,
	namespaceTag: IdentityNamespaceTag,
) => {
	const res = await axios.get('/enterprise/tokens/approved', {
		params: {
			chain,
			namespaceTag,
		},
	});
	return res.data;
};

export const importToken = async (body: any) => {
	const res = await axios.post('/enterprise/tokens', body);
	return res.data;
};

export const getTokenDetails = async (body: any) => {
	const res = await axios.post('/utility/token-details', body);
	return res.data;
};

export const createCustomChain = async (body: any) => {
	const res = await axios.post('/enterprise/add-custom-chain', body);
	return res.data;
};

export const sendUTMParameters = async (body: any) => {
	const res = await axios.post('/utility/utm-params', body);
	return res.data;
};
