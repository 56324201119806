import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogFooter,
} from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import TaskData from './TaskData';
import { useLoyaltyCampaign } from '../../hooks/useLoyaltyCampaign';
import { useMemo } from 'react';
import { formatNumberWithThSt } from '@/utils/parsers';
import { ILoyaltyTask } from '../../types/loyalty.type';
import {
	MetricBasedXpFunction,
	TaskStatus,
} from '@/features/campaigns/types/tasks.enums';
import { Status } from '@/features/campaigns/types';
import TaskActions from './TaskActions';
import { useLoyaltyCampaignReorder } from '../../hooks/useLoyaltyCampaignReorder';
import Spinner from '@/components/element/loading/Spinner';
import TaskAdminValue from '@/features/campaigns/components/create-campaign/tasks/components/Tasks/params/TaskAdminValue';
import { cn } from '@/lib/utils';

const TaskDialog = ({
	open,
	setOpen,
	task,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	task: ILoyaltyTask;
	setTask: any;
}) => {
	const { campaign } = useLoyaltyCampaign();
	const { moveDown, moveUp, isMoving } = useLoyaltyCampaignReorder();

	const {
		section,
		index: taskIndex,
		sectionIndex,
	} = useMemo(() => {
		if (!task) return {};
		const section = campaign?.taskSections.find((section) =>
			section.displayData.find((task_) => task_.unitId === task._id),
		);
		const index = section?.displayData.findIndex(
			(task_) => task_.unitId === task._id,
		);
		const sectionIndex = campaign?.taskSections.findIndex(
			(section_) => section_._id === section?._id,
		);
		return { section, index, sectionIndex };
	}, [campaign, task]);

	const taskStatus: Status = useMemo(() => {
		if (!task) return Status.Draft;
		const status = task.status;
		if (status === TaskStatus.Active) {
			if (task.startImmediately && task.noEndTime) {
				return Status.Active;
			}
			if (
				new Date(task.taskStartDate) > new Date() &&
				!task.startImmediately
			) {
				return Status.Scheduled;
			}
			if (new Date(task.taskEndDate) < new Date() && !task.noEndTime) {
				return Status.Completed;
			}
			return Status.Active;
		}
		if (status === TaskStatus.Draft) return Status.Draft;
		if (status === TaskStatus.Inactive) return Status.Inactive;
	}, [task]);

	if (!task) return null;
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent className="sm:max-w-[635px] px-0 p-0">
				<DialogHeader className="border-b p-3 px-4 flex flex-row justify-between items-center h-full">
					<div className="flex space-x-2">
						<Button
							size="icon"
							variant="outline"
							className="h-6 w-6"
							onClick={() => moveUp(task._id)}
							disabled={
								isMoving?.down ||
								isMoving?.up ||
								(taskIndex === 0 && sectionIndex === 0)
							}
						>
							{isMoving?.up ? (
								<Spinner size="sm" />
							) : (
								<i className="bi-chevron-up"></i>
							)}
						</Button>
						<Button
							size="icon"
							variant="outline"
							className="h-6 w-6"
							onClick={() => moveDown(task._id)}
							disabled={
								isMoving?.down ||
								isMoving?.up ||
								(taskIndex === section?.displayData.length - 1 &&
									sectionIndex ===
										campaign?.taskSections.length - 1)
							}
						>
							{isMoving?.down ? (
								<Spinner size="sm" />
							) : (
								<i className="bi-chevron-down"></i>
							)}
						</Button>
					</div>
					<div className="flex items-center">
						<div className="text-sm hover:underline cursor-pointer"></div>
						<Separator
							orientation="vertical"
							className="mx-3 h-[20px]"
						/>
						<i
							className="bi-x text-xl cursor-pointer block text-muted-foreground"
							onClick={() => {
								setOpen(false);
							}}
						></i>
					</div>
				</DialogHeader>
				<div className="px-6 border-b">
					<div>
						<div className="text-xs text-muted-foreground mb-5">
							{section?.title} / {formatNumberWithThSt(taskIndex + 1)}{' '}
							Task
						</div>
						<div className="text-xl font-medium">
							{task?.description}
						</div>
					</div>
					<TaskData task={task} status={taskStatus} />
				</div>
				<div
					className={cn(
						'px-6 ',
						task?.metricBasedXpConfig?.functionType !==
							MetricBasedXpFunction.None
							? 'min-h-fit'
							: 'min-h-[240px]',
					)}
				>
					<div className="font-medium mb-3">Task Parameters</div>
					<div className="mt-4 mb-4 grid grid-cols-2 gap-4">
						{task?.adminInputs?.map((i) => {
							return (
								<div>
									<div className="text-xs text-muted-foreground">
										{i.label}
									</div>
									<div className="text-sm mt-1 max-w-[90%] truncate">
										<TaskAdminValue adminInput={i} />
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<DialogFooter className="w-full space-x-2 border-t py-3 px-4">
					<TaskActions
						taskStatus={taskStatus}
						task={task}
						setOpen={setOpen}
					/>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default TaskDialog;
